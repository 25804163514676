import React, { useState, useContext, useEffect, useRef } from "react";
import FileInputBox from "../../components/FileInputBox";
import { Button } from "../../components";
import AuthContext from "../../contexts/AuthContext";
import ApiCallTwo from "../../utils/ApiCallTwo";
import { TRAININGFILE, DELETEFILE } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";
import ApiCall from "../../utils/ApiCall";

const TrainingMaterial = () => {
	const { authTokens } = useContext(AuthContext);
	const [trainingFile, setTrainingFile] = useState(null);
	const [showTable, setShowTable] = useState(false);
	const [pdf, setPdf] = useState([]);
	const fileInput = useRef(null);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCall(TRAININGFILE, "GET", authTokens);
			//console.log("response", response.data);
			setPdf(response.data);
		} catch (error) {
			console.log("Error While Getting file", error);
		}
	};

	const trainingMaterialFile = (event) => {
		const file = event.target.files[0];
		setTrainingFile(file);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("file", trainingFile);

		// console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }

		try {
			const response = await ApiCallTwo(
				TRAININGFILE,
				"POST",
				authTokens,
				formData
			);
			//console.log("response", response);
			if (response.status === 200) {
				SweetAlert({ title: response.data.detail, icon: "success" });
			}
			await fetchData();
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	const handleDeleteFile = async (index) => {
		const fileToDelete = pdf[index];
		const fileId = fileToDelete.id;
		//console.log("File ID to be deleted:", fileId);

		try {
			const res = await ApiCall(
				`${DELETEFILE}?id=${fileId}`,
				"GET",
				authTokens
			);
			await fetchData();
		} catch (error) {
			console.log("Error deleting file", error);
		}
	};

	//console.log("pdf", pdf);

	return (
		<>
			<div className="w-auto my-6 flex md:flex-row flex-col gap-4 mx-6 py-4 px-2 justify-center">
				<form className="w-full" onSubmit={handleSubmit}>
					<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
						<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
							<h3 className="mx-6 text-center table-header-text font-bold text-lg">
								Training Materials
							</h3>
						</div>

						<div className="flex flex-col items-center justify-center p-4">
							<FileInputBox
								onChange={trainingMaterialFile}
								accept=".pdf,.doc,.docx,.pptx"
							/>
							<p className="mt-2">(Select a Pdf, PPTX or Word Document)</p>
							<Button classname="bg-blue-600 text-white font-semibold text-sm px-4 py-2 rounded-md">
								Submit
							</Button>
						</div>
					</div>
				</form>
			</div>

			{pdf.length > 0 ? (
				<div className="w-auto my-6 flex md:flex-row flex-col gap-4 mx-6 py-4 px-2 justify-center">
					<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full pb-4 mt-10">
						<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
							<h3 className="mx-6 text-center table-header-text font-bold text-lg">
								Uploaded Files
							</h3>
						</div>

						<div className="flex gap-4 flex-col items-center w-full">
							{pdf.map((file, index) => (
								<div key={index} className="w-full px-4">
									<div className="flex items-center gap-3 space-y-2">
										<span className="flex justify-center flex-1">
											{file.name}
										</span>
										<Button
											classname="bg-red-600 text-white font-semibold text-sm px-4 py-2 rounded-md"
											onClick={() => handleDeleteFile(index)}
										>
											Delete
										</Button>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default TrainingMaterial;
