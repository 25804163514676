import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import TeamChart from "./TeamChart";
import { TEAMCHART } from "../Constants";
import "../css/teamchart.css";
import ApiCall from "../utils/ApiCall";
import Loader from "./Loader";

const MyTeam = () => {
	const { authTokens } = useContext(AuthContext);
	const [teamChartData, setTeamChartData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		//console.log("api calling");
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCall(TEAMCHART, "GET", authTokens, null);
			setTeamChartData(response.data.user_org);
			setIsLoading(false);
			//console.log("response", response.data.user_org);
			// console.log("teamchart",response)
		} catch (error) {
			console.log("error while fetching teamchart data", error);
			setIsLoading(false);
		}
	};

	const Organizationchart = (teamChartData) => {
		//console.log("teamchart", teamChartData);

		if (teamChartData.length > 0) {
			return teamChartData.map((item, index) => (
				<TeamChart
					key={index}
					title={item.title}
					name={item.name}
					avatar={item.avatar}
				>
					{item.children && Organizationchart(item.children)}
				</TeamChart>
			));
		} else {
			return <h2>No Data</h2>;
		}
	};

	return (
		<div className="bg-accent w-auto box-shadow flex gap-1 justify-center items-center rounded-lg my-6 mx-6  teamchart-card">
			{isLoading ? (
				<div className="w-full h-[70vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<div className="tree-container">
					<div className="tree">
						<ul>{Organizationchart([teamChartData])}</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default MyTeam;
