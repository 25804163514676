// FOR IMAGES & PDF
import { BASE_URL } from "../Constants";

const ApiCallTwo = async (url, method, authTokens, formData) => {
	const headers = {
		Authorization: "Bearer " + String(authTokens.access),
	};
	const body = formData ? formData : undefined;
	const response = await fetch(BASE_URL + url, {
		method: method,
		headers: headers,
		body: body,
	});

	const contentType = response.headers.get("content-type");
	if (contentType && contentType.includes("application/pdf")) {
		const pdfBlob = await response.blob();
		return {
			status: response.status,
			data: pdfBlob,
			isPdf: true,
		};
	} else if (contentType && contentType.includes("image/jpeg")) {
		const imageBlob = await response.blob();
		return {
			status: response.status,
			data: imageBlob,
			isImage: true,
		};
	} else if (contentType && contentType.includes("application/ms-excel")) {
		const imageBlob = await response.blob();
		return {
			status: response.status,
			data: imageBlob,
			isImage: true,
		};
	} else {
		const responseData = await response.json();
		return {
			status: response.status,
			data: responseData,
			isPdf: false,
		};
	}
};

export default ApiCallTwo;
