import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Dropdown,
	FormRow,
	InputBox,
	Loader,
	Popup,
	Table,
} from "../../components";
import {
	PayslipFormHeadData,
	filterDateMonth,
	dropDownPagination,
} from "../../data";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { PAYPAYSLIPGENERATION } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";

const PayslipGeneration = () => {
	const year = new Date().getFullYear();
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [payslipGenerate, setPayslipGenerate] = useState([]);
	const [searchData, setSearchData] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [payslipGeneratePopup, setPayslipGeneratePopup] = useState(null);
	const [selectedYear, setSelectedYear] = useState(year);
	const [selectedMonth, setSelectedMonth] = useState("");
	const [pagination, setPagination] = useState(10);
	const rowPerPage = 5;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem(
			"payslip-generation-pagination-data"
		);
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${PAYPAYSLIPGENERATION}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			//console.log("res", res.data);
			setPayslipGenerate(res.data.payslipgen);
			setIsLoading(false);
		} catch (error) {
			console.log("Error While Fetching Data", error);
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"payslip-generation-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handleYear = (e) => {
		e.preventDefault();
		setSelectedYear(e.target.value);
	};

	const handleMonth = (e) => {
		e.preventDefault();
		setSelectedMonth(e.target.value);
	};

	const handleSearch = (e) => {
		setSearchData(e.target.value);
	};

	const searchedData = payslipGenerate.filter((item) =>
		item.email.toLowerCase().includes(searchData.toLowerCase())
	);

	const handlePayslipGenerate = (rowIndex) => {
		if (searchedData.length > 7) {
			const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
			const id = searchedData[actualIndex].id;
			setPayslipGeneratePopup(id);
		} else {
			const correctIndex = payslipGenerate.findIndex(
				(item) => item.id === searchedData[rowIndex].id
			);
			const id = payslipGenerate[correctIndex].id;
			setPayslipGeneratePopup(id);
		}
	};

	const handlePayslipGenerateData = async (e) => {
		e.preventDefault();
		const params = {
			emp_id: payslipGeneratePopup || "",
			year: selectedYear || "",
			month: selectedMonth || "",
		};
		setPayslipGeneratePopup(null);
		setSelectedMonth("");
		setSelectedYear(year);
		//console.log("params", params);
		try {
			const res = await ApiCall(
				PAYPAYSLIPGENERATION,
				"POST",
				authTokens,
				params
			);
			if (res.status === 200) {
				SweetAlert({ title: "Successfully Generated", icon: "success" });
			} else {
				SweetAlert({ title: "Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("Error While Fetching Data", error);
		}
	};
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Pay Slips Generation
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<div className="p-2 flex justify-between w-full mt-4 intro-x">
						<InputBox
							value={searchData}
							onChange={handleSearch}
							placeholder="Search"
							type="search"
						/>
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					<Table
						tableStyle="border-spacing-y-[10px] border-separate -mt-4"
						bodyStyle="bg-accent box-shadow"
						header={PayslipFormHeadData}
						body={searchedData.map((row) => [
							row.email,
							row.emp,
							row.designation,
							row.ctc,
						])}
						btnstyle="intro-x"
						bodyTdStyle="table-body-td intro-x"
						headTdStyle="table-body-td intro-x"
						btnTitle="Generate"
						paginationNeed={searchedData.length > 7 ? true : false}
						currentPage={currentPage}
						rowPerPage={rowPerPage}
						paginationBody={payslipGenerate}
						onPageChange={handlePageChange}
						onClick={handlePayslipGenerate}
					/>
					{payslipGeneratePopup && (
						<Popup
							title="Generate Payroll"
							trigger={payslipGeneratePopup}
							setTrigger={setPayslipGeneratePopup}
							popupstyle="md:w-2/5 w-full"
						>
							<form onSubmit={handlePayslipGenerateData}>
								<FormRow
									label="Year"
									item={
										<InputBox
											value={selectedYear}
											onChange={handleYear}
											style="w-full"
											type="number"
										/>
									}
								/>
								<FormRow
									label="Month"
									item={
										<Dropdown
											value={selectedMonth}
											options={filterDateMonth}
											onChange={handleMonth}
											style="w-full cursor-pointer"
										/>
									}
								/>
								<div className="text-center">
									<Button classname="w-auto my-4 text-white text-sm font-semibold py-2 rounded-md px-6 bg-button">
										Submit
									</Button>
								</div>
							</form>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default PayslipGeneration;
