import React, { useContext } from "react";
import Layout from "../components/Layout";
import {
	TimesheetDashboard,
	Timesheet,
	Notification,
	Sidebar,
} from "../components";
import EmployeeTimesheet from "../sub-pages/TimesheetPages/EmployeeTimesheet";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import {
	TimeSheetItems,
	TimeSheetItemsForHr,
	TimeSheetItemsForManager,
} from "../data";

import EmployeeDashboard from "../sub-pages/TimesheetPages/EmployeeDashboard";
import { useStateContext } from "../contexts/ContextProvider";
import ProjectUser from "../sub-pages/TimesheetPages/ProjectUser";

const TimesheetPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { reportTo } = useStateContext();

	if (!user) {
		return history.push("/login");
	}

	return (
		<>
			{user.user_role === "INTERN" ? (
				<Layout sidebar={<Sidebar sidebarLinks={TimeSheetItems} />}>
					<Route path="/timesheet/dashboard" component={TimesheetDashboard} />
					<Route path="/timesheet/timesheets" component={Timesheet} />
					<Route path="/timesheet/notification" component={Notification} />
				</Layout>
			) : null}

			{reportTo === true ? (
				user.user_role === "EMPLOYEE" || user.user_role === "CONSULTANT" || user.user_role === "TRAINEE" ? (
					<Layout sidebar={<Sidebar sidebarLinks={TimeSheetItemsForManager} />}>
						<Route path="/timesheet/dashboard" component={TimesheetDashboard} />
						<Route path="/timesheet/timesheets" component={Timesheet} />
						<Route path="/timesheet/notification" component={Notification} />
						<Route
							path="/timesheet/employee-dashboard"
							component={EmployeeDashboard}
						/>
						<Route
							path="/timesheet/employee-timesheet"
							component={EmployeeTimesheet}
						/>
					</Layout>
				) : null
			) : user.user_role === "EMPLOYEE" || user.user_role === "CONSULTANT" || user.user_role === "TRAINEE" ? (
				<Layout sidebar={<Sidebar sidebarLinks={TimeSheetItems} />}>
					<Route path="/timesheet/dashboard" component={TimesheetDashboard} />
					<Route path="/timesheet/timesheets" component={Timesheet} />
					<Route path="/timesheet/notification" component={Notification} />
				</Layout>
			) : null}

			{user.user_role === "MANAGER" && (
				<Layout sidebar={<Sidebar sidebarLinks={TimeSheetItemsForManager} />}>
					<Route path="/timesheet/dashboard" component={TimesheetDashboard} />
					<Route path="/timesheet/timesheets" component={Timesheet} />
					<Route path="/timesheet/notification" component={Notification} />
					<Route
						path="/timesheet/employee-dashboard"
						component={EmployeeDashboard}
					/>
					<Route
						path="/timesheet/employee-timesheet"
						component={EmployeeTimesheet}
					/>
					{/* <Route path="/timesheet/project-user" component={ProjectUser} /> */}
				</Layout>
			)}
			{user.user_role === "HR" && (
				<Layout sidebar={<Sidebar sidebarLinks={TimeSheetItemsForHr} />}>
					<Route path="/timesheet/dashboard" component={EmployeeDashboard} />
					<Route
						path="/timesheet/employee-timesheet"
						component={EmployeeTimesheet}
					/>
				</Layout>
			)}
		</>
	);
};

export default TimesheetPage;
