import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import { Layout, Sidebar } from "../components";
import {
	LmsItems,
	LmsItemsForHr,
	LmsAdminItems,
	LmsCeoItems,
	LmsItemsForManager,
	LmsItemsTrai
} from "../data";
import {
	LmsDashboard,
	MyProfile,
	LeaveRequest,
	CompensationRequest,
	ReedemRequest,
	HolidayCalendar,
	LeavePolicy,
	WorkFromHome,
} from "../components";
import {
	LeaveHistory,
	RejectedApplications,
	CreateUser,
	ModifyUser,
	DeleteUser,
	WorkFromHomeHistory,
} from "../sub-pages/LmsPages";

const LmsPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<>
			{user.user_role === "EMPLOYEE" ||
			user.user_role === "INTERN" ||
			user.user_role === "TRAINEE" ||
			user.user_role === "CONSULTANT" ? (
				<Layout sidebar={<Sidebar sidebarLinks={LmsItems} />}>
					<Route path="/lms/dashboard" component={LmsDashboard} />
					<Route path="/lms/my-profile" component={MyProfile} />
					<Route path="/lms/leave-request" component={LeaveRequest} />
					<Route path="/lms/work-home-request" component={WorkFromHome} />
					<Route
						path="/lms/compensation-request"
						component={CompensationRequest}
					/>
					<Route path="/lms/reedem-request" component={ReedemRequest} />
					<Route path="/lms/holiday-calendar" component={HolidayCalendar} />
					<Route path="/lms/leave-policy" component={LeavePolicy} />
				</Layout>
			) : null}
			{user.user_role === "HR" && (
				<Layout sidebar={<Sidebar sidebarLinks={LmsItemsForHr} />}>
					<Route path="/lms/dashboard" component={LmsDashboard} />
					<Route path="/lms/leave-request" component={LeaveRequest} />
					<Route path="/lms/work-home-request" component={WorkFromHome} />
					<Route path="/lms/leave-history" component={LeaveHistory} />
					<Route path="/lms/wfh-history" component={WorkFromHomeHistory} />
					<Route
						path="/lms/rejected-applications"
						component={RejectedApplications}
					/>
					<Route path="/lms/reedem-request" component={ReedemRequest} />
					<Route path="/lms/holiday-calendar" component={HolidayCalendar} />
					<Route path="/lms/leave-policy" component={LeavePolicy} />
				</Layout>
			)}
			{user.user_role === "MANAGER" && (
				<Layout sidebar={<Sidebar sidebarLinks={LmsItemsForManager} />}>
					<Route path="/lms/dashboard" component={LmsDashboard} />
					<Route path="/lms/my-profile" component={MyProfile} />
					<Route path="/lms/leave-request" component={LeaveRequest} />
					<Route path="/lms/work-home-request" component={WorkFromHome} />
					<Route path="/lms/leave-history" component={LeaveHistory} />
					<Route path="/lms/wfh-history" component={WorkFromHomeHistory} />
					<Route
						path="/lms/compensation-request"
						component={CompensationRequest}
					/>
					<Route path="/lms/reedem-request" component={ReedemRequest} />
					<Route path="/lms/holiday-calendar" component={HolidayCalendar} />
					<Route path="/lms/leave-policy" component={LeavePolicy} />
				</Layout>
			)}

			{user.user_role === "CEO" && (
				<Layout sidebar={<Sidebar sidebarLinks={LmsCeoItems} />}>
					<Route path="/lms/dashboard" component={LmsDashboard} />
					<Route path="/lms/holiday-calendar" component={HolidayCalendar} />
					<Route path="/lms/leave-policy" component={LeavePolicy} />
				</Layout>
			)}

			{user.user_role === "ADMIN" && (
				<Layout sidebar={<Sidebar sidebarLinks={LmsAdminItems} />}>
					<Route path="/lms/dashboard" component={LeaveHistory} />
					<Route path="/lms/create-user" component={CreateUser} />
					<Route path="/lms/modify-user" component={ModifyUser} />
					<Route path="/lms/delete-user" component={DeleteUser} />
					<Route path="/lms/holiday-calendar" component={HolidayCalendar} />
					<Route path="/lms/leave-policy" component={LeavePolicy} />
				</Layout>
			)}
		</>
	);
};

export default LmsPage;
