import React from "react";
import { Bars } from "react-loader-spinner";
import "../css/pageloader.css";
const PageLoader = () => {
	return (
		<div className="loader-new">
			<Bars
				height="80"
				width="80"
				color="#02C6B9"
				ariaLabel="bars-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
		</div>
	);
};

export default PageLoader;
