import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import ApiCall from "../../utils/ApiCall";
import { FormRow, InputBox, ProfileLabel } from "../../components";
import { PAYROLLUSERDATA } from "../../Constants";

const PayrollDashboard = () => {
	const { authTokens } = useContext(AuthContext);
	const [userData, setUserData] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(PAYROLLUSERDATA, "GET", authTokens, null);
			//console.log("res", res.data);
			setUserData(res.data);
		} catch (error) {
			console.log("While fetching User Data", error);
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2 flex items-center justify-center">
			<div className="w-full md:w-[450px]  transition rounded-3xl mt-4 pb-4">
				<div className="w-full intro-x bg-blue-800 justify-center flex mt-0 rounded-t-3xl items-center py-3">
					<h4 className="table-header-text text-center font-bold text-sm md:text-lg">
						Salary Info
					</h4>
				</div>
				<FormRow
					formRowStyle="row-hover my-2"
					label="Name"
					item={
						<InputBox
							value={userData.map((item) => item.name)}
							style="font-bold outline-none border-none capitalize w-full text-end"
							disabled={true}
						/>
					}
				/>
				<FormRow
					formRowStyle="row-hover my-2"
					label="Designation"
					item={
						<InputBox
							value={userData.map((item) => item.designation)}
							style="font-bold outline-none border-none capitalize w-full text-end"
							disabled={true}
						/>
					}
				/>
				<FormRow
					formRowStyle="row-hover my-2"
					label="Date of Joining"
					item={
						<InputBox
							value={userData.map((item) => item.doj)}
							style="font-bold outline-none border-none capitalize w-full text-end"
							disabled={true}
						/>
					}
				/>
				<FormRow
					formRowStyle="row-hover my-2"
					label="Current CTC"
					item={
						<InputBox
							value={userData.map((item) => item.ctc)}
							style="font-bold outline-none border-none capitalize w-full text-end"
							disabled={true}
						/>
					}
				/>
				<FormRow
					formRowStyle="row-hover my-2"
					label="Last Appraisal date"
					item={
						<InputBox
							value={userData.map((item) => item.last_appraisal)}
							style="font-bold outline-none border-none capitalize w-full text-end"
							disabled={true}
						/>
					}
				/>
				<div className="w-full intro-x bg-blue-800 justify-center flex mt-0 rounded-b-3xl items-center py-3"></div>
			</div>
		</div>
	);
};

export default PayrollDashboard;
