import React from "react";
import LmsForm from "./LmsForm";
import Dropdown from "./Dropdown";
import DateBox from "./DateBox";
import Textarea from "./Textarea";
import InputBox from "./InputBox";

const WfhForm = ({
	handleSubmit,
	handleWfhStartDate,
	handleWfhEndDate,
	optionsTwo,
	handleWfhAreaValue,
	wfhDescription,
	wfhTotalLeave,
	handleWfhType,
	wfhStartDate,
	wfhEndDate,
}) => {
	return (
		<LmsForm
			style="mb-6"
			onSubmit={handleSubmit}
			labelOne="Start"
			itemOne={
				<DateBox
					required={true}
					style="w-full"
					value={wfhStartDate}
					onChange={handleWfhStartDate}
				/>
			}
			labelTwo="End"
			itemTwo={
				<DateBox
					required={true}
					style="w-full"
					value={wfhEndDate}
					onChange={handleWfhEndDate}
				/>
			}
			// labelThree="Type"
			// itemThree={
			// 	<Dropdown
			// 		style="w-full"
			// 		onChange={handleWfhType}
			// 		options={optionsTwo}
			// 	/>
			// }
			labelThree="No of Days"
			itemThree={
				<InputBox
					style="w-full"
					value={wfhTotalLeave}
					placeholder="No of days"
					disabled="disabled"
				/>
			}
			labelFour="Description"
			itemFour={
				<Textarea
					required={true}
					style="w-full h-32"
					value={wfhDescription}
					onChange={handleWfhAreaValue}
				/>
			}
			Submit="Submit"
		/>
	);
};

export default WfhForm;
