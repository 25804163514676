import React, { useState, useEffect, useContext } from "react";
import LoginImg from "../images/loginbg.jpg";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import SweetAlert from "../utils/SweetAlert";
import { CHANGEPASSWORD } from "../Constants";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ChangePasswordPage = () => {
	const history = useHistory();
	const { authTokens } = useContext(AuthContext);
	const [passwordOne, setPasswordOne] = useState("");
	const [passwordTwo, setPasswordTwo] = useState("");
	const [response, setResponse] = useState([]);
	const [resmsg, setResmsg] = useState([]);
	const [showMessage, setShowMessage] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [passwordVisibleTwo, setPasswordVisibleTwo] = useState(false);

	const handlePwsOne = (e) => {
		setShowMessage(false);
		setPasswordOne(e.target.value);
	};

	const handlePwsTwo = (e) => {
		e.preventDefault();
		setShowMessage(false);
		setPasswordTwo(e.target.value);
	};

	const handlePasswordKeyDown = (e) => {
		if (e.key === "Space") {
			e.preventDefault();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setShowMessage(true);

		const params = {
			password1: passwordOne,
			password2: passwordTwo,
		};

		try {
			const res = await ApiCall(CHANGEPASSWORD, "POST", authTokens, params);

			setResponse(res);
			setResmsg(res.data.message);
			//console.log("ddddd", res, res.data.message);

			if (res.status === 200) {
				SweetAlert({
					title: "Please check your email for new password",
					icon: "success",
				});
				history.push({
					pathname: "/",
				});
			}
		} catch (error) {
			console.log("Error while sending Data", error);
		}
	};

	const getPasswordStrength = () => {
		if (
			passwordOne.length >= 8 &&
			/[a-z]/.test(passwordOne) &&
			/[A-Z]/.test(passwordOne) &&
			/\d/.test(passwordOne) &&
			/[!@#$%^&*]/.test(passwordOne)
		) {
			return "Strong";
		} else if (
			passwordOne.length >= 8 &&
			/[a-zA-Z]/.test(passwordOne) &&
			/\d/.test(passwordOne)
		) {
			return "Moderate";
		} else {
			return "Weak";
		}
	};

	return (
		<div className="flex  items-center justify-center bg-loginBg py-4 sm:py-8 h-screen">
			<div className="max-w-xl mx-auto overflow-hidden rounded-md bg-white shadow ">
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<div className="relative ">
						<div className="absolute inset-0 bg-gray-800/70"></div>
						<img
							className="h-48 sm:h-96 w-full object-cover sm:max-h-full sm:max-w-full responsive-image"
							src={LoginImg}
							alt="Login Background"
						/>
						<div className="absolute inset-0  flex items-center justify-center flex-col text-center text-white">
							<h1 className="text-2xl font-semibold mb-4">Change Password?</h1>
							<p className="text-base pl-1">
								Go back to{" "}
								<Link to="/" className="underline underline-offset-2">
									Home Page?
								</Link>
							</p>
						</div>
					</div>
					<div className="sm:px-6">
						<div className="flex flex-col justify-center h-full py-6">
							<form className="w-full space-y-4" onSubmit={handleSubmit}>
								<div className="mb-4">
									<label
										className="block text-black text-md font-bold mb-2"
										htmlFor="PasswordOne"
									>
										Password
									</label>
									<div className="relative">
										<input
											className="bg-white shadow appearance-none border rounded-lg  h-12 sm:h-14 py-2 px-3 sm:py-3 sm:px-4 tet-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline w-full pr-12"
											id="passwordOne"
											value={passwordOne}
											type={passwordVisible ? "text" : "password"}
											placeholder="Password"
											onKeyDown={handlePasswordKeyDown}
											onChange={handlePwsOne}
										/>
										<span
											className="absolute right-2 top-4 cursor-pointer"
											onClick={() => setPasswordVisible(!passwordVisible)}
										>
											{passwordVisible ? (
												<AiOutlineEyeInvisible size={20} />
											) : (
												<AiOutlineEye size={20} />
											)}
										</span>
									</div>
									{passwordOne && (
										<p className="text select-none">
											Password Strength: {getPasswordStrength()}
										</p>
									)}
								</div>
								<div className="mb-4">
									<label
										className="block text-black text-md font-bold mb-2 select-none"
										htmlFor="PasswordTwo"
									>
										Confirm Password
									</label>
									<div className="relative">
										<input
											className="bg-white shadow appearance-none border rounded-lg  h-12 sm:h-14 py-2 px-3 sm:py-3 sm:px-4 tet-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline w-full pr-12"
											id="passwordTwo"
											value={passwordTwo}
											type={passwordVisibleTwo ? "text" : "password"}
											placeholder="Confirm Password"
											onKeyDown={handlePasswordKeyDown}
											onChange={handlePwsTwo}
										/>
										<span
											className="absolute right-2 top-4 cursor-pointer"
											onClick={() => setPasswordVisibleTwo(!passwordVisibleTwo)}
										>
											{passwordVisibleTwo ? (
												<AiOutlineEyeInvisible size={20} />
											) : (
												<AiOutlineEye size={20} />
											)}
										</span>
									</div>
								</div>
								<div className="pt-3 flex flex-col justify-center">
									{showMessage && response && response.status !== 200 && (
										<h3 className="text-sm text-black font-semibold text-center capitalize -mt-5 mb-2">
											{resmsg}
										</h3>
									)}
									<button
										className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline w-full sm:py-3 sm:px-4"
										type="submit"
									>
										Submit
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePasswordPage;
