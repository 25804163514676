import React from "react";

const LmsLeaveDetails = ({ style, label, item, className }) => {
	return (
		<div className={`flex w-full lms-leave-details my-2 ${className}`}>
			<div className={`w-full p-2 ${style}`}>
				<p className="text-sm leave-label font-extrabold">{label}</p>
			</div>
			<div className="w-full p-2">
				<p className="text-sm font-bold leave-item">{item}</p>
			</div>
		</div>
	);
};

export default LmsLeaveDetails;
