import React, { useState, useEffect, useContext } from "react";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import { useStateContext } from "../contexts/ContextProvider";
import ProfileLabel from "./ProfileLabel";
import InputBox from "./InputBox";
import Textarea from "./Textarea";
import Button from "./Button";
import SweetAlert from "../utils/SweetAlert";
import LmsCards from "./LmsCards";
import { REDEEM } from "../Constants";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const ReedemRequest = () => {
	const { cardData } = useStateContext();
	const [btnLoader, setBtnLoader] = useState(false);
	const { authTokens } = useContext(AuthContext);
	const [redeem, setRedeem] = useState("");
	const [output, setOutput] = useState(() => {
		const storedOutput = localStorage.getItem("output");
		return storedOutput ? JSON.parse(storedOutput) : "";
	});
	const [days, setDays] = useState([]);
	const [description, setDescription] = useState("");
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setLoader(true);
		try {
			const res = await ApiCall(REDEEM, "GET", authTokens, null);
			setRedeem(res.data.active);
			setDays(res.data.casual);
			//console.log("ddd", res);
		} catch (error) {
			console.log("While Fetching No of Days Data", error);
		}
		setLoader(false);
	};

	const handleDescrip = (e) => {
		e.preventDefault();
		setDescription(e.target.value);
	};

	const handleRedeem = async (e) => {
		e.preventDefault();
		setBtnLoader(true);
		const params = {
			casual: days,
			description: description,
		};

		try {
			const response = await ApiCall(REDEEM, "POST", authTokens, params);
			setOutput(response.status);
			localStorage.setItem("output", JSON.stringify(response.status));
			if (response.status === 200) {
				SweetAlert({ title: "Successfully Applied", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
			}

			//console.log("mmm", response);
		} catch (error) {
			console.log("While Sending Description data", error);
		}
		setBtnLoader(false);
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			{loader === true ? (
				<div className="w-full h-[70vh] flex justify-center items-center">
					<Loader />
				</div>
			) : (
				<>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 pb-6">
						{cardData &&
							cardData.map((item, index) => (
								<LmsCards
									key={index}
									number={item.number}
									title={item.title}
									icons={item.icons}
									color={item.color}
								/>
							))}
					</div>
					<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
						<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
							<h4 className="mx-6 text-center table-header-text font-bold text-lg">
								Redeem Request
							</h4>
						</div>
						{redeem === true ? (
							output === 200 ? (
								<h3 className="px-4">
									You have successfully applied for Redeem.
								</h3>
							) : (
								<form onSubmit={handleRedeem} className="w-full px-6 py-2">
									<div className="w-full gap-7 flex md:flex-row flex-col my-1">
										<div className="w-full flex gap-0 flex-col px-10">
											<ProfileLabel lableStyle="text-sm" label="No Of Days" />
											<InputBox value={days} disabled={true} />
										</div>
										<div className="w-full flex gap-0 flex-col px-10">
											<ProfileLabel lableStyle="text-sm" label="Description" />
											<Textarea
												value={description}
												onChange={handleDescrip}
												required={true}
												style="bg-transparent h-20"
											/>
										</div>
									</div>
									<div className="w-full text-center mt-6">
										{btnLoader ? (
											<div className="flex justify-center items-center">
												<Loader />
											</div>
										) : (
											<Button classname="bg-button text-white font-semibold text-sm px-4 py-2 rounded-md">
												Submit
											</Button>
										)}
									</div>
									<h3 className="px-4 text-red-600 mt-4 font-semibold">
										The Redeem request will be available from 1/4/2024, 1:00 PM,
										until 8/4/2024, 1:00 PM. Please apply before the closing
										date.
									</h3>
								</form>
							)
						) : (
							<div>
								<h3 className="px-4">
									This functionality will only be available in the year end. For
									more info, Please check the
									<Link
										className="nav-item-active font-bold px-1"
										to="/lms/leave-policy"
									>
										{" "}
										Leave Policy
									</Link>
									.
									{/* The Redeem request will be available from 1/4/2024, 1:00 PM,
									until 8/4/2024, 1:00 PM. Please apply before the closing date. */}
								</h3>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ReedemRequest;
