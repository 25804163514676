import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import { HOLIDAYCALENDAR } from "../Constants";
import Table from "./Table";
import { lmsHolidayCalendarHead } from "../data";
import Loader from "./Loader";

const HolidayCalendar = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [holidayData, setHolidayData] = useState({ holiday: [] });
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(HOLIDAYCALENDAR, "GET", authTokens, null);
			// console.log("res", res);
			setHolidayData(res.data);
			// console.log("holiday", res.data.holiday);
			setIsLoading(false);
		} catch (error) {
			console.log("error during holiday calendar data fetching", error);
			setIsLoading(false);
		}
	};

	const newHolidayData = holidayData.holiday || [];

	const currentYear = new Date().getFullYear();

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Holiday Calendar - {currentYear}
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<Table
					tableStyle="border-spacing-y-[10px] border-separate -mt-4"
					bodyStyle="bg-accent box-shadow"
					header={lmsHolidayCalendarHead}
					body={newHolidayData.map((row) => [
						row.sno,
						row.date,
						row.name,
						row.opt === "" ? "Holiday" : row.opt,
					])}
					bodyTdStyle="table-body-td intro-x"
					headTdStyle="table-body-td intro-x"
					paginationNeed={true}
					currentPage={currentPage}
					rowPerPage={rowPerPage}
					paginationBody={newHolidayData}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default HolidayCalendar;
