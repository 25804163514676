import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../../utils/ApiCall";
import ApiCallTwo from "../../utils/ApiCallTwo";
import AuthContext from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Table, Loader } from "../../components";
import { CLOSEDTICKET, OPENTICKETHR, OPENTICKETHRIMAGE } from "../../Constants";
import { helpDeskOpenTickes } from "../../data";

const ClosedTickets = () => {
	const history = useHistory();
	const { authTokens, user } = useContext(AuthContext);
	const [ticket, setTicket] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [attachmentPic, setAttachmentPic] = useState("");
	const rowPerPage = 7;

	const handleView = async (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const id = ticket[actualIndex].id;

		try {
			// console.log("Fetching data for user:", id);
			const res = await ApiCallTwo(
				`${OPENTICKETHR}?id=${id}`,
				"GET",
				authTokens,
				null
			);
			// console.log("response", res.data[0]);
			history.push({
				pathname: "/helpdesk/closedticketview",
				state: {
					closedticketData: res.data[0],
				},
			});
		} catch (error) {
			console.log("Error fetching data", error);
		}

		try {
			const res = await ApiCallTwo(
				`${OPENTICKETHRIMAGE}?id=${id}`,
				"GET",
				authTokens,
				null
			);
			if (res.isImage) {
				const imageBlob = res.data;
				const url = URL.createObjectURL(imageBlob);
				// console.log("PDF Blob:", url);
				history.push({
					pathname: "/helpdesk/closedticketview",
					state: {
						attachmentPic: url,
					},
				});
			}
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCall(CLOSEDTICKET, "GET", authTokens);
			// console.log("open ticket", response.data);
			setTicket(response.data);
			setLoading(false);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const newTicket = ticket || [];
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2 flex items-center justify-center">
			<div className="px-4 py-2 w-full">
				<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
					<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
						{user && user.user_role === "CONSULTANT" ? (
							<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
								Consultant Tickets
							</h3>
						) : (
							<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
								Employee Tickets
							</h3>
						)}
					</div>
					{loading ? (
						<div className="w-full h-[60vh] flex items-center justify-center intro-x">
							<Loader />
						</div>
					) : ticket && ticket.length > 0 ? (
						<Table
							tableStyle="border-spacing-y-[10px] border-separate -mt-4"
							bodyStyle="bg-accent box-shadow"
							header={helpDeskOpenTickes}
							body={ticket.map((row) => [
								row.id,
								row.user_name,
								row.category,
								row.description,
								row.ticket_solved === true ? "Closed" : "Open",
							])}
							btnTitle="View"
							bodyTdStyle="table-body-td intro-x"
							headTdStyle="table-body-td intro-x"
							btnstyle="intro-x"
							paginationNeed={true}
							currentPage={currentPage}
							rowPerPage={rowPerPage}
							paginationBody={ticket}
							onPageChange={handlePageChange}
							onClick={handleView}
						/>
					) : (
						<h3 className="text-center">No data</h3>
					)}
				</div>
			</div>
		</div>
	);
};

export default ClosedTickets;
