import React, { useContext } from "react";
import Layout from "../components/Layout";
import { Sidebar } from "../components";
import HelpDesk from "../sub-pages/HelpDeskPages/Helpdesk";
import Tickets from "../sub-pages/HelpDeskPages/Tickets";
import OpenTickets from "../sub-pages/HelpDeskPages/OpenTickets";
import ClosedTickets from "../sub-pages/HelpDeskPages/ClosedTickets";
import ClosedTicketView from "../sub-pages/HelpDeskPages/ClosedTicketView";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import {
	HelpDeskItems,
	HelpDeskItemsHR,
	HelpDeskItemsForAdminCeo,
	HelpDeskItemsTwo,
} from "../data";
import TicketsView from "../sub-pages/HelpDeskPages/TicketsView";
import { useStateContext } from "../contexts/ContextProvider";
import TechOpenTicks from "../sub-pages/HelpDeskPages/TechOpenTicks";
import TechClosedTicks from "../sub-pages/HelpDeskPages/TechClosedTicks";

const HelpDeskPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { techTrue } = useStateContext();

	//console.log("ddddd", user);

	if (!user) {
		return history.push("/login");
	}
	return (
		<>
			{user.user_role === "INTERN" || user.user_role === "MANAGER" ? (
				<Layout sidebar={<Sidebar sidebarLinks={HelpDeskItems} />}>
					<Route path="/helpdesk/dashboard" component={HelpDesk} />
					<Route path="/helpdesk/ticket" component={Tickets} />
				</Layout>
			) : null}

			{techTrue === true ? (
				user.user_role === "EMPLOYEE" || user.user_role === "CONSULTANT" || user.user_role === "TRAINEE" ? (
					<Layout sidebar={<Sidebar sidebarLinks={HelpDeskItemsTwo} />}>
						<Route path="/helpdesk/dashboard" component={HelpDesk} />
						<Route path="/helpdesk/ticket" component={Tickets} />
						<Route
							path="/helpdesk/closedticketview"
							component={ClosedTicketView}
						/>
						<Route path="/helpdesk/ticketView" component={TicketsView} />
						<Route path="/helpdesk/open-ticket" component={TechOpenTicks} />
						<Route path="/helpdesk/closed-ticket" component={TechClosedTicks} />
					</Layout>
				) : null
			) : user.user_role === "EMPLOYEE" || user.user_role === "CONSULTANT" || user.user_role === "TRAINEE" ? (
				<Layout sidebar={<Sidebar sidebarLinks={HelpDeskItems} />}>
					<Route path="/helpdesk/dashboard" component={HelpDesk} />
					<Route path="/helpdesk/ticket" component={Tickets} />
				</Layout>
			) : null}

			{user.user_role === "ADMIN" || user.user_role === "CEO" ? (
				<Layout sidebar={<Sidebar sidebarLinks={HelpDeskItemsForAdminCeo} />}>
					<Route path="/helpdesk/dashboard" component={HelpDesk} />
					<Route path="/helpdesk/ticket" component={Tickets} />
				</Layout>
			) : null}

			{user.user_role === "HR" && (
				<Layout sidebar={<Sidebar sidebarLinks={HelpDeskItemsHR} />}>
					<Route path="/helpdesk/dashboard" component={HelpDesk} />
					<Route path="/helpdesk/openticket" component={OpenTickets} />
					<Route path="/helpdesk/ticketView" component={TicketsView} />
					<Route path="/helpdesk/closedticket" component={ClosedTickets} />
					<Route
						path="/helpdesk/closedticketview"
						component={ClosedTicketView}
					/>
				</Layout>
			)}
		</>
	);
};

export default HelpDeskPage;
