import React, { useEffect, useState, useContext } from "react";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import AuthContext from "../../contexts/AuthContext";
import {
	lmsRejectedApplicationsHead,
	lmsRejectedApplicationsBody,
	lmsUpdateUserData,
} from "../../data";
import { LmsLeaveDetails, Popup, InputBox, Button } from "../../components";
import { REJECTEDAPPLICATIONS, USERHOLIDAYDETAILS } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";
import ApiCall from "../../utils/ApiCall";
import NoDataImg from "../../images/no_data.svg";

const RejectedApplications = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [userHoliday, setUserHoliday] = useState([]);
	const [popupItem, setPopupItem] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 5;

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCall(
				REJECTEDAPPLICATIONS,
				"GET",
				authTokens,
				null
			);
			setUserHoliday(response.data.rejected_applications);
			// console.log("Response", response.data.rejected_applications);
			setIsLoading(false);
		} catch (error) {
			console.log("While fetching Rejected Applications Data", error);
			setIsLoading(false);
		}
	};
	const handleShowPopup = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = userHoliday[actualIndex];
		setPopupItem(currentData);
	};

	const handleChange = (e) => {
		setPopupItem({ ...popupItem, [e.target.name]: e.target.value });
	};

	const handleUpdateUserDetails = async () => {
		const params = {
			userId: popupItem.username,
			userName: popupItem.username,
			sickLeave: popupItem.delta_sick,
			casualLeave: popupItem.delta_casual,
			optionalLeave: popupItem.delta_optional,
			lop: popupItem.delta_lop,
		};
		// console.log("params", params);
		try {
			const response = await ApiCall(
				USERHOLIDAYDETAILS,
				"POST",
				authTokens,
				params
			);
			// console.log("response", response.status);
			if (response.status === 200) {
				SweetAlert({ title: "Details Updated", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
			}

			setPopupItem(null);
		} catch (error) {
			console.log("Error While Sending Description Data", error);
			SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
		}
	};
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<h4 className="text-lg intro-x table-body-td px-2 font-semibold">
				Rejected Applications
			</h4>
			{isLoading ? (
				<div className="flex justify-center items-center w-full h-[70vh]">
					<Loader />
				</div>
			) : (
				<>
					{userHoliday.length > 0 ? (
						<Table
							tableStyle="border-spacing-y-[10px] border-separate -mt-4 intro-x"
							bodyStyle="bg-accent box-shadow"
							header={lmsRejectedApplicationsHead}
							body={userHoliday.map((row) => [
								row.id,
								row.username,
								row.category,
								row.date,
								row.manager_level_approval === false
									? "Rejected"
									: row.manager_level_approval === true
									? "Approved"
									: "Pending",
							])}
							btnTitle="View"
							paginationNeed={true}
							currentPage={currentPage}
							rowPerPage={rowPerPage}
							paginationBody={userHoliday}
							onPageChange={handlePageChange}
							onClick={handleShowPopup}
						/>
					) : (
						<h3 className="text-center w-full flex flex-col justify-center items-center intro-x my-4">
							<img className="w-72 w- text-center" src={NoDataImg} />
							<p className="font-bold">No Data</p>
						</h3>
					)}
					{popupItem && (
						<Popup
							title="User Holiday Details"
							trigger={popupItem}
							setTrigger={setPopupItem}
							popupstyle="md:w-2/5 w-full"
						>
							<LmsLeaveDetails
								style="flex items-center"
								label="user"
								item={
									<InputBox
										name="username"
										value={popupItem.username}
										disabled={true}
									/>
								}
							/>
							<LmsLeaveDetails
								style="flex items-center"
								label="Sick Leaves Taken"
								item={
									<InputBox
										name="delta_sick"
										type="number"
										value={popupItem.delta_sick}
										onChange={handleChange}
									/>
								}
							/>
							<LmsLeaveDetails
								style="flex items-center"
								label="Casual Leaves Taken"
								item={
									<InputBox
										name="delta_casual"
										type="number"
										value={popupItem.delta_casual}
										onChange={handleChange}
									/>
								}
							/>
							<LmsLeaveDetails
								style="flex items-center"
								label="Optional Holiday Taken"
								item={
									<InputBox
										name="delta_optional"
										type="number"
										value={popupItem.delta_optional}
										onChange={handleChange}
									/>
								}
							/>
							<LmsLeaveDetails
								style="flex items-center"
								label="LOP"
								item={
									<InputBox
										name="delta_lop"
										type="number"
										value={popupItem.delta_lop}
										onChange={handleChange}
									/>
								}
							/>
							<div className="w-full my-2 flex justify-center items-center">
								<Button
									classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
									onClick={handleUpdateUserDetails}
								>
									Update
								</Button>
							</div>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default RejectedApplications;
