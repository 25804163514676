import React, { useEffect, useContext } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";
import AuthContext from "../contexts/AuthContext";
import NavButton from "./NavButton";
import UserProfile from "./UserProfile";
import ChangePasswordBtn from "./ChangePasswordBtn";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Navbar = () => {
	const {
		activeItem,
		setActiveItem,
		screenSize,
		setScreenSize,
		sitetheme,
		setSiteTheme,
		userPicData,
		imgLoader,
	} = useStateContext();

	const { user, logoutUser, isClicked, setIsClicked, handleClick } =
		useContext(AuthContext);

	const history = useHistory();

	useEffect(() => {
		const handleScreenSize = () => setScreenSize(window.innerWidth);
		window.addEventListener("resize", handleScreenSize);
		handleScreenSize();
		return () => window.removeEventListener("resize", handleScreenSize);
	}, []);

	useEffect(() => {
		if (screenSize <= 900) {
			setActiveItem(false);
		} else {
			setActiveItem(true);
		}
	}, [screenSize]);

	useEffect(() => {
		localStorage.setItem("theme", sitetheme);
		const localTheme = localStorage.getItem("theme");
		document.querySelector("html").setAttribute("data-theme", localTheme);
		if (localTheme === "dark") {
			setSiteTheme("dark");
		} else {
			setSiteTheme("light");
		}
	}, [sitetheme]);

	const handleTheme = (e) => {
		if (e.target.checked) {
			setSiteTheme("dark");
		} else {
			setSiteTheme("light");
		}
	};

	const handleChangePswd = () => {
		setIsClicked(!isClicked);
		history.push({
			pathname: "/change-password",
		});
	};

	// console.log("theme", user.profile_pic);

	return (
		<div className="flex flex-col h-14 md:justify-normal justify-center md:h-10 z-50">
			<div className="flex relative justify-between md:mx-6 p-2">
				<NavButton
					click={() => setActiveItem((prevActiveItem) => !prevActiveItem)}
				/>
				<div className="flex gap-3 items-center justify-center">
					<label className="swap swap-rotate">
						{/* this hidden checkbox controls the state */}
						<input
							type="checkbox"
							onChange={handleTheme}
							checked={sitetheme === "dark"}
						/>

						{/* sun icon */}
						<svg
							className={`swap-on fill-current w-6 h-6 ${
								sitetheme === "dark" ? "" : "hidden"
							}`}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
						</svg>

						{/* moon icon */}
						<svg
							className={`swap-off fill-current w-6 h-6 ${
								sitetheme === "light" ? "" : "hidden"
							}`}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
						</svg>
					</label>

					<div
						className="cursor-pointer flex items-center p-1 gap-2 select-none"
						onClick={() => handleClick("userProfile")}
					>
						{imgLoader ? (
							<Skeleton baseColor="#808080" className="rounded-full w-8 h-8" />
						) : (
							<LazyLoad height={32}>
								<img
									className="rounded-full w-8 h-8"
									src={user && userPicData}
									alt="User Logo"
								/>
							</LazyLoad>
						)}

						<p>
							<span>{user.email}</span>
						</p>
						<MdKeyboardArrowDown />
					</div>
				</div>
			</div>
			<div className="flex justify-end mr-11 transition-all ease-in-out">
				<div className="flex gap-1 flex-col">
					{isClicked && <UserProfile onClick={logoutUser} />}
					{isClicked && <ChangePasswordBtn onClick={handleChangePswd} />}
				</div>
			</div>
		</div>
	);
};

export default Navbar;
