import React from "react";
import Swal from "sweetalert2";
import "../css/sweetalert.css";

const SweetAlert = ({ title, icon }) => {
	Swal.fire({
		toast: true,
		position: "top",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		icon: icon,
		title: title,
		iconColor: "#ffffffe3",
		// timerProgressBarColor: "white",
		customClass: {
			container: "sweet-alert",
		},
	});
};

export default SweetAlert;
