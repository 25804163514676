import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import {
	Button,
	DateBox,
	Dropdown,
	InputBox,
	InputFile,
	LmsLeaveDetails,
} from "../../components";
import { lmsUserRole, lmsUserGender } from "../../data";
import ApiCall from "../../utils/ApiCall";
import { CREATEUSER, BASE_URL } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";

const CreateUser = () => {
	const { authTokens } = useContext(AuthContext);
	const [userImg, setUserImg] = useState("");
	const [userImgUrl, setUserImgUrl] = useState("");
	const [userName, setUserName] = useState("");
	const [userId, setUserId] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userRole, setUserRole] = useState("");
	const [userDesig, setUserDesig] = useState("");
	const [userGender, setUserGender] = useState("");
	const [userDob, setUserDob] = useState("");
	const [userDoj, setUserDoj] = useState("");
	const [userPf, setUserPf] = useState("");
	const [userSickLeave, setUserSickLeave] = useState("");
	const [userCasualLeave, setUserCasualLeave] = useState("");
	const [reportManager, setReportManager] = useState([]);
	const [reportTrain, setReportTrain] = useState([]);
	const [reportTo, setReportTo] = useState([]);
	const [userMang, setUsermang] = useState("");
	const [userTrai, setUserTrai] = useState("");
	const [userToEmp, setUserToEmp] = useState("");
	useEffect(() => {
		fetchData();
	}, []);

	// console.log("gggg", BASE_URL + CREATEUSER);

	const fetchData = async () => {
		try {
			const res = await ApiCall(CREATEUSER, "GET", authTokens, null);
			//console.log("dropdown details", res);
			setReportManager(res.data.report_to);
			setReportTrain(res.data.report_mang);
			setReportTo(res.data.report_to_1);
		} catch (error) {
			console.log("While fetching CREATEUSER api", error);
		}
	};

	useEffect(() => {
		const storedUserImgUrl = localStorage.getItem("image-url");
		if (storedUserImgUrl) {
			setUserImgUrl(storedUserImgUrl);
		}
	}, []);

	const handleImg = (event) => {
		const file = event.target.files[0];
		setUserImg(file);

		const reader = new FileReader();
		reader.onload = () => {
			const url = reader.result;
			setUserImgUrl(url);
			localStorage.setItem("image-url", url);
		};
		reader.readAsDataURL(file);
		//console.log(reader.result);
	};

	const handleRemoveImg = () => {
		setUserImgUrl("");
		localStorage.removeItem("image-url");
	};

	//console.log("url", userImgUrl);

	const handleUname = (event) => {
		event.preventDefault();
		setUserName(event.target.value);
	};

	const handleUid = (event) => {
		event.preventDefault();
		setUserId(event.target.value);
	};

	const hanleUemail = (event) => {
		event.preventDefault();
		setUserEmail(event.target.value);
	};

	const handleUrole = (event) => {
		event.preventDefault();
		setUserRole(event.target.value);
	};

	const handleUdesig = (event) => {
		event.preventDefault();
		setUserDesig(event.target.value);
	};

	const handleUgender = (event) => {
		event.preventDefault();
		setUserGender(event.target.value);
	};

	const handleUdob = (event) => {
		event.preventDefault();
		setUserDob(event.target.value);
	};

	const handleUdoj = (event) => {
		event.preventDefault();
		setUserDoj(event.target.value);
	};

	const handleUpf = (event) => {
		event.preventDefault();
		setUserPf(event.target.value);
	};

	const handleUsick = (event) => {
		event.preventDefault();
		setUserSickLeave(event.target.value);
	};

	const handleUcasual = (event) => {
		event.preventDefault();
		setUserCasualLeave(event.target.value);
	};

	const handleReportManag = (event) => {
		event.preventDefault();
		setUsermang(event.target.value);
	};

	const handleReportTrai = (event) => {
		event.preventDefault();
		setUserTrai(event.target.value);
	};

	const handleReportTo = (event) => {
		event.preventDefault();
		setUserToEmp(event.target.value);
	};

	const handleCreateUser = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("profile_pic", userImg);
		formData.append("username", userName);
		formData.append("emp_id", userId);
		formData.append("email", userEmail);
		formData.append("role", userRole);
		formData.append("designation", userDesig);
		formData.append("gender", userGender);
		formData.append("dob", userDob);
		formData.append("doj", userDoj);
		formData.append("pf_no", userPf);
		formData.append("sick", userSickLeave);
		formData.append("casual", userCasualLeave);

		if (userRole && userRole === "EMPLOYEE") {
			formData.append("report_to", userMang);
		}

		if (userRole && userRole === "TRAINEE") {
			formData.append("report_to_three", userTrai);
		}

		if (userRole && userRole === "INTERN") {
			formData.append("report_to_1", userToEmp);
		}

		// console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }

		fetch(BASE_URL + CREATEUSER, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + String(authTokens.access),
			},
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				//console.log("data", data);
				SweetAlert({ title: data, icon: "success" });

				setUserCasualLeave("");
				setUserDesig("");
				setUserDob("");
				setUserDoj("");
				setUserEmail("");
				setUserGender("");
				setUserId("");
				setUserImg("");
				setUserImgUrl("");
				setUserName("");
				setUserPf("");
				setUserRole("");
				setUserSickLeave("");

				localStorage.removeItem("image-url");
			})
			.catch((error) => {
				console.error("Error:", error);
				SweetAlert({ title: error, icon: "error" });
			});
	};

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<form onSubmit={handleCreateUser}>
				<div className="intro-y transition flex flex-col items-center px-8 py-4 justify-center w-auto pb-4">
					<div className="flex flex-col md:flex-row w-full">
						<div className="flex-auto md:w-20 w-full">
							<h3 className="text-md font-medium pb-4 nav-item-active uppercase">
								User Profile
							</h3>
							<p>Please add user profile image.</p>
						</div>
						<div className="flex-auto mt-6 md:mt-0 md:w-60 w-full box-shadow bg-accent rounded-2xl py-6 p-4 px-6">
							<InputFile
								cancelBtn={handleRemoveImg}
								userImg={userImgUrl}
								onChange={handleImg}
							/>
						</div>
					</div>
				</div>
				<div className="mt-6 intro-y transition flex flex-col items-center px-8 py-4 justify-center w-auto pb-4">
					<div className="flex flex-col md:flex-row w-full">
						<div className="flex-auto md:w-20 w-full">
							<h3 className="text-md font-medium pb-4 nav-item-active uppercase">
								Basic Details
							</h3>
							<p>Please fill user details.</p>
						</div>
						<div className="flex-auto mt-6 md:mt-0 md:w-60 w-full box-shadow bg-accent rounded-2xl  p-4 px-8">
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Employee Name</h4>
								<InputBox
									value={userName}
									onChange={handleUname}
									type="text"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Employee Id</h4>
								<InputBox
									value={userId}
									onChange={handleUid}
									type="text"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">E-Mail Address</h4>
								<InputBox
									value={userEmail}
									onChange={hanleUemail}
									type="email"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Role</h4>
								<Dropdown
									required={true}
									value={userRole}
									onChange={handleUrole}
									options={lmsUserRole}
									style="mt-2 nav-item-active"
								/>
							</div>
							{userRole === "EMPLOYEE" ? (
								<div className="flex flex-col w-full my-3 transition ease-in-out">
									<h4 className="text-sm">Reporting Manager</h4>
									<Dropdown
										value={userMang}
										onChange={handleReportManag}
										options={[
											{ value: "", label: "Select" },
											...reportTo.map((item) => ({
												value: item.id,
												label: item.name,
											})),
										]}
										required={true}
										style="mt-2 nav-item-active"
									/>
								</div>
							) : null}
							{userRole === "TRAINEE" ? (
								<div className="flex flex-col w-full my-3 transition ease-in-out">
									<h4 className="text-sm">Reporting Manager</h4>
									<Dropdown
										value={userTrai}
										onChange={handleReportTrai}
										options={[
											{ value: "", label: "Select" },
											...reportTo.map((item) => ({
												value: item.id,
												label: item.name,
											})),
										]}
										required={true}
										style="mt-2 nav-item-active"
									/>
								</div>
							) : null}
							{userRole === "INTERN" ? (
								<div className="flex flex-col w-full my-3 transition ease-in-out">
									<h4 className="text-sm">Reporting To</h4>
									<Dropdown
										value={userToEmp}
										onChange={handleReportTo}
										options={[
											{ value: "", label: "Select" },
											...reportTo.map((items) => ({
												value: items.id,
												label: items.name,
											})),
										]}
										required={true}
										style="mt-2 nav-item-active"
									/>
								</div>
							) : null}
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Designation</h4>
								<InputBox
									value={userDesig}
									onChange={handleUdesig}
									type="text"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Gender</h4>
								<Dropdown
									value={userGender}
									onChange={handleUgender}
									options={lmsUserGender}
									required={true}
									style="mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Dob</h4>
								<DateBox
									value={userDob}
									onChange={handleUdob}
									style="bg-transparent  mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Doj</h4>
								<DateBox
									value={userDoj}
									onChange={handleUdoj}
									style="bg-transparent  mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">PF Number</h4>
								<InputBox
									value={userPf}
									onChange={handleUpf}
									type="number"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Sick leaves allowed</h4>
								<InputBox
									value={userSickLeave}
									onChange={handleUsick}
									type="number"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
							<div className="flex flex-col w-full my-3">
								<h4 className="text-sm">Casual leaves allowed</h4>
								<InputBox
									value={userCasualLeave}
									onChange={handleUcasual}
									type="number"
									style="w-full mt-2 nav-item-active"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full text-center mt-6">
					<Button classname="bg-button px-6 py-2 rounded-lg text-white font-bold">
						Submit
					</Button>
				</div>
			</form>
		</div>
	);
};

export default CreateUser;
