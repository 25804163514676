import React, { useState, useEffect } from "react";

const SwitchTab = ({ tabs, defaultTab, onTabChange }) => {
	const [activeTab, setActiveTab] = useState(
		sessionStorage.getItem("activeTab") || defaultTab
	);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
		onTabChange(tab);
		sessionStorage.setItem("activeTab", tab);
	};

	useEffect(() => {
		sessionStorage.setItem("activeTab", activeTab);
	}, [activeTab]);
	return (
		<div className="text-sm bg-accent mt-4 rounded-t-3xl font-medium text-center text-gray-500 box-shadow">
			<ul className="flex flex-wrap -mb-px">
				{tabs &&
					tabs.map((tab) => (
						<li className="mr-2" key={tab}>
							<button
								key={tab}
								className={`inline-block p-4 border-b-2 px-5 py-4 font-medium rounded-b-sm border-transparent rounded-t-lg ${
									activeTab === tab
										? "text-blue-600 border-blue-600 nav-item-active"
										: ""
								}`}
								onClick={() => handleTabClick(tab)}
							>
								{tab}
							</button>
						</li>
					))}
			</ul>
		</div>
	);
};

export default SwitchTab;
