import React from "react";

const FileInputBox = ({ title, onChange, value, style, accept, required }) => {
	return (
		<input
			title={title}
			type="file"
			onChange={onChange}
			value={value}
			accept={accept}
			className={`${style} border p-2 text-sm rounded-md border-gray-300 cursor-pointer`}
			required={required}
		/>
	);
};

export default FileInputBox;
