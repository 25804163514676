import React, { useState, useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { DateBox, Dropdown, InputBox, Button, Table } from "../../components";
import { ADDHOLIDAY, MODIFYHOLIDAY } from "../../Constants";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import ApiCallTwo from "../../utils/ApiCallTwo";
import { AdminListHead } from "../../data";
import SweetAlert from "../../utils/SweetAlert";

const AddHolidays = () => {
	const { authTokens } = useContext(AuthContext);
	const currentYear = new Date().getFullYear();
	const history = useHistory();
	const [inputRows, setInputRows] = useState([
		{ holidayname: "", date: "", optional: "" },
	]);
	const [modifyClicked, setModifyClicked] = useState([]);
	const [holiday, SetHoliday] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCallTwo(MODIFYHOLIDAY, "GET", authTokens);
			//console.log("response", response.data.holidays);
			SetHoliday(response.data.holidays);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	const handleInputChange = (index, field, value) => {
		const updatedRows = [...inputRows];
		updatedRows[index][field] = value;
		setInputRows(updatedRows);
	};

	const handleDeleteRow = (index) => {
		const updatedRows = [...inputRows];
		updatedRows.splice(index, 1);
		setInputRows(updatedRows);
	};

	const handleAddRow = () => {
		setInputRows([...inputRows, { holidayname: "", date: "", optional: "" }]);
	};

	const handleDropdownChange = (index, value) => {
		const updatedRows = [...inputRows];
		updatedRows[index].optional = value;
		setInputRows(updatedRows);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		//console.log("holiday detail", inputRows);

		const params = inputRows.map((row) => ({
			holiday_name: row.holidayname,
			holiday_date: row.date,
			optional: row.optional,
		}));
		try {
			const response = await ApiCall(ADDHOLIDAY, "POST", authTokens, params);
			// console.log("Add holiday datas", response);
			fetchData();
			if (response.status === 200) {
				SweetAlert({ title: "Holiday Added", icon: "success" });
			}
		} catch (error) {
			console.log("Error adding addholiday", error);
		}
	};

	return (
		<div className="w-auto my-6 flex flex-col gap-6 mx-6 py-4 px-2 justify-center">
			<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
				<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
					<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
						Add Holiday Calendar {currentYear}
					</h3>
				</div>

				<div className="p-4 w-full">
					<div className="overflow-x-scroll md:overflow-x-hidden">
						<table className="w-full">
							<thead>
								<tr>
									<th className="text-xl text-left font-bold px-4 py-2">
										Holiday Name
									</th>
									<th className="text-xl text-left font-bold px-4 py-2">
										Holiday Date
									</th>
									<th className="text-xl text-left font-bold px-4 py-2">
										Holiday Type
									</th>
								</tr>
							</thead>
							<tbody>
								{inputRows.map((row, index) => (
									<tr
										key={index}
										style={{ transform: "none", boxShadow: "none" }}
									>
										<td className="px-4 py-2">
											<InputBox
												className="border border-gray-300 rounded px-2 py-1 w-full"
												type="text"
												placeholder="Holiday Name"
												value={row.holidayname || ""}
												onChange={(e) =>
													handleInputChange(
														index,
														"holidayname",
														e.target.value
													)
												}
											/>
										</td>
										<td className="px-4 py-2">
											<DateBox
												value={row.date || ""}
												onChange={(e) =>
													handleInputChange(index, "date", e.target.value)
												}
												style="border border-gray-300 rounded px-2 py-1 w-full h-11"
												required={true}
											/>
										</td>
										<td className="px-4 py-2">
											<Dropdown
												options={[
													{ value: "", label: "Holiday Type" },
													{ value: 1, label: "Optional Holiday" },
													{ value: 0, label: "Holiday" },
												]}
												value={row.optional || ""}
												onChange={(e) =>
													handleDropdownChange(index, e.target.value)
												}
											/>
										</td>
										<td className="px-4 py-2">
											{index === 0 ? (
												<Button
													classname="bg-green-500 text-white px-3 py-2 rounded-md"
													onClick={handleAddRow}
												>
													Add Fields
												</Button>
											) : (
												<Button
													classname="bg-red-500 text-white px-7 py-2 rounded-md"
													onClick={() => handleDeleteRow(index)}
												>
													Delete
												</Button>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				<div className="flex justify-center mt-4">
					<Link to="/admin/holidaycalendar">
						<Button
							onClick={handleSubmit}
							classname="bg-blue-600 text-white font-semibold text-sm px-4 py-2 rounded-md"
							type="submit"
						>
							Submit
						</Button>
					</Link>
				</div>
			</div>

			<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
				<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
					<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
						Holiday Calendar {currentYear}
					</h3>
				</div>
				<Table
					tableStyle="border-spacing-y-[10px] border-separate -mt-4"
					bodyStyle="bg-accent box-shadow"
					header={AdminListHead}
					body={holiday.map((row) => [
						row.id,
						row.name,
						row.date,
						row.optional === true ? "Optional" : null,
					])}
					bodyTdStyle="table-body-td intro-x"
					headTdStyle="table-body-td intro-x"
					btnstyle="intro-x"
				/>

				<div className="flex space-x-4">
					<Link to="/admin/modifiedholiday">
						<Button classname="bg-red-600 text-white font-semibold text-sm px-4 py-3 rounded-md">
							Modify Holiday
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AddHolidays;
