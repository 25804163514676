import React, { useState, useContext } from "react";
import { DateBox, InputBox, ProfileLabel, SubmitBtn } from "../../components";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import SweetAlert from "../../utils/SweetAlert";

const ConDashboard = () => {
	const { authTokens, user } = useContext(AuthContext);
	const currentDate = new Date().toISOString().split("T")[0];
	const [teamName, setTeamName] = useState("");
	const [conDate, setConDate] = useState(currentDate);
	const [conStartTime, setConStartTime] = useState("");
	const [conEndTime, setConEndTime] = useState("");

	const handleTeamName = (e) => {
		e.preventDefault();
		setTeamName(e.target.value);
	};
	const handleConDate = (e) => {
		e.preventDefault();
		setConDate(e.target.value);
	};
	const handleStartTime = (e) => {
		e.preventDefault();
		setConStartTime(e.target.value);
	};
	const handleEndTime = (e) => {
		e.preventDefault();
		setConEndTime(e.target.value);
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		console.log("data", teamName, conDate, conStartTime, conEndTime);
		const params = {
			name: teamName,
			date: conDate,
			start_time: conStartTime,
			end_time: conEndTime,
		};
		// try {
		// 	const response = await ApiCall("POST", authTokens, params);
		// 	// console.log("response", response.status);
		// 	// if (response.status === 200) {
		// 	// 	SweetAlert({ title: "Approved", icon: "success" });
		// 	// } else {
		// 	// 	SweetAlert({ title: "Sorry!, Something went wrong", icon: "failed" });
		// 	// }
		// 	setTeamName("");
		// 	setConDate("");
		// 	setConStartTime("");
		// 	setConEndTime("");
		// } catch (error) {
		// 	console.log("Error While Sending Description Data", error);
		// }
	};
	return (
		<div className="w-auto md:flex my-6 gap-2 mx-6 py-4 px-2">
			<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto md:w-3/6 pb-4 mb-4">
				<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
					<h4 className="mx-6 text-center table-header-text font-bold text-lg">
						Book a Conference Room
					</h4>
				</div>
				<form className="flex flex-col gap-4" onSubmit={handleOnSubmit}>
					<div className="w-full px-3 mb-6 md:mb-0">
						<ProfileLabel lableStyle="text-sm" label="Team Name" />
						<InputBox
							style="w-full"
							type="text"
							value={teamName}
							placeholder="Enter Team Name"
							onChange={handleTeamName}
						/>
					</div>
					<div className="w-full px-3 mb-6 md:mb-0">
						<ProfileLabel lableStyle="text-sm" label="Date" />
						<DateBox
							min={currentDate}
							style="w-full"
							value={conDate}
							onChange={handleConDate}
						/>
					</div>
					<div className="flex gap-3 w-full">
						<div className="w-full px-3 mb-6 md:mb-0">
							<ProfileLabel lableStyle="text-sm w-full" label="Start Time" />
							<InputBox
								style="w-full"
								value={conStartTime}
								type="time"
								onChange={handleStartTime}
							/>
						</div>
						<div className="w-full px-3 mb-6 md:mb-0">
							<ProfileLabel lableStyle="text-sm" label="End Time" />
							<InputBox
								value={conEndTime}
								type="time"
								onChange={handleEndTime}
							/>
						</div>
					</div>
					<div className="mt-4 mb-4 w-full">
						<button
							type="submit"
							className="w-full text-white text-sm font-semibold py-2 rounded-md px-6 bg-button"
						>
							Submit
						</button>
					</div>
				</form>
			</div>
			<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto md:w-3/6 pb-4 mb-4">
				<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
					<h4 className="mx-6 text-center table-header-text font-bold text-lg">
						Booked Details
					</h4>
				</div>
			</div>
		</div>
	);
};

export default ConDashboard;
