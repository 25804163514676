import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import ApiCallTwo from "../../utils/ApiCallTwo";
import AuthContext from "../../contexts/AuthContext";
import { Table, Button } from "../../components";
import { MODIFYHOLIDAY } from "../../Constants";
import { MdKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
const HolidayCalendar = () => {
	const [holiday, SetHoliday] = useState([]);
	const history = useHistory();
	const { authTokens } = useContext(AuthContext);

	const handleback = () => {
		history.goBack();
	};

	const handleGoBack = () => {
		history.goBack();
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCallTwo(MODIFYHOLIDAY, "GET", authTokens);
			//console.log("response", response.data.holidays);
			SetHoliday(response.data.holidays);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	return (
		<>
			<div className="flex justify-start ml-10">
				<Button
					classname="mt-4 ml-1 hover:translate-y-[-3px] hover:font-semibold hover:nav-item-active transition-all ease-in-out"
					onClick={handleGoBack}
				>
					<span className="flex justify-center items-center gap-1 text-sm">
						<MdKeyboardBackspace size={18} />
						Go Back
					</span>
				</Button>
			</div>
			<div className="px-4 py-2  w-full max-w-screen">
				<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
					<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
						<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
							Holiday Calendar - 2023
						</h3>
					</div>
					<Table
						tableStyle="border-spacing-y-[10px] border-separate -mt-4"
						bodyStyle="bg-accent box-shadow"
						// header={timesheetTableHead}
						body={holiday.map((row) => [
							row.id,
							row.name,
							row.date,
							row.optional,
						])}
						bodyTdStyle="table-body-td intro-x"
						headTdStyle="table-body-td intro-x"
						btnstyle="intro-x"
					/>

					<div className="flex space-x-4">
						<Button
							classname="bg-green-600 text-white font-semibold text-sm px-4 py-3 rounded-md"
							onClick={handleback}
						>
							Add New Holiday
						</Button>
						<Link to="/admin/modifiedholiday">
							<Button classname="bg-red-600 text-white font-semibold text-sm px-4 py-3 rounded-md">
								Modify Holiday
							</Button>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default HolidayCalendar;
