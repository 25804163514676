import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import ApiCall from "../../utils/ApiCall";
import { DELETEUSER, EDITUSER } from "../../Constants";
import { Button, Dropdown, Loader, Popup, Table } from "../../components";
import { lmsDeleteUserHead, dropDownPagination } from "../../data";
import SweetAlert from "../../utils/SweetAlert";
import { MdDeleteForever } from "react-icons/md";

const DeleteUser = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [deleteUser, setDeleteUser] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [deletePopup, setDeletePopup] = useState(false);
	const [pagination, setPagination] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem("pagination-data");
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${DELETEUSER}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			//console.log("data", res.data);
			setDeleteUser(res.data.results.users);
			setIsLoading(false);
		} catch (error) {
			console.log("While Fetching Delete User Data", error);
			setIsLoading(false);
		}
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem("pagination-data", JSON.stringify(pageValue));
	};

	const handleDeleteRow = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const id = deleteUser[actualIndex].id;
		setSelectedRow(id);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// console.log("current", currentPage);
	};

	const handleDeleteUser = async (e) => {
		e.preventDefault();
		setDeletePopup(true);
	};

	const handleDeleteConfirm = async (e) => {
		e.preventDefault();
		const params = {
			id: selectedRow,
		};
		// console.log("prams", params);
		try {
			const res = await ApiCall(DELETEUSER, "POST", authTokens, params);
			setDeletePopup(false);
			fetchData();
			setSelectedRow("");
			// console.log("selected row", selectedRow);
			if (res.status === 200) {
				SweetAlert({ title: "Successfully Deleted", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("While Sending Delete User ID", error);
		}
	};
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Delete User
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<div className="w-full flex justify-end items-end px-4">
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					<form
						onSubmit={handleDeleteUser}
						className="w-full flex flex-col justify-center items-center"
					>
						{deleteUser && (
							<Table
								tableStyle="border-spacing-y-[10px] border-separate -mt-4 intro-x"
								bodyStyle="bg-accent box-shadow"
								header={lmsDeleteUserHead}
								body={deleteUser.map((row) => [
									row.id,
									row.name,
									row.email,
									row.role,
								])}
								btnTitle={<MdDeleteForever size={22} />}
								paginationNeed={true}
								currentPage={currentPage}
								rowPerPage={rowPerPage}
								paginationBody={deleteUser}
								onPageChange={handlePageChange}
								onClick={handleDeleteRow}
							/>
						)}
						{/* <Button classname="bg-button text-white px-3 py-1 my-2 rounded-md">
							Delete
						</Button> */}
					</form>
					{deletePopup && (
						<Popup
							title="Delete User"
							trigger={deletePopup}
							setTrigger={setDeletePopup}
							popupstyle="md:w-2/5 w-full"
						>
							<div className="w-full flex-col flex justify-center items-center p-4">
								<h2>Are you sure you want to delete?</h2>
								<div className="w-full flex justify-center items-center gap-4 pt-4">
									<Button
										classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
										onClick={() => setDeletePopup(false)}
									>
										Cancel
									</Button>
									<Button
										classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
										onClick={handleDeleteConfirm}
									>
										Delete
									</Button>
								</div>
							</div>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default DeleteUser;
