import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { ContextProvider } from "./contexts/ContextProvider";
import { AuthProvider } from "./contexts/AuthContext";
import "./App.css";
import {
	AdminPage,
	HelpDeskPage,
	LmsPage,
	MydetailsPage,
	MyteamPage,
	PayrollPage,
	TimesheetPage,
	HomePage,
	UserLogin,
	Training,
	ForgotPasswordPage,
	ChangePasswordPage,
	ConferenceRoomPage,
	OurOrganizationModule,
} from "./pages";
import { Dna } from "react-loader-spinner";
import { Loader } from "./components";

function App() {
	const [isRefreshing, setIsRefreshing] = useState(false);

	// useEffect(() => {
	// 	const handleBeforeUnload = () => {
	// 		setIsRefreshing(true);
	// 	};
	// 	window.addEventListener("beforeunload", handleBeforeUnload);
	// 	return () => {
	// 		window.removeEventListener("beforeunload", handleBeforeUnload);
	// 	};
	// }, []);
	return (
		<div>
			{isRefreshing ? (
				<div className="flex justify-center items-center w-full h-[100vh]">
					<Loader />
				</div>
			) : (
				<Router>
					<Switch>
						<AuthProvider>
							<ContextProvider>
								<PrivateRoute path="/" component={HomePage} exact />
								<Route path="/login" component={UserLogin} />
								<Route path="/forgot-password" component={ForgotPasswordPage} />
								<Route path="/change-password" component={ChangePasswordPage} />
								<Route path="/admin" component={AdminPage} />
								<Route path="/helpdesk" component={HelpDeskPage} />
								<Route path="/lms/" component={LmsPage} />
								<Route path="/mydetails" component={MydetailsPage} />
								<Route path="/myteam/*" component={MyteamPage} />
								<Route path="/our-organization/*" component={OurOrganizationModule} />
								<Route path="/payroll/" component={PayrollPage} />
								<Route path="/timesheet/*" component={TimesheetPage} />
								<Route path="/training" component={Training} />
								<Route path="/conference-room" component={ConferenceRoomPage} />
							</ContextProvider>
						</AuthProvider>
					</Switch>
				</Router>
			)}
		</div>
	);
}

export default App;
