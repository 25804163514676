import React, { useState, useEffect, useContext } from "react";
import LoginImg from "../images/loginbg.jpg";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import SweetAlert from "../utils/SweetAlert";
import { FORGOTPASSWORD, BASE_URL } from "../Constants";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const ForgotPasswordPage = () => {
	const history = useHistory();
	const { authTokens } = useContext(AuthContext);
	const [email, setEmail] = useState("");
	const [response, setResponse] = useState([]);
	const [resmsg, setResmsg] = useState([]);
	const [showMessage, setShowMessage] = useState(false);

	const handleEmail = (e) => {
		e.preventDefault();
		setShowMessage(false);
		setEmail(e.target.value);
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		setShowMessage(true);

		const res = await fetch(BASE_URL + FORGOTPASSWORD, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
			}),
		});

		const data = await res.json();
		//console.log("data", data);

		setResponse(res);
		setResmsg(data.msg);

		if (res.status === 200) {
			SweetAlert({
				title: "Please check your email for new password",
				icon: "success",
			});
			history.push({
				pathname: "/login",
			});
		}
	};
	return (
		<div className="flex  items-center justify-center bg-loginBg py-4 sm:py-8 h-screen">
			<div className="max-w-xl mx-auto overflow-hidden rounded-md bg-white shadow ">
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<div className="relative ">
						<div className="absolute inset-0 bg-gray-800/70"></div>
						<img
							className="h-48 sm:h-96 w-full object-cover sm:max-h-full sm:max-w-full responsive-image"
							src={LoginImg}
							alt="Login Background"
						/>
						<div className="absolute inset-0  flex items-center justify-center flex-col text-center text-white">
							<h1 className="text-2xl font-semibold mb-4">Forgot Password?</h1>
							<p className="text-base pl-1">
								Go back to{" "}
								<Link to="/login" className="underline underline-offset-2">
									sign in page?
								</Link>
								, you can avail full features of the ESS.
							</p>
						</div>
					</div>
					<div className="sm:px-6">
						<div className="flex flex-col justify-center h-full py-6">
							<form className="w-full space-y-4" onSubmit={handleSubmit}>
								<div className="mb-4">
									<label
										className="block text-black text-md font-bold mb-2"
										htmlFor="Email"
									>
										Email
									</label>
									<input
										className="bg-white shadow appearance-none border rounded-lg  py-2 px-3 sm:py-3 sm:px-4 h-12 sm:h-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
										id="email"
										name="email"
										placeholder="Email"
										type="email"
										value={email}
										onChange={handleEmail}
										required
									/>
								</div>
								<div className="pt-3 flex flex-col justify-center">
									{showMessage && response && response.status !== 200 && (
										<h3 className="text-md text-black font-semibold text-center capitalize">
											{resmsg}
										</h3>
									)}
									<button
										className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline w-full sm:py-3 sm:px-4"
										type="submit"
									>
										Submit
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;
