import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import Table from "../../components/Table";
import {
	lmsLeaveHistoryHead,
	dropDownPagination,
	appliedDataLms,
	lmsLeaveHistoryHeadForManager,
} from "../../data";
import { LEAVEHISTORY } from "../../Constants";
import ApiCall from "../../utils/ApiCall";
import { Popup, LmsLeaveDetails, InputBox, Dropdown } from "../../components";
import Loader from "../../components/Loader";

const LeaveHistory = () => {
	const { user } = useContext(AuthContext);
	const year = new Date();
	const fullYear = year.getFullYear();
	const comingYear = fullYear + 1;

	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [leaveHistory, setLeaveHistory] = useState([]);
	const [userHoliday, setUserHoliday] = useState([]);
	const [popupView, setPopupView] = useState(null);
	const [appliedPopup, setAppliedPopup] = useState(null);
	const [finYear, setFinYear] = useState(null);
	const [finYearData, setFinYearData] = useState(
		sessionStorage.getItem("fin-year")
			? JSON.parse(sessionStorage.getItem("fin-year"))
			: `${fullYear}-${comingYear}`
	);
	const [pagination, setPagination] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem("leave-pagination-data");
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
		const savedFinYear = sessionStorage.getItem("fin-year");
		if (savedFinYear) {
			setFinYearData(JSON.parse(savedFinYear));
		}
	}, [pagination, finYearData]);

	const fetchData = async () => {
		try {
			const response = await ApiCall(
				`${LEAVEHISTORY}?year_=${finYearData}&pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			setLeaveHistory(response.data.users);
			setFinYear(response.data.available_years);
			setUserHoliday(response.data.holiday);
			//console.log("Response", response.data.users);
			setIsLoading(false);
		} catch (error) {
			console.log("While fetching Rejected Applications Data", error);
			setIsLoading(false);
		}
	};

	const handlePopupRow = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = leaveHistory[actualIndex];
		setPopupView(currentData);
	};

	const handlePopupTd = (rowIndex, cellIndex) => {
		if (cellIndex === 2) {
			const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
			const currentData = leaveHistory[actualIndex];
			const nameOfClickedRow = currentData.name;
			//console.log("nameOfClickedRow", nameOfClickedRow);
			const userName = userHoliday.filter(
				(user) => user.user_ === nameOfClickedRow
			);
			const userNameDetails = userName;
			setAppliedPopup(userNameDetails);
			// if (userName.length === 1) {
			// 	const userNameDetails = userName;
			// 	setAppliedPopup(userNameDetails);
			// } else {
			// 	const userNameDetails = userName;
			// 	setAppliedPopup(userNameDetails);
			// }
			// console.log("username", userName);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// console.log("current", currentPage);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem("leave-pagination-data", JSON.stringify(pageValue));
	};

	const handleFinYear = (e) => {
		e.preventDefault();
		// console.log("Pagination", e.target.value);
		setFinYearData(e.target.value);
		const finValue = e.target.value;
		sessionStorage.setItem("fin-year", JSON.stringify(finValue));
	};

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Employee Leave Details
			</h2>
			{isLoading ? (
				<div className="flex justify-center items-center w-full h-[60vh]">
					<Loader />
				</div>
			) : (
				<>
					<h4 className="text-md intro-x table-body-td px-2 font-medium mt-4">
						Financial year{" "}
						{finYear && (
							<Dropdown
								value={finYearData}
								onChange={handleFinYear}
								options={finYear.map((option) => ({
									label: option,
									value: option,
								}))}
							/>
						)}
					</h4>
					<div className="w-full flex justify-end items-end px-4">
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					{leaveHistory && (
						<Table
							tableStyle="border-spacing-y-[10px] border-separate -mt-4 intro-x"
							bodyStyle="bg-accent box-shadow"
							header={
								user.user_role === "HR"
									? lmsLeaveHistoryHead
									: lmsLeaveHistoryHeadForManager
							}
							body={leaveHistory.map((row) => {
								const rowData = [row.id, row.name, row.applied];

								if (user.user_role === "HR") {
									rowData.push(row.time_det);
								}

								rowData.push(row.pending);

								return rowData;
							})}
							tdonClick={(rowIndex, cellIndex) => {
								handlePopupTd(rowIndex, cellIndex);
							}}
							btnTitle="View"
							paginationNeed={true}
							currentPage={currentPage}
							rowPerPage={rowPerPage}
							paginationBody={leaveHistory}
							onPageChange={handlePageChange}
							onClick={handlePopupRow}
						/>
					)}
				</>
			)}
			{popupView && (
				<Popup
					title="Employee Leave Details"
					trigger={popupView}
					setTrigger={setPopupView}
					popupstyle="md:w-2/5 w-full"
				>
					<LmsLeaveDetails
						style="flex items-center"
						label="Sick Leaves Pending"
						item={
							<InputBox
								style="w-full"
								value={popupView.sick_leave_pending}
								disabled={true}
							/>
						}
					/>
					<LmsLeaveDetails
						style="flex items-center"
						label="Casual Leaves Pending"
						item={
							<InputBox
								style="w-full"
								value={popupView.casual_leave_pending}
								disabled={true}
							/>
						}
					/>
					<LmsLeaveDetails
						style="flex items-center"
						label="LOP "
						item={
							<InputBox style="w-full" value={popupView.lop} disabled={true} />
						}
					/>
					<LmsLeaveDetails
						style="flex items-center"
						label="Optional Holidays Availed"
						item={
							<InputBox
								style="w-full"
								value={popupView.optional}
								disabled={true}
							/>
						}
					/>
					<LmsLeaveDetails
						style="flex items-center"
						label="Compensation Applied"
						item={
							<InputBox
								style="w-full"
								value={popupView.compensation}
								disabled={true}
							/>
						}
					/>
					<LmsLeaveDetails
						style="flex items-center"
						label="Leave redeemed"
						item={
							<InputBox
								style="w-full"
								value={popupView.redeem}
								disabled={true}
							/>
						}
					/>
				</Popup>
			)}
			{appliedPopup && (
				<Popup
					title="Employee Applied Leaves"
					trigger={appliedPopup}
					setTrigger={setAppliedPopup}
					popupstyle="md:w-4/5 w-full"
				>
					{/* {console.log("applied popup", appliedPopup)} */}
					<Table
						headTdStyle="bg-primary"
						header={appliedDataLms}
						body={
							appliedPopup &&
							appliedPopup.map((row) => [
								row.user_,
								row.type_,
								row.no_days,
								row.description_,
								row.start_date,
								row.end_date,
								row.decision_ === "" ? "Pending" : row.decision_,
							])
						}
					/>
				</Popup>
			)}
		</div>
	);
};

export default LeaveHistory;
