import React, { useState, useEffect, useContext } from "react";
import { Table, Loader, Dropdown, InputBox, Popup } from "../../components";
import AuthContext from "../../contexts/AuthContext";
import ApiCall from "../../utils/ApiCall";
import { USERSKILLTABLE } from "../../Constants";
import {
	dropDownPagination,
	employeeSkillsTableHeader,
	employeeSkillsPopupTableHeader,
} from "../../data";

const EmployeeSkills = () => {
	const { authTokens, user } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [tableData, setTableData] = useState([]);
	const [popupView, setPopupView] = useState(null);
	const [pagination, setPagination] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem("empskills-pagination-data");
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const response = await ApiCall(
				`${USERSKILLTABLE}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			setIsLoading(false);
			setTableData(response.data.skills);
			// console.log("response: ", response.data);
		} catch (error) {
			console.log("Error while getting data", error);
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"empskills-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handlePopupRow = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = tableData[actualIndex];
		setPopupView([currentData]);
	};

	// console.log("popupview", popupView);
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			{user && user.user_role === "CONSULTANT" ? (
				<h2 className="text-lg intro-x table-body-td px-2 font-semibold ">
					Consultant Skills
				</h2>
			) : (
				<h2 className="text-lg intro-x table-body-td px-2 font-semibold ">
					Employee Skills
				</h2>
			)}
			{isLoading ? (
				<div className="flex justify-center items-center w-full h-[60vh]">
					<Loader />
				</div>
			) : (
				<>
					<div className="w-full flex justify-end items-end px-4 intro-x">
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					{tableData && (
						<Table
							tableStyle="border-spacing-y-[10px] border-separate -mt-4 intro-x"
							bodyStyle="bg-accent box-shadow"
							header={employeeSkillsTableHeader}
							body={tableData.map((row) => [
								row.employee_id,
								row.name,
								row.update_date,
							])}
							btnTitle="View"
							paginationNeed={true}
							currentPage={currentPage}
							rowPerPage={rowPerPage}
							paginationBody={tableData}
							onPageChange={handlePageChange}
							onClick={handlePopupRow}
						/>
					)}
				</>
			)}
			{popupView && (
				<Popup
					title="Employee Skills"
					trigger={popupView}
					setTrigger={setPopupView}
					popupstyle="md:w-4/5 w-full"
				>
					<Table
						headTdStyle="bg-primary"
						header={employeeSkillsPopupTableHeader}
						body={
							popupView &&
							popupView.map((row) => [
								row.name,
								row.primary_skill,
								row.secondary_skill,
							])
						}
					/>
				</Popup>
			)}
		</div>
	);
};

export default EmployeeSkills;
