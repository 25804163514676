import React from "react";
import "../css/button.css";
import { motion } from "framer-motion";

const Button = ({ children, onClick, classname, disabled }) => {
	return (
		<motion.button
			whileTap={{ scale: 0.9 }}
			className={`${classname}`}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</motion.button>
	);
};

export default Button;
