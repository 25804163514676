import React from "react";

const DateBox = ({ onChange, value, style, required, disabled, min }) => {
	return (
		<input
			required={required}
			min={min}
			type="date"
			onChange={onChange}
			value={value}
			disabled={disabled}
			className={`${style} bg-transparent border p-2 text-sm rounded-md border-gray-300 cursor-pointer`}
		/>
	);
};

export default DateBox;
