import React from "react";

const Textarea = ({ style, value, onChange, required, disabled }) => {
	return (
		<textarea
			rows="1"
			value={value}
			onChange={onChange}
			className={`${style} bg-transparent rounded-md border-gray-300 border p-2.5 text-sm`}
			placeholder="Your message..."
			required={required}
			disabled={disabled}
		></textarea>
	);
};

export default Textarea;
