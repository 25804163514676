import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { HELPDESKTICKET } from "../../Constants";
import { Table, Loader } from "../../components";
import { helpDeskTickes } from "../../data";

const Tickets = () => {
	const { authTokens, user } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [ticket, setTicket] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCall(HELPDESKTICKET, "GET", authTokens);
			//console.log("helpdesk", response.data);
			setTicket(response.data);
			setLoading(false);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};
	// console.log("tickets", ticket);
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
				<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
					{user && user.user_role === "CONSULTANT" ? (
						<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
							Consultant Tickets
						</h3>
					) : (
						<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
							Employee Tickets
						</h3>
					)}
				</div>
				{loading ? (
					<div className="w-full h-[60vh] flex items-center justify-center intro-x">
						<Loader />
					</div>
				) : ticket && ticket.length > 0 ? (
					<>
						<Table
							tableStyle="border-spacing-y-[10px] border-separate -mt-4"
							bodyStyle="bg-accent box-shadow"
							header={helpDeskTickes}
							body={ticket.map((row) => [
								row.id,
								row.user_name,
								row.category,
								row.description,
								row.ticket_solved === true ? "Closed" : "Open",
							])}
							bodyTdStyle="table-body-td intro-x"
							headTdStyle="table-body-td intro-x"
							btnstyle="intro-x"
						/>
					</>
				) : (
					<h2 className="text-center font-semibold">No Tickets</h2>
				)}
			</div>
		</div>
	);
};

export default Tickets;
