import React from "react";
import Dropdown from "./Dropdown";
import DateBox from "./DateBox";
import Textarea from "./Textarea";
import InputBox from "./InputBox";
import LmsForm from "./LmsForm";

const LmsProfileForm = ({
	handleSubmit,
	optionsOne,
	optionsTwo,
	handleStartDate,
	handleEndDate,
	description,
	handleAreaValue,
	startDate,
	endDate,
	totalLeave,
	handleType,
	handleLeave,
	selectedOptionLeave,
	handleOptionalLeave,
	optionalLeave,
	handleOptionalSubmit,
}) => {
	//console.log(selectedOptionLeave);
	//console.log(optionalLeave);
	return (
		<>
			{selectedOptionLeave === "optional" ? (
				<LmsForm
					style="mb-0"
					onSubmit={handleOptionalSubmit}
					labelOne="Category"
					itemOne={
						<Dropdown
							onChange={handleLeave}
							style="w-full"
							options={optionsOne}
						/>
					}
					labelTwo="Name"
					itemTwo={
						<Dropdown
							onChange={handleOptionalLeave}
							style="w-full"
							options={[
								{ value: "", label: "Select" },
								...optionalLeave.map((item) => ({
									value: item.name,
									label: `${item.name} - ${item.date}`,
								})),
							]}
						/>
					}
					Submit="Submit"
				/>
			) : (
				<LmsForm
					style="mb-6"
					onSubmit={handleSubmit}
					labelOne="Category"
					itemOne={
						<Dropdown
							onChange={handleLeave}
							style="w-full"
							options={optionsOne}
						/>
					}
					labelTwo="Type"
					itemTwo={
						<Dropdown
							onChange={handleType}
							style="w-full"
							options={optionsTwo}
						/>
					}
					labelThree="Start"
					itemThree={
						<DateBox
							required={true}
							style="w-full"
							value={startDate}
							onChange={handleStartDate}
						/>
					}
					labelFour="End"
					itemFour={
						<DateBox
							required={true}
							value={endDate}
							style="w-full"
							onChange={handleEndDate}
						/>
					}
					labelFive="No of Days"
					itemFive={
						<InputBox
							value={totalLeave}
							style="w-full"
							placeholder="No of days"
							disabled="disabled"
						/>
					}
					labelSix="Description"
					itemSix={
						<Textarea
							required={true}
							value={description}
							onChange={handleAreaValue}
							style="w-full h-32"
						/>
					}
					Submit="Submit"
				/>
			)}
		</>
	);
};

export default LmsProfileForm;
