import React from "react";
import { motion } from "framer-motion";

const SubmitBtn = ({ onClick }) => {
	return (
		<motion.button
			whileTap={{ scale: 0.8 }}
			transition={{ duration: 0.5, ease: "easeInOut" }}
			onClick={onClick}
			className="bg-submitBtn text-sm px-6 rounded-md p-2 text-white"
		>
			Submit
		</motion.button>
	);
};

export default SubmitBtn;
