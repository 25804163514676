import React, { useState, useEffect, useContext } from "react";
import LmsProfileForm from "./LmsProfileForm";
import LmsCards from "./LmsCards";
import { eachDayOfInterval, format, parseISO } from "date-fns";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import { APPLYLEAVE, HOLIDAYCALENDARLEAVES } from "../Constants";
import { useStateContext } from "../contexts/ContextProvider";
import SweetAlert from "../utils/SweetAlert";

const LeaveRequest = () => {
	const { cardData } = useStateContext();
	const { authTokens } = useContext(AuthContext);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [selectedOptionLeave, setSelectedOptionLeave] = useState("sick");
	const [selectedOptionType, setSelectedOptionType] = useState("half");
	const [selectedOptinalLeaveType, setSelectedOptinalLeaveType] =
		useState("2023-06-29");
	const [description, setDescription] = useState("");
	const [totalLeave, setTotalLeave] = useState("");
	const [optionalHoliday, setOptionalHoliday] = useState([]);
	const [holidayLeaves, setHolidayLeaves] = useState([]);
	const { toast, snackbar } = require("tailwind-toast");

	const optionsOne = [
		{ label: "Sick Leave", value: "sick" },
		{ label: "Casual Leave", value: "casual" },
		{ label: "Optional Leave", value: "optional" },
		{ label: "Privilege Leave", value: "granted" },
	];
	const optionsTwo = [
		{ label: "Half Day", value: "half" },
		{ label: "Full Day", value: "full" },
	];

	useEffect(() => {
		fetchOptionalFilterData();
		fetchHolidayLeave();
	}, []);

	const fetchOptionalFilterData = async () => {
		try {
			const res = await ApiCall(APPLYLEAVE, "GET", authTokens, null);
			//console.log("optional filter", res.data);
			setOptionalHoliday(res.data.holiday);
		} catch (error) {
			console.log("Error , while fetching filterOptionData", error);
		}
	};

	const fetchHolidayLeave = async () => {
		try {
			const res = await ApiCall(
				HOLIDAYCALENDARLEAVES,
				"POST",
				authTokens,
				null
			);
			// console.log("HolidayLeaves", res.data.holiday);
			setHolidayLeaves(res.data.holiday);
		} catch (error) {
			console.log("Error , while fetching Holiday Leaves", error);
		}
	};

	const handleLeave = (event) => {
		event.preventDefault();
		setSelectedOptionLeave(event.target.value);
	};

	const handleType = (event) => {
		event.preventDefault();
		setSelectedOptionType(event.target.value);
	};

	const handleOptionalLeave = (event) => {
		event.preventDefault();
		setSelectedOptinalLeaveType(event.target.value);
	};

	const handleStartDate = (event) => {
		event.preventDefault();
		setStartDate(event.target.value);
	};
	// console.log("start Date", startDate);

	const handleEndDate = (event) => {
		event.preventDefault();
		const selectedDate = event.target.value;
		setEndDate(selectedDate);
	};

	useEffect(() => {
		if (startDate && endDate) {
			const calDuration = leaveDuration(endDate);
			setEndDate(calDuration);
		}
	}, [startDate, endDate, selectedOptionType]);

	const leaveDuration = (selectedDate) => {
		const start = parseISO(startDate);
		const end = parseISO(selectedDate);

		if (start > end || end < start) {
			setTotalLeave(0);
			// console.log("going", totalLeave);
			return format(end, "yyyy-MM-dd");
		}

		const dates = eachDayOfInterval({
			start: start,
			end: end,
		});

		// console.log("dates", dates);

		const leaveDays = dates
			.map((date) => format(date, "yyyy-MM-dd"))
			.filter((date) => {
				const day = new Date(date).getDay();
				return day !== 6 && day !== 0;
			});

		// console.log("leave days", leaveDays);
		const filteredDates = leaveDays.filter(
			(date) => !holidayLeaves.includes(date)
		);
		// console.log("filtered Dates", filteredDates);

		const totalLeaveDays = filteredDates.length;
		// console.log("total Leave Days", totalLeaveDays);
		let calculatedTotalLeave = totalLeaveDays;

		if (selectedOptionType === "half") {
			calculatedTotalLeave = totalLeaveDays / 2;
		}

		setTotalLeave(calculatedTotalLeave);

		return format(end, "yyyy-MM-dd");
	};

	const handleAreaValue = (event) => {
		event.preventDefault();
		setDescription(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		// console.log("API working");
		const params = {
			selectedOptionLeave,
			selectedOptionType,
			startDate,
			endDate,
			totalLeave,
			description,
		};

		try {
			const response = await ApiCall(APPLYLEAVE, "POST", authTokens, params);
			// console.log("Leave Request", response);
			if (response.status === 200) {
				SweetAlert({ title: "Successfully Applied", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
			}
			setStartDate("");
			setEndDate("");
			setTotalLeave("");
			setDescription("");
		} catch (error) {
			console.error("Error fetching data", error);
		}
	};

	const handleOptionalSubmit = async (event) => {
		event.preventDefault();
		// console.log("API working");
		const params = {
			selectedOptionLeave,
			selectedOptinalLeaveType,
		};
		//console.log("params", params);
		try {
			const response = await ApiCall(APPLYLEAVE, "POST", authTokens, params);
			if (response.status === 200) {
				SweetAlert({ title: "Successfully Applied", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "failed" });
			}
		} catch (error) {
			console.error("Error fetching data", error);
		}
	};

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 pb-6">
				{cardData &&
					cardData.map((item, index) => (
						<LmsCards
							key={index}
							number={item.number}
							title={item.title}
							icons={item.icons}
							color={item.color}
						/>
					))}
			</div>
			<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
				<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
					<h4 className="mx-6 text-center table-header-text font-bold text-lg">
						Leave Request
					</h4>
				</div>
				<LmsProfileForm
					selectedOptionLeave={selectedOptionLeave}
					setSelectedOptionLeave={setSelectedOptionLeave}
					selectedOptionType={selectedOptionType}
					setSelectedOptionType={setSelectedOptionType}
					startDate={startDate}
					endDate={endDate}
					handleLeave={handleLeave}
					handleSubmit={handleSubmit}
					handleStartDate={handleStartDate}
					handleEndDate={handleEndDate}
					optionsOne={optionsOne}
					optionsTwo={optionsTwo}
					handleAreaValue={handleAreaValue}
					description={description}
					totalLeave={totalLeave}
					handleType={handleType}
					optionalLeave={optionalHoliday}
					handleOptionalSubmit={handleOptionalSubmit}
					handleOptionalLeave={handleOptionalLeave}
				/>
			</div>
		</div>
	);
};

export default LeaveRequest;
