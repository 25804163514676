import React from "react";
import LmsForm from "./LmsForm";
import Dropdown from "./Dropdown";
import DateBox from "./DateBox";
import Textarea from "./Textarea";
import InputBox from "./InputBox";

const LmsCompensationForm = ({
	handleSubmit,
	compenSelectedOptionType,
	handleCompenStartDate,
	handleCompenEndDate,
	optionsTwo,
	handleCompenAreaValue,
	compenDescription,
	compenTotalLeave,
	handleCompenType,
	compenStartDate,
	compenEndDate,
}) => {
	return (
		<LmsForm
			style="mb-6"
			onSubmit={handleSubmit}
			labelOne="Start"
			itemOne={
				<DateBox
					required={true}
					style="w-full"
					value={compenStartDate}
					onChange={handleCompenStartDate}
				/>
			}
			labelTwo="End"
			itemTwo={
				<DateBox
					required={true}
					style="w-full"
					value={compenEndDate}
					onChange={handleCompenEndDate}
				/>
			}
			labelThree="Type"
			itemThree={
				<Dropdown
					style="w-full"
					onChange={handleCompenType}
					options={optionsTwo}
				/>
			}
			labelFour="No of Days"
			itemFour={
				<InputBox
					style="w-full"
					value={compenTotalLeave}
					placeholder="No of days"
					disabled="disabled"
				/>
			}
			labelFive="Description"
			itemFive={
				<Textarea
					required={true}
					style="w-full h-32"
					value={compenDescription}
					onChange={handleCompenAreaValue}
				/>
			}
			Submit="Submit"
		/>
	);
};

export default LmsCompensationForm;
