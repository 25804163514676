import React, { useState } from "react";

const Dropdown = ({ options, style, onChange, value, required }) => {
	return (
		<select
			value={value}
			className={`text-sm rounded-md bg-transparent border-gray-300 cursor-pointer border focus:ring-blue-500 focus:border-blue-500 p-2.5 ${style}`}
			onChange={onChange}
			required={required ? required : false}
		>
			{options
				? options.map((option) => (
						<option
							className="text-white bg-sky-600"
							key={option.value}
							value={option.value}
						>
							{option.label}
						</option>
				  ))
				: null}
		</select>
	);
};

export default Dropdown;
