import React, { useContext } from "react";
import { FormRow, Textarea, InputBox, Button } from "../../components";
import { useLocation, useHistory } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import AuthContext from "../../contexts/AuthContext";

const ClosedTicketView = () => {
	const { user } = useContext(AuthContext);
	const location = useLocation();
	const history = useHistory();
	const closedticketData = location.state?.closedticketData;
	const attachmentPic = location.state?.attachmentPic;

	// console.log("closedticket data", closedticketData);
	const handleGoBack = () => {
		if (user.user_role === "EMPLOYEE" || user.user_role === "TRAINEE") {
			history.push({
				pathname: "/helpdesk/closed-ticket",
			});
		} else {
			history.push({
				pathname: "/helpdesk/closedticket",
			});
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2 flex items-center justify-center">
			<div className="w-full md:w-4/5 sm:w-full my-6 mx-6 py-4 px-2">
				<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
					<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
						<div className="flex justify-start mb-5 w-full md:w-2/5 ml-2">
							<Button
								classname="mt-4 ml-1 hover:translate-y-[-3px] hover:font-semibold hover:nav-item-active transition-all ease-in-out"
								onClick={handleGoBack}
							>
								<span className="flex justify-center items-center gap-1 text-sm text-[#ffffffc7]">
									<MdKeyboardBackspace size={18} />
									Go Back
								</span>
							</Button>
						</div>
						<h3 className="text-center mr-16 md:mr-36 pr-6 table-header-text font-bold text-md md:text-lg w-full">
							Ticket View
						</h3>
					</div>

					<FormRow
						label="Employee Name"
						item={
							<InputBox
								style="h-10 w-full text-md"
								className="p-2 border border-gray-300 rounded"
								value={closedticketData && closedticketData.name}
								disabled={true}
							/>
						}
					/>

					<FormRow
						label="Category"
						item={
							<InputBox
								style="h-10 w-full text-md"
								className="p-2 border border-gray-300 rounded"
								value={closedticketData && closedticketData.category}
								disabled={true}
							/>
						}
					/>

					<FormRow
						label="Description"
						item={
							<Textarea
								style="h-20 w-full text-md"
								className="p-2 border border-gray-300 rounded"
								value={closedticketData && closedticketData.description}
								disabled={true}
							/>
						}
					/>

					<FormRow
						label="Ticket Solved"
						item={
							<Button classname="bg-red-600 text-white font-semibold text-sm px-4 py-2 rounded-md">
								Closed
							</Button>
						}
					/>

					<div className="p-2 w-full text-center flex flex-col justify-center items-center">
						<h1 className="p-2 font-semibold text-lg">Attachment</h1>
						<img src={attachmentPic} className="p-2 w-64" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClosedTicketView;
