import React, { useContext } from "react";
import OurOrganizationChart from '../sub-pages/OurOrganization/OurOrganizationChart';
import Layout from "../components/Layout";
import { Sidebar } from "../components";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import { OurOrganization } from "../data";


const OurOrganizationModule = () => {
    const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<Layout sidebar={<Sidebar sidebarLinks={OurOrganization} />}>
			<Route path="/our-organization/our-organization-chart" component={OurOrganizationChart} />
		</Layout>
	);
};

export default OurOrganizationModule;
