import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import Table from "../../components/Table";
import { dropDownPagination, appliedDataLms, wfhHistoryHead } from "../../data";
import { WFHHISTORY } from "../../Constants";
import ApiCall from "../../utils/ApiCall";
import { Popup, Dropdown } from "../../components";
import Loader from "../../components/Loader";

const WorkFromHomeHistory = () => {
	const { user } = useContext(AuthContext);
	const year = new Date();
	const fullYear = year.getFullYear();
	const comingYear = fullYear + 1;

	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [wfhHHistory, setWfhHHistory] = useState([]);
	const [userHoliday, setUserHoliday] = useState([]);
	const [popupView, setPopupView] = useState(null);
	const [appliedPopup, setAppliedPopup] = useState(null);
	const [finYear, setFinYear] = useState(null);
	const [finYearData, setFinYearData] = useState(
		sessionStorage.getItem("fin-year-two")
			? JSON.parse(sessionStorage.getItem("fin-year-two"))
			: `${fullYear}-${comingYear}`
	);
	const [pagination, setPagination] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem("wfhH-pagination-data-two");
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
		const savedFinYear = sessionStorage.getItem("fin-year-two");
		if (savedFinYear) {
			setFinYearData(JSON.parse(savedFinYear));
		}
	}, [pagination, finYearData]);

	const fetchData = async () => {
		try {
			const response = await ApiCall(
				`${WFHHISTORY}?year_=${finYearData}&pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			setWfhHHistory(response.data.users);
			setFinYear(response.data.available_years);
			setUserHoliday(response.data.workfromhome);
			// console.log("Response", response);
			setIsLoading(false);
		} catch (error) {
			console.log("While fetching Rejected Applications Data", error);
			setIsLoading(false);
		}
	};

	const handlePopupRow = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = wfhHHistory[actualIndex];
		setPopupView(currentData);
	};

	const handlePopupTd = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = wfhHHistory[actualIndex];
		const nameOfClickedRow = currentData.name;
		const userName = userHoliday.filter(
			(user) => user.user_ === nameOfClickedRow
		);
		const userNameDetails = userName;
		setAppliedPopup(userNameDetails);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// console.log("current", currentPage);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"wfhH-pagination-data-two",
			JSON.stringify(pageValue)
		);
	};

	const handleFinYear = (e) => {
		e.preventDefault();
		// console.log("Pagination", e.target.value);
		setFinYearData(e.target.value);
		const finValue = e.target.value;
		sessionStorage.setItem("fin-year-two", JSON.stringify(finValue));
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Employee Work From Home Details
			</h2>
			{isLoading ? (
				<div className="flex justify-center items-center w-full h-[60vh]">
					<Loader />
				</div>
			) : (
				<>
					<h4 className="text-md intro-x table-body-td px-2 font-medium mt-4">
						Financial year{" "}
						{finYear && (
							<Dropdown
								value={finYearData}
								onChange={handleFinYear}
								options={finYear.map((option) => ({
									label: option,
									value: option,
								}))}
							/>
						)}
					</h4>
					<div className="w-full flex justify-end items-end px-4">
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					{wfhHHistory && (
						<Table
							tableStyle="border-spacing-y-[10px] border-separate -mt-4 intro-x"
							bodyStyle="bg-accent box-shadow"
							header={wfhHistoryHead}
							body={wfhHHistory.map((row) => {
								const rowData = [row.id, row.name, row.applied];

								rowData.push(row.pending);

								return rowData;
							})}
							btnTitle="View"
							paginationNeed={true}
							currentPage={currentPage}
							rowPerPage={rowPerPage}
							paginationBody={wfhHHistory}
							onPageChange={handlePageChange}
							onClick={handlePopupTd}
						/>
					)}
				</>
			)}

			{appliedPopup && (
				<Popup
					title="Employee Work From Home Details"
					trigger={appliedPopup}
					setTrigger={setAppliedPopup}
					popupstyle="md:w-4/5 w-full"
				>
					{/* {console.log("applied popup", appliedPopup)} */}
					<Table
						headTdStyle="bg-primary"
						header={appliedDataLms}
						body={
							appliedPopup &&
							appliedPopup.map((row) => [
								row.user_,
								row.type_,
								row.no_days,
								row.description_,
								row.start_date,
								row.end_date,
								row.decision_ === "" ? "Pending" : row.decision_,
							])
						}
					/>
				</Popup>
			)}
		</div>
	);
};

export default WorkFromHomeHistory;
