import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import ApiCall from "../../utils/ApiCall";
import { FormRow, InputBox, Textarea, Button } from "../../components";
import { NOTIFICATIONALERTDATA } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";

const Announcement = () => {
	const { authTokens } = useContext(AuthContext);
	// const [userData, setUserData] = useState([]);
	const [selectedInput, setSelectedInput] = useState("");
	const [selectedDescription, setSelectedDescription] = useState("");
	const [selectedDate, setSelectedDate] = useState("");
	// const [value,setValue] = useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const params = {
			title: selectedInput,
			description: selectedDescription,
			datetime: selectedDate,
		};
		// console.log("params", params);
		try {
			const response = await ApiCall(
				NOTIFICATIONALERTDATA,
				"POST",
				authTokens,
				params
			);
			setSelectedInput("");
			setSelectedDescription("");
			setSelectedDate("");
			if (response.status === 200) {
				SweetAlert({ title: "Announcement Added", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry! Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};
	return (
		<>
			<div className="w-auto my-6 mx-6 py-4 px-2 flex items-center justify-center">
				<div className="w-full md:w-[550px]  transition rounded-3xl mt-4 pb-4 flex flex-col items-center">
					<div className="w-full intro-x bg-blue-800 justify-center flex mt-0 rounded-t-3xl items-center py-3">
						<h4 className="table-header-text text-center font-bold text-sm md:text-lg">
							ADMIN ANNOUNCEMENT
						</h4>
					</div>

					<form
						className="w-full bg-accent rounded-bl-3xl rounded-br-3xl"
						onSubmit={handleSubmit}
					>
						{" "}
						<FormRow
							formRowStyle="row-hover my-2"
							label="Title"
							item={
								<InputBox
									required={true}
									style="w-full"
									type="text"
									value={selectedInput}
									onChange={(e) => setSelectedInput(e.target.value)}
									className="p-2 border border-gray-300 rounded ml-2 sm:ml-6 w-full"
								/>
							}
						/>
						<FormRow
							formRowStyle="row-hover my-2"
							label="Description"
							item={
								<Textarea
									required={true}
									style="h-20 w-full text-md"
									value={selectedDescription}
									onChange={(e) => setSelectedDescription(e.target.value)}
								/>
							}
						/>
						<FormRow
							formRowStyle="row-hover my-2"
							label="Date and Time"
							value={selectedDate}
							onChange={(e) => setSelectedDate(e.target.value)}
							item={
								<input
									required
									type="datetime-local"
									value={selectedDate}
									onChange={(e) => setSelectedDate(e.target.value)}
									className="shadow text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline time"
								/>
							}
						/>
						<div className="intro-x w-full flex justify-center">
							<Button classname="uppercase mb-3 bg-blue-600 flex justify-center text-white font-semibold text-sm px-4 py-2 rounded-md mt-5">
								Submit
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default Announcement;
