import React, { useState, useContext, useEffect } from "react";
import { DateBox, Dropdown, InputBox, Button } from "../../components";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { MODIFYHOLIDAY } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";

const ModifiedHoliday = () => {
	const { authTokens } = useContext(AuthContext);
	const [modifyHoliday, setModifyHoliday] = useState([]);
	const [editableModifyHoliday, setEditableModifyHoliday] = useState([]);

	const handleInputChange = (index, field, value) => {
		setEditableModifyHoliday((prevData) =>
			prevData.map((row, rowIndex) =>
				rowIndex === index ? { ...row, [field]: value } : row
			)
		);
	};

	useEffect(() => {
		setEditableModifyHoliday(modifyHoliday);
	}, [modifyHoliday]);

	const handleDropdownChange = (index, value) => {
		const updatedRows = [...editableModifyHoliday];
		updatedRows[index].optional = value;
		setEditableModifyHoliday(updatedRows);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCall(MODIFYHOLIDAY, "GET", authTokens);
			//console.log("modify", response.data.holidays);
			setModifyHoliday(response.data.holidays);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	const handleUpdate = async (index) => {
		const updatedRow = editableModifyHoliday[index];

		const params = {
			holiday_name: updatedRow.name,
			holiday_date: updatedRow.date,
			optional: updatedRow.optional,
			id: updatedRow.id,
		};

		//console.log("params", params);
		try {
			const response = await ApiCall(MODIFYHOLIDAY, "POST", authTokens, params);
			fetchData();
			if (response.status === 200) {
				SweetAlert({ title: "Holiday Updated", icon: "success" });
			}
		} catch (error) {
			console.log("Error updating holiday", error);
		}
	};

	const handleDeleteRow = async (index) => {
		const deleteRow = editableModifyHoliday[index];
		const deleteId = deleteRow.id;
		const params = {
			id: deleteId,
		};

		try {
			const res = await ApiCall(MODIFYHOLIDAY, "DELETE", authTokens, params);
			//console.log("ID", res);
			fetchData();
			if (res.status === 200) {
				SweetAlert({ title: "Holiday Deleted", icon: "success" });
			}
		} catch (error) {
			console.log("Error deleting file", error);
		}
	};

	return (
		<>
			<div className="w-auto my-6 flex flex-col gap-6 mx-6 py-4 px-2 justify-center">
				<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
					<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
						<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
							Modified Holiday Calendar - 2023
						</h3>
					</div>

					<div className="p-4 w-full">
						<div className="overflow-x-scroll md:overflow-x-hidden">
							<table className="w-full">
								<thead>
									<tr>
										<th className="text-lg font-bold px-4 py-2 text-left">
											Holiday Name
										</th>
										<th className="text-lg font-bold px-4 py-2 text-left">
											Holiday Date
										</th>
										<th className="text-lg font-bold px-4 py-2 text-left">
											Holiday Type
										</th>
									</tr>
								</thead>
								<tbody>
									{editableModifyHoliday.map((row, index) => (
										<tr
											key={index}
											style={{ transform: "none", boxShadow: "none" }}
										>
											<td className="px-4 py-2">
												<InputBox
													className="border border-gray-300 rounded px-2 py-1 w-full"
													type="text"
													placeholder="Holiday Name"
													value={row.name}
													onChange={(e) =>
														handleInputChange(index, "name", e.target.value)
													}
												/>
											</td>
											<td className="px-4 py-2">
												<DateBox
													value={row.date}
													onChange={(e) =>
														handleInputChange(index, "date", e.target.value)
													}
													style="border border-gray-300 rounded px-2 py-1 w-full h-11"
													required={true}
												/>
											</td>
											<td className="px-4 py-2">
												<Dropdown
													options={[
														{ value: "", label: "Holiday Type" },
														{ value: 1, label: "Optional Holiday" },
														{ value: 0, label: "Holiday" },
													]}
													value={row.optional}
													onChange={(e) =>
														handleDropdownChange(index, e.target.value)
													}
												/>
											</td>
											<td className="px-4 py-2">
												<div className="flex space-x-4">
													<Button
														classname="bg-green-500 text-white px-3 py-2 rounded-md"
														onClick={() => handleUpdate(index)}
													>
														Update
													</Button>

													<Button
														classname="bg-red-500 text-white px-3 py-2 rounded-md"
														onClick={() => handleDeleteRow(index)}
													>
														Delete
													</Button>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModifiedHoliday;
