import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { motion } from "framer-motion";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import Logo from "../images/logo.png";

const Sidebar = ({ sidebarLinks }) => {
	const location = useLocation();
	const { activeItem, setActiveItem, screenSize } = useStateContext();

	const handleSidebarClose = () => {
		if (activeItem !== undefined && screenSize <= 900) {
			setActiveItem(false);
		}
	};

	const activeMenu =
		"p-2 m-1 text-md nav-item bg-base-100 nav-item-active hover:p-2 transition-all ease-in-out rounded-e-3xl border-l-4 border-base-200";
	const nonActive =
		"p-2 m-1 text-md nav-item hover:p-2 hover:rounded-e-3xl transition-all ease-in-out hover:border-l-4 m-1 ";

	return (
		<motion.div
			initial={{ x: "-100%" }}
			animate={{ x: 0 }}
			exit={{ x: "-100%" }}
			transition={{ duration: 0.3 }}
			className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"
		>
			{activeItem && (
				<>
					<div className="flex justify-between items-center">
						<Link
							to="/"
							onClick={handleSidebarClose}
							className="w-full border-b-[#ffffff14]"
						>
							<img
								src={Logo}
								className="md:w-52 md:h-auto w-32 h-auto"
								alt="Logo"
							/>
						</Link>
						<motion.div
							whileTap={{ scale: 0.9 }}
							className="mt-4 p-2 md:hidden block"
						>
							<button
								onClick={() =>
									setActiveItem((prevActiveItem) => !prevActiveItem)
								}
								className="bg-btnOne rounded-2xl p-1 text-white text-md"
							>
								<MdKeyboardBackspace />
							</button>
						</motion.div>
					</div>

					<div className="mt-3 flex flex-col p-2">
						{sidebarLinks.map((item) => (
							<NavLink
								to={`/${item.url}`}
								key={item.title}
								onClick={handleSidebarClose}
								isActive={() => {
									// Determine if the current route matches the link's destination
									const currentPath = location.pathname;
									return currentPath === `/${item.url}`;
								}}
								activeClassName={activeMenu}
								className={nonActive}
							>
								<motion.div
									whileTap={{ scale: 0.9 }}
									className="flex items-center"
								>
									<span className="text-lg">{item.icon}</span>
									<span className="ml-5">{item.title}</span>
									<span className="ml-3">{item.notification}</span>
								</motion.div>
							</NavLink>
						))}
					</div>
				</>
			)}
		</motion.div>
	);
};

export default Sidebar;
