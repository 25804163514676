import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../../css/conhistory.css";

const ConHistory = () => {
	// const eventPropGetter = (event) => {
	// 	const color = getColor(event);
	// 	return { style: { backgroundColor: color } };
	// };
	// const getColor = (event) => {
	// 	switch (event.type) {
	// 		case "meeting":
	// 			return "#357CD2";
	// 		case "event":
	// 			return "#00BDAE";
	// 		case "clientcall":
	// 			return "#1AAA55";
	// 		case "groupdiscussion":
	// 			return "#EA7A57";
	// 		default:
	// 			return "#c084fc";
	// 	}
	// };
	// const tooltipAccessor = (event) => {
	// 	return `${event.title} - ${event.description}`;
	// };
	// return (
	// 	<div className="p-5 monthcalendar">
	// 		{/* <div style={{ height: "550pt" }}>
	// 			<Calendar
	// 				className="p-10 calendarbg rounded-xl "
	// 				events={MyEventsList}
	// 				startAccessor="start"
	// 				endAccessor="end"
	// 				defaultDate={moment().toDate()}
	// 				localizer={localizer}
	// 				tooltipAccessor={tooltipAccessor}
	// 				eventPropGetter={eventPropGetter}
	// 			/>
	// 		</div> */}
	// 	</div>
	// );
};

export default ConHistory;
