import React from "react";
import { MdNotificationsActive } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";
import "../css/notification.css";

const NotificationTimesheet = () => {
	const { notify } = useStateContext();
	return (
		<div>
			{notify ? (
				<span className="notification">
					<MdNotificationsActive />
				</span>
			) : (
				<MdNotificationsActive />
			)}
		</div>
	);
};

export default NotificationTimesheet;
