import React, { createContext, useContext, useState, useEffect } from "react";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import {
	EMPREPORTTO,
	LMSDASHBOARD,
	NOTIFYALERT,
	MYDETAILSDATA,
	MYPROFILE,
	TECHSUPPORTSEEN,
} from "../Constants";
import { cardIcons } from "../data";
import ApiCallTwo from "../utils/ApiCallTwo";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
	const { authTokens, user } = useContext(AuthContext);
	const [activeItem, setActiveItem] = useState(true);
	const [screenSize, setScreenSize] = useState(undefined);
	const [sitetheme, setSiteTheme] = useState(
		localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
	);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [cardData, setCardData] = useState([]);
	const [notify, setNotify] = useState([]);
	const [reportTo, setReportTo] = useState([]);
	const [techTrue, setTechTrue] = useState([]);
	const [userPicData, setUserPicData] = useState([]);
	const [imgLoader, setImgLoader] = useState(true);

	useEffect(() => {
		if (
			(user && user.user_role === "MANAGER") ||
			(user && user.user_role === "EMPLOYEE") ||
			(user && user.user_role === "TRAINEE") ||
			(user && user.user_role === "INTERN") ||
			(user && user.user_role === "CONSULTANT")
		) {
			notifyAlert();
		}
	}, []);

	const notifyAlert = async () => {
		try {
			const res = await ApiCall(NOTIFYALERT, "GET", authTokens, null);
			setNotify(res.data.notification);
			//console.log("notifyAlert", res.data);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	useEffect(() => {
		if (
			(user && user.user_role === "EMPLOYEE") ||
			(user && user.user_role === "TRAINEE") ||
			(user && user.user_role === "CONSULTANT")
		) {
			reportData();
		}
	}, [user]);

	const reportData = async () => {
		try {
			const res = await ApiCall(EMPREPORTTO, "GET", authTokens, null);
			const ress = await ApiCall(TECHSUPPORTSEEN, "GET", authTokens, null);
			setReportTo(res.data.seens);
			setTechTrue(ress.data.seens);
			//console.log("report to", res.data);
			//console.log("report to", ress.data);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	useEffect(() => {
		if (
			(user && user.user_role === "MANAGER") ||
			(user && user.user_role === "HR") ||
			(user && user.user_role === "EMPLOYEE") ||
			(user && user.user_role === "TRAINEE") ||
			(user && user.user_role === "INTERN") ||
			(user && user.user_role === "CONSULTANT")
		) {
			fetchData();
		}
	}, [user]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(LMSDASHBOARD, "GET", authTokens, null);
			const modifiedCardData = res.data.map((item, index) => ({
				...item,
				icons: cardIcons[index % cardIcons.length],
			}));
			setCardData(modifiedCardData);
			// console.log("lms dashboard", res);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	useEffect(() => {
		if (user) {
			fetchUserProfileData();
		}
	}, [user]);

	const fetchUserProfileData = async () => {
		try {
			const res = await ApiCallTwo(MYPROFILE, "GET", authTokens, null);
			//console.log("hhh", res.data[0].photo);
			if (res.data !== null) {
				const url = res.data[0].photo;
				setUserPicData(url);
				setImgLoader(false);
			}
		} catch (error) {
			console.log("Error while fetching data from api", error);
		}
	};

	return (
		<StateContext.Provider
			value={{
				activeItem,
				setActiveItem,
				screenSize,
				setScreenSize,
				sitetheme,
				setSiteTheme,
				email,
				setEmail,
				password,
				setPassword,
				cardData,
				notify,
				reportTo,
				techTrue,
				fetchUserProfileData,
				userPicData,
				imgLoader,
				notifyAlert,
			}}
		>
			{children}
		</StateContext.Provider>
	);
};

export const useStateContext = () => useContext(StateContext);
