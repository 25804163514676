import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import { NOTIFICATION } from "../Constants";
import Table from "./Table";
import Loader from "./Loader";
import { useStateContext } from "../contexts/ContextProvider";
import NoDataImg from "../images/no_data.svg";

const Notification = () => {
	const { authTokens } = useContext(AuthContext);
	const { notifyAlert } = useStateContext();
	const [isLoading, setIsLoading] = useState(true);
	const [rowDetails, setRowDetails] = useState([]);

	useEffect(() => {
		fetchData();
		notifyAlert();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(NOTIFICATION, "GET", authTokens, null);
			//console.log("res", res);
			setRowDetails(res.data);
			//console.log("notification", res.data);
			setIsLoading(false);
		} catch (error) {
			console.log("error during data fetching", error);
			setIsLoading(false);
		}
	};

	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Notification
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : rowDetails.length > 0 ? (
				<Table
					tableStyle="border-spacing-y-[10px] border-separate -mt-4"
					bodyStyle="bg-accent box-shadow"
					body={rowDetails.map((row) => [
						row.id,
						row.notification_type,
						row.message,
						row.date_created,
					])}
					bodyTdStyle="table-body-td intro-x"
					headTdStyle="table-body-td intro-x"
				/>
			) : (
				<h3 className="text-center w-full flex flex-col justify-center items-center intro-x my-4">
					<img className="w-72 w- text-center" src={NoDataImg} />
					<p className="font-bold">No Notification</p>
				</h3>
			)}
		</div>
	);
};

export default Notification;
