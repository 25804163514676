import React, { Children } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { AnimatePresence } from "framer-motion";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout = ({ children, sidebar }) => {
	const { activeItem } = useStateContext();
	return (
		<div className="flex relative">
			{activeItem ? (
				<AnimatePresence>
					<div className="bg-accent fixed w-72 shadow-lg z-[100] transition-all ease-in-out">
						{sidebar}
					</div>
				</AnimatePresence>
			) : (
				<div className="bg-accent w-0">{sidebar}</div>
			)}
			<div
				className={`bg-primary w-full min-h-screen ${
					activeItem ? "md:ml-72" : "flex-2"
				}`}
			>
				<div className="bg-primary z-[90] fixed w-full md:static">
					<Navbar />
				</div>
				<div className="bg-primary mt-20 md:mt-12">{children}</div>
			</div>
		</div>
	);
};

export default Layout;
