import React, { useContext } from "react";
import Layout from "../components/Layout";
import { Sidebar } from "../components";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import {
	MyDetailsItems,
	MyDetailsItemsForAdmin,
	MyDetailsItemsForHR,
	MyDetailsItemsForCEO,
} from "../data";
import {
	PrimarySkills,
	SecondarySkills,
	UserDetails,
	UserSkillSet,
	EmployeeSkills,
} from "../sub-pages/MyDetails";

const MydetailsPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<>
			{user.user_role === "EMPLOYEE" ||
			user.user_role === "INTERN" ||
			user.user_role === "TRAINEE" ||
			user.user_role === "MANAGER" ||
			user.user_role === "CONSULTANT" ? (
				<Layout sidebar={<Sidebar sidebarLinks={MyDetailsItems} />}>
					<Route path="/mydetails/user-profile" component={UserDetails} />
					<Route path="/mydetails/user-skill-set" component={UserSkillSet} />
				</Layout>
			) : null}

			{user.user_role === "ADMIN" ? (
				<Layout sidebar={<Sidebar sidebarLinks={MyDetailsItemsForAdmin} />}>
					<Route path="/mydetails/user-profile" component={UserDetails} />
				</Layout>
			) : null}

			{user.user_role === "HR" ? (
				<Layout sidebar={<Sidebar sidebarLinks={MyDetailsItemsForHR} />}>
					<Route path="/mydetails/user-profile" component={UserDetails} />
					<Route path="/mydetails/user-skills" component={EmployeeSkills} />
					<Route
						path="/mydetails/user-primary-skills"
						component={PrimarySkills}
					/>
					<Route
						path="/mydetails/user-secondary-skills"
						component={SecondarySkills}
					/>
				</Layout>
			) : null}

			{user.user_role === "CEO" ? (
				<Layout sidebar={<Sidebar sidebarLinks={MyDetailsItemsForCEO} />}>
					<Route path="/mydetails/user-profile" component={UserDetails} />
					<Route
						path="/mydetails/user-primary-skills"
						component={PrimarySkills}
					/>
					<Route
						path="/mydetails/user-secondary-skills"
						component={SecondarySkills}
					/>
				</Layout>
			) : null}
		</>
	);
};

export default MydetailsPage;
