import React from "react";

const LmsCards = ({ number, title, icons, color, button }) => {
	return (
		<div
			className={`w-full intro-x text-white box-shadow flex items-center rounded-2xl ${color}`}
		>
			<div className="w-full">
				<div className="md:py-3 w-full py-3 flex items-center gap-4 px-1">
					<div className="text-5xl">{icons}</div>
					<div>
						<div className="float float-right">{button}</div>
						<h5 className="text-lg font-semibold ">{number}</h5>
						<h5 className="text-xs">{title}</h5>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LmsCards;
