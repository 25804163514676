import React, { useState, useEffect, useContext } from "react";
import { eachDayOfInterval, format, parseISO } from "date-fns";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import LmsCards from "./LmsCards";
import { WFHWORKMODE, HOLIDAYCALENDARLEAVES } from "../Constants";
import { useStateContext } from "../contexts/ContextProvider";
import SweetAlert from "../utils/SweetAlert";
import WfhForm from "./WfhForm";

const WorkFromHome = () => {
	const { cardData } = useStateContext();
	const { authTokens } = useContext(AuthContext);
	const [wfhStartDate, setWfhStartDate] = useState("");
	const [wfhEndDate, setWfhEndDate] = useState("");
	const [wfhTotalLeave, setWfhTotalLeave] = useState("");
	const [wfhDescription, setWfhDescription] = useState("");
	const [holidayLeaves, setHolidayLeaves] = useState([]);
	const { toast, snackbar } = require("tailwind-toast");

	useEffect(() => {
		fetchHolidayLeave();
	}, []);

	const fetchHolidayLeave = async () => {
		try {
			const res = await ApiCall(
				HOLIDAYCALENDARLEAVES,
				"POST",
				authTokens,
				null
			);
			// console.log("HolidayLeaves", res.data.holiday);
			setHolidayLeaves(res.data.holiday);
		} catch (error) {
			console.log("Error , while fetching Holiday Leaves", error);
		}
	};

	const handleWfhStartDate = (event) => {
		event.preventDefault();
		setWfhStartDate(event.target.value);
	};
	const handleWfhEndDate = (event) => {
		event.preventDefault();
		const wfhSelectedDate = event.target.value;
		setWfhEndDate(wfhSelectedDate);
	};
	const handleWfhAreaValue = (event) => {
		event.preventDefault();
		setWfhDescription(event.target.value);
	};
	useEffect(() => {
		if (wfhStartDate && wfhEndDate) {
			const wfhCalDuration = wfhLeaveDuration(wfhEndDate);
			setWfhEndDate(wfhCalDuration);
		}
	}, [wfhStartDate, wfhEndDate]);

	const wfhLeaveDuration = (wfhSelectedDate) => {
		const start = parseISO(wfhStartDate);
		const end = parseISO(wfhSelectedDate);

		// console.log("start and end date", start, end);

		if (start > end || end < start) {
			setWfhTotalLeave(0);
			// console.log("going", compenTotalLeave);
			return format(end, "yyyy-MM-dd");
		}

		const wfhDates = eachDayOfInterval({
			start: start,
			end: end,
		});

		// console.log("compenDates", compenDates);

		const wfhLeaveDays = wfhDates
			.map((date) => format(date, "yyyy-MM-dd"))
			.filter((date) => {
				const wfhDay = new Date(date).getDay();
				return wfhDay !== 6 && wfhDay !== 0;
			});
		// console.log("compenLeaveDays", compenLeaveDays);

		const filteredDates = wfhLeaveDays.filter(
			(date) => !holidayLeaves.includes(date)
		);

		const wfhTotalLeaveDays = filteredDates.length;
		// console.log("wfhTotalLeaveDays", wfhTotalLeaveDays);
		let wfhCalculatedTotalLeave = wfhTotalLeaveDays;

		setWfhTotalLeave(wfhCalculatedTotalLeave);

		return format(end, "yyyy-MM-dd");
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		// console.log("API working");
		const params = {
			wfhStartDate,
			wfhEndDate,
			wfhDescription,
			wfhTotalLeave,
		};
		//console.log("params", params);

		try {
			const response = await ApiCall(WFHWORKMODE, "POST", authTokens, params);
			// console.log("Compensation Request", response.status);
			if (response.status === 200) {
				SweetAlert({ title: "Successfully Applied", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "failed" });
			}
			setWfhStartDate("");
			setWfhEndDate("");
			setWfhDescription("");
			setWfhTotalLeave("");
			// Update the bar chart data
			// setBarChartData(response);
			// console.log("barchart", response);
		} catch (error) {
			console.error("Error fetching data", error);
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 pb-6">
				{cardData &&
					cardData.map((item, index) => (
						<LmsCards
							key={index}
							number={item.number}
							title={item.title}
							icons={item.icons}
							color={item.color}
							// button={
							// 	index === 0 ? (
							// 		<Button classname="w-full bg-cardFourBg border-none px-2 rounded-sm text-xs">
							// 			view
							// 		</Button>
							// 	) : null
							// }
						/>
					))}
			</div>
			{/* <h3 className="pb-4 text-xl intro-y">Compensation Request</h3> */}
			<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
				<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
					<h4 className="mx-6 text-center table-header-text font-bold text-lg">
						Work From Home Request
					</h4>
				</div>
				<WfhForm
					wfhStartDate={wfhStartDate}
					wfhEndDate={wfhEndDate}
					handleSubmit={handleSubmit}
					handleWfhStartDate={handleWfhStartDate}
					handleWfhEndDate={handleWfhEndDate}
					handleWfhAreaValue={handleWfhAreaValue}
					wfhDescription={wfhDescription}
					wfhTotalLeave={wfhTotalLeave}
				/>
			</div>
		</div>
	);
};

export default WorkFromHome;
