import React, { useState, useEffect, useContext } from "react";
import {
	Table,
	Loader,
	Dropdown,
	InputBox,
	Popup,
	BarCharts,
} from "../../components";
import AuthContext from "../../contexts/AuthContext";
import ApiCall from "../../utils/ApiCall";
import { PRIMARYSKILLSADMIN } from "../../Constants";
import {
	dropDownPagination,
	primarySkillsTableHeader,
	primarySkillsPopupTableHeader,
} from "../../data";

const PrimarySkills = () => {
	const { authTokens, user } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [xaxis, setXaxis] = useState([]);
	const [yaxis, setYaxis] = useState([]);
	const [primarySkills, setPrimarySkills] = useState([]);
	const [popupData, setPopupData] = useState(null);
	const [pagination, setPagination] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem(
			"empprimaryskills-pagination-data"
		);
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const response = await ApiCall(
				`${PRIMARYSKILLSADMIN}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			setIsLoading(false);
			setXaxis(response.data.x_axis);
			setYaxis(response.data.y_axis);
			setPrimarySkills(response.data.skill_set);
			//console.log("response: ", response.data);
		} catch (error) {
			console.log("Error while getting data", error);
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		//console.log("current", currentPage);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"empprimaryskills-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handlePopupTd = (rowIndex, cellIndex) => {
		//console.log("cell index", cellIndex);
		if (cellIndex === 1) {
			const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
			const currentData = primarySkills[actualIndex];
			//console.log("currentData", currentData);
			setPopupData(currentData);
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			{isLoading ? (
				<div className="flex justify-center items-center w-full h-[60vh]">
					<Loader />
				</div>
			) : (
				<>
					{user && user.user_role === "CONSULTANT" ? (
						<h2 className="text-lg intro-x table-body-td px-2 font-semibold ">
							Consultant Skills
						</h2>
					) : (
						<h2 className="text-lg intro-x table-body-td px-2 font-semibold ">
							Employee Skills
						</h2>
					)}

					<div className="w-full mt-6 flex justify-center items-center intro-x">
						{xaxis && yaxis ? (
							<BarCharts
								yaxisvalue={yaxis}
								xaxisvalue={xaxis}
								xAxisLabel="Skills"
								yAxisLabel="No of Users"
							/>
						) : null}
					</div>
					<div className="w-full flex justify-end items-end px-2 md:px-4 mt-4 intro-x">
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					{primarySkills && (
						<div className="w-full flex justify-end items-end px-2 md:px-4 mt-4">
							<Table
								tableStyle="border-spacing-y-[10px] border-separate -mt-4 intro-x"
								headStyle="text-center"
								bodyStyle="bg-accent box-shadow text-center"
								header={primarySkillsTableHeader}
								body={primarySkills.map((row) => [row.skill, row.count])}
								tdonClick={(rowIndex, cellIndex) => {
									handlePopupTd(rowIndex, cellIndex);
								}}
								paginationNeed={true}
								currentPage={currentPage}
								rowPerPage={rowPerPage}
								paginationBody={primarySkills}
								onPageChange={handlePageChange}
							/>
						</div>
					)}
					{popupData && (
						<Popup
							title={popupData.skill}
							trigger={popupData}
							setTrigger={setPopupData}
							popupstyle="md:w-96 w-full"
						>
							<Table
								headTdStyle="bg-primary"
								headStyle="text-center"
								bodyStyle="text-center"
								header={primarySkillsPopupTableHeader}
								body={
									popupData && popupData.names.map((row, index) => [index, row])
								}
							/>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default PrimarySkills;
