import React, { useState } from "react";
import ModfifyUserForm from "./ModfifyUserForm";
import InputBox from "./InputBox";
import InputFile from "./InputFile";

const ModifyUserDetails = ({ data, profilePic }) => {
	// console.log("dataaaa", data);
	let initialCheckedValue = false;
	if (data && data.user && data.user.same_as_per_addr !== null) {
		initialCheckedValue = data.user.same_as_per_addr;
	}
	const [isChecked, setIsChecked] = useState(initialCheckedValue);

	// console.log("data", data.user);
	return (
		<ModfifyUserForm
			styleOne="p-4"
			labelOne="Asset"
			itemOne={
				<InputBox
					type="text"
					name=""
					value={data.user ? data.user.asset : ""}
					disabled={true}
					style="w-full"
				/>
			}
			labelTwo="Laptop Brand"
			itemTwo={
				<InputBox
					value={data.user ? data.user.computer_brand : ""}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelThree="Laptop Processor"
			itemThree={
				<InputBox
					value={data.user ? data.user.computer_processor : ""}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelFour="Laptop RAM"
			itemFour={
				<InputBox
					value={data.user ? data.user.computer_ram : ""}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelFive="Personal Email"
			itemFive={
				<InputBox
					value={
						data.user
							? data.user.personal_email === null
								? "None"
								: data.user.personal_email
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			headingTwo="Present Address"
			styleTwo="pb-4"
			labelSeven="Address Line1"
			itemSeven={
				<InputBox
					value={
						data.user
							? data.user.tem_address1 === null
								? "None"
								: data.user.tem_address1
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelEight="Address Line2"
			itemEight={
				<InputBox
					value={
						data.user
							? data.user.tem_address2 === null
								? "None"
								: data.user.tem_address2
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelNine="City"
			itemNine={
				<InputBox
					value={
						data.user
							? data.user.tem_city === null
								? "None"
								: data.user.tem_city
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelTen="State"
			itemTen={
				<InputBox
					value={
						data.user
							? data.user.tem_state === null
								? "None"
								: data.user.tem_state
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelEleven="Country"
			itemEleven={
				<InputBox
					value={
						data.user
							? data.user.tem_country === null
								? "None"
								: data.user.tem_country
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelTwelve="Postal Code"
			itemTwelve={
				<InputBox
					value={
						data.user
							? data.user.tem_pincode === null
								? "None"
								: data.user.tem_pincode
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			ltwoExtraOne="Phone No"
			itwoExtraOne={
				<InputBox
					value={
						data.user
							? data.user.tem_phone_number === null
								? "None"
								: data.user.tem_phone_number
							: ""
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			styleThree="pb-4"
			headingThree="Permenant Address"
			labelThirteen="Address Line1"
			itemThirteen={
				<InputBox
					value={
						isChecked === true ? data.user.tem_address1 : data.user.per_address1
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelFourteen="Address Line2"
			itemFourteen={
				<InputBox
					value={
						isChecked === true ? data.user.tem_address2 : data.user.per_address2
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelFifteen="City"
			itemFifteen={
				<InputBox
					value={isChecked === true ? data.user.tem_city : data.user.per_city}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelSixteen="State"
			itemSixteen={
				<InputBox
					value={isChecked === true ? data.user.tem_state : data.user.per_state}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelSeventeen="Country"
			itemSeventeen={
				<InputBox
					value={
						isChecked === true ? data.user.tem_country : data.user.per_country
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			ltthreeExtraOne="Postal Code"
			ithreeExtraOne={
				<InputBox
					value={
						isChecked === true ? data.user.tem_pincode : data.user.per_pincode
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			ltthreeExtraTwo="Phone No"
			ithreeExtraTwo={
				<InputBox
					value={
						isChecked === true
							? data.user.tem_phone_number
							: data.user.per_phone_number
					}
					type="text"
					name=""
					disabled={true}
					style="w-full"
				/>
			}
			labelEighteen="USER PROFILE"
			itemEighteen={<InputFile userImg={profilePic} disabled={true} />}
			checkBox={true}
			checkBoxValue={isChecked}
			disabled={true}
		/>
	);
};

export default ModifyUserDetails;
