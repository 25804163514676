import React, { useContext } from "react";
import Layout from "../components/Layout";
import { MyTeam, Sidebar } from "../components";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import { MyTeamItems } from "../data";

const MyteamPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<Layout sidebar={<Sidebar sidebarLinks={MyTeamItems} />}>
			<Route path="/myteam/user-team" component={MyTeam} />
		</Layout>
	);
};

export default MyteamPage;
