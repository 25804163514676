import React, { useState, useEffect, useContext } from "react";
import LmsCompensationForm from "./LmsCompensationForm";
import { eachDayOfInterval, format, parseISO } from "date-fns";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import LmsCards from "./LmsCards";
import { COMPENSATION, HOLIDAYCALENDARLEAVES } from "../Constants";
import { useStateContext } from "../contexts/ContextProvider";
import SweetAlert from "../utils/SweetAlert";

const CompensationRequest = () => {
	const { cardData } = useStateContext();
	const { authTokens } = useContext(AuthContext);
	const [compenStartDate, setCompenStartDate] = useState("");
	const [compenEndDate, setCompenEndDate] = useState("");
	const [compenSelectedOptionType, setCompenSelectedOptionType] =
		useState("half");
	const [compenTotalLeave, setCompenTotalLeave] = useState("");
	const [compenDescription, setCompenDescription] = useState("");
	const [holidayLeaves, setHolidayLeaves] = useState([]);
	const { toast, snackbar } = require("tailwind-toast");

	const optionsTwo = [
		{ label: "Half Day", value: "half" },
		{ label: "Full Day", value: "full" },
	];

	useEffect(() => {
		fetchHolidayLeave();
	}, []);

	const fetchHolidayLeave = async () => {
		try {
			const res = await ApiCall(
				HOLIDAYCALENDARLEAVES,
				"POST",
				authTokens,
				null
			);
			// console.log("HolidayLeaves", res.data.holiday);
			setHolidayLeaves(res.data.holiday);
		} catch (error) {
			console.log("Error , while fetching Holiday Leaves", error);
		}
	};

	const handleCompenStartDate = (event) => {
		event.preventDefault();
		setCompenStartDate(event.target.value);
	};
	const handleCompenEndDate = (event) => {
		event.preventDefault();
		const compenSelectedDate = event.target.value;
		setCompenEndDate(compenSelectedDate);
	};
	const handleCompenType = (event) => {
		event.preventDefault();
		setCompenSelectedOptionType(event.target.value);
	};
	const handleCompenAreaValue = (event) => {
		event.preventDefault();
		setCompenDescription(event.target.value);
	};
	useEffect(() => {
		if (compenStartDate && compenEndDate) {
			const compenCalDuration = compenLeaveDuration(compenEndDate);
			setCompenEndDate(compenCalDuration);
		}
	}, [compenStartDate, compenEndDate, compenSelectedOptionType]);

	const compenLeaveDuration = (compenSelectedDate) => {
		const start = parseISO(compenStartDate);
		const end = parseISO(compenSelectedDate);

		// console.log("start and end date", start, end);

		if (start > end || end < start) {
			setCompenTotalLeave(0);
			// console.log("going", compenTotalLeave);
			return format(end, "yyyy-MM-dd");
		}

		const compenDates = eachDayOfInterval({
			start: start,
			end: end,
		});

		// console.log("compenDates", compenDates);

		const compenLeaveDays = compenDates
			.map((date) => format(date, "yyyy-MM-dd"))
			.filter((date) => {
				const compenDay = new Date(date).getDay();
				return compenDay !== 6 && compenDay !== 0;
			});
		// console.log("compenLeaveDays", compenLeaveDays);

		const filteredDates = compenLeaveDays.filter(
			(date) => !holidayLeaves.includes(date)
		);

		const compenTotalLeaveDays = filteredDates.length;
		// console.log("compenTotalLeaveDays", compenTotalLeaveDays);
		let compenCalculatedTotalLeave = compenTotalLeaveDays;

		if (compenSelectedOptionType == "half") {
			compenCalculatedTotalLeave = compenTotalLeaveDays / 2;
		}
		setCompenTotalLeave(compenCalculatedTotalLeave);

		return format(end, "yyyy-MM-dd");
	};

	// const handleSubmit = (event) => {
	// 	event.preventDefault();
	// 	const compenLeaveRequestValues = {
	// 		compenSelectedOptionType,
	// 		compenStartDate,
	// 		compenEndDate,
	// 		compenDescription,
	// 		compenTotalLeave,
	// 	};
	// 	console.log("Form Submitted", compenLeaveRequestValues);
	// };

	const handleSubmit = async (event) => {
		event.preventDefault();
		// console.log("API working");
		const params = {
			compenSelectedOptionType,
			compenStartDate,
			compenEndDate,
			compenDescription,
			compenTotalLeave,
		};

		try {
			const response = await ApiCall(COMPENSATION, "POST", authTokens, params);
			// console.log("Compensation Request", response.status);
			if (response.status === 200) {
				SweetAlert({ title: "Successfully Applied", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "failed" });
			}
			setCompenStartDate("");
			setCompenEndDate("");
			setCompenDescription("");
			setCompenTotalLeave("");
			// Update the bar chart data
			// setBarChartData(response);
			// console.log("barchart", response);
		} catch (error) {
			console.error("Error fetching data", error);
		}
	};

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 pb-6">
				{cardData &&
					cardData.map((item, index) => (
						<LmsCards
							key={index}
							number={item.number}
							title={item.title}
							icons={item.icons}
							color={item.color}
							// button={
							// 	index === 0 ? (
							// 		<Button classname="w-full bg-cardFourBg border-none px-2 rounded-sm text-xs">
							// 			view
							// 		</Button>
							// 	) : null
							// }
						/>
					))}
			</div>
			{/* <h3 className="pb-4 text-xl intro-y">Compensation Request</h3> */}
			<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
				<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
					<h4 className="mx-6 text-center table-header-text font-bold text-lg">
						Compensation Request
					</h4>
				</div>
				<LmsCompensationForm
					compenSelectedOptionType={compenSelectedOptionType}
					compenStartDate={compenStartDate}
					compenEndDate={compenEndDate}
					handleSubmit={handleSubmit}
					handleCompenStartDate={handleCompenStartDate}
					handleCompenEndDate={handleCompenEndDate}
					optionsTwo={optionsTwo}
					handleCompenAreaValue={handleCompenAreaValue}
					compenDescription={compenDescription}
					compenTotalLeave={compenTotalLeave}
					handleCompenType={handleCompenType}
				/>
			</div>
		</div>
	);
};

export default CompensationRequest;
