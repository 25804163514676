import React, { useState, useContext, useEffect } from "react";
import Dropdown from "./Dropdown";
import Popup from "./Popup";
import Table from "./Table";
import ProfileLabel from "./ProfileLabel";
import InputBox from "./InputBox";
import DateBox from "./DateBox";
import NumberBox from "./NumberBox";
import Textarea from "./Textarea";
import Button from "./Button";
import {
	timesheetTableHead,
	timesheetTableBody,
	TimesheetWorkModel,
} from "../data";
import { TIMESHEET } from "../Constants";
import { TIMESHEETEDIT } from "../Constants";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import SweetAlert from "../utils/SweetAlert";
import Loader from "./Loader";

const Timesheet = () => {
	const { authTokens, user } = useContext(AuthContext);
	const [currentDate] = useState(getFormattedDate());
	const [loading, setLoading] = useState(true);
	const [btnLoader, setBtnLoader] = useState(false);
	const [btnLoaderEdit, setBtnLoaderEdit] = useState(false);
	const [selectedProject, setSelectedProject] = useState("");
	const [selectedManager, setSelectedManager] = useState("");
	const [selectedWorkModel, setSelectedWorkModel] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedHours, setSelectedHours] = useState("");
	const [selectedStartTime, setSelectedStartTime] = useState("");
	const [selectedEndTime, setSelectedEndTime] = useState("");
	//const [formData, setFormData] = useState([]);
	const [ButtonPopup, setButtonPopup] = useState(false);
	const [editPopup, setEditPopup] = useState(null);
	const [timesheetDetails, setTimesheetDetails] = useState([]);
	const [timesheetProjects, setTimesheetProjects] = useState([]);
	const [dropDownValue, setDropdownValue] = useState(null);
	const [textName, setTextName] = useState("");
	const [editFormData, setEditFormData] = useState([]);
	const [selectedId, setSelectedId] = useState(" ");
	const [nonProductiveHours, setNonProductiveHours] = useState(0);

	useEffect(() => {
		fetchData();
		setTimesheetProjects([]);
	}, []);

	const fetchData = async () => {
		try {
			const data = await ApiCall(TIMESHEET, "GET", authTokens, null);
			// console.log("hhfsdhfsjfkkds", data.data);
			setTimesheetDetails(data.data.timesheet_data);
			// console.log("Details", timesheetDetails);
			setTimesheetProjects(data.data.projects);
			setSelectedId(data.id);
			// console.log("timeid",data.id)
			// console.log("data",data.data.projects,data.data.timesheet_data,data.id);
			setLoading(false);
		} catch (error) {
			console.log("Error fetching data", error);
			setLoading(false);
		}
	};

	function getFormattedDate() {
		const date = new Date();
		const year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();

		month = month < 10 ? `0${month}` : month;
		day = day < 10 ? `0${day}` : day;

		return `${year}-${month}-${day}`;
	}

	const handleStartTimeChange = (event) => {
		const time = event.target.value;
		setSelectedStartTime(time);
	};

	const handleEndTimeChange = (event) => {
		const time = event.target.value;
		setSelectedEndTime(time);
	};

	const addTimeSheet = () => {
		setButtonPopup(true);
	};

	const handleSave = async (event) => {
		event.preventDefault();
		setBtnLoader(true);

		// if (
		// 	!selectedProject ||
		// 	!selectedStartTime ||
		// 	!selectedEndTime ||
		// 	!selectedHours ||
		// 	!selectedStatus
		// ) {
		// 	//  console.log("error",selectedManager)
		// 	SweetAlert({
		// 		title: "Please fill all required fields before saving.",
		// 		icon: "error",
		// 	});
		// 	return;
		// }

		const params = {
			project_name: selectedProject,
			project_manager:
				selectedProject === "nonproductive" ? "None" : selectedManager,
			work_mode: selectedWorkModel,
			date: currentDate,
			start_time: selectedStartTime,
			end_time: selectedEndTime,
			logged_hours: selectedHours,
			status: selectedStatus,
			// other: selectedProject === "other" ? textName : null,
			non_productive_hrs: nonProductiveHours,
		};

		// console.log("timesheet sfs", params);
		//let res = { status: -1 };
		try {
			const res = await ApiCall(TIMESHEET, "POST", authTokens, params);
			//console.log(res.data.message);
			// console.log("get methods sfs", res);
			await fetchData();
			if (res.status === 200) {
				SweetAlert({ title: res.data.message, icon: "success" });
			} else if (res.status === 409) {
				SweetAlert({
					title: res.data.message,
					icon: "error",
				});
			} else {
				SweetAlert({ title: "Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("Error fetching data", error);
		}
		setBtnLoader(false);
		setButtonPopup(false);
		setSelectedProject("");
		//setTextName("");
		fetchData();
	};

	const handleSaveEdit = async (event) => {
		event.preventDefault();
		setBtnLoaderEdit(true);
		const params = {
			id: selectedId,
			project_name: selectedProject,
			project_manager:
				selectedProject === "nonproductive" ? "None" : selectedManager,
			work_mode: selectedWorkModel,
			date: selectedDate,
			start_time: selectedStartTime,
			end_time: selectedEndTime,
			logged_hours: selectedHours,
			status: selectedStatus,
			// other: textName,
			non_productive_hrs: nonProductiveHours,
		};

		// console.log("non productive", nonProductiveHours);
		// setTimesheetFilled(true);

		try {
			const res = await ApiCall(TIMESHEETEDIT, "POST", authTokens, params);
			setButtonPopup(false);
			if (res.status === 200) {
				SweetAlert({ title: "Edited timesheet added", icon: "success" });
			} else {
				SweetAlert({ title: "Something went wrong", icon: "error" });
			}
			//console.log(res.data);
			// console.log("EditTimesheet", res);
			fetchData();
		} catch (error) {
			console.log("Error fetching data", error);
		}
		setBtnLoaderEdit(false);
		// setSelectedProject("");
		// setSelectedManager("");
		//setTextName("");
		setEditPopup(false);
	};

	const timesheetEdit = (rowIndex) => {
		const clickedRow = timesheetDetails[rowIndex];
		//console.log("edit popup data", clickedRow);
		const id = clickedRow.id;
		const statusData = clickedRow.status;

		setEditFormData(clickedRow);
		// console.log("time", id);
		// console.log("object", clickedRow);
		setSelectedProject(clickedRow.project);
		setSelectedManager(clickedRow.project_manager);
		setSelectedWorkModel(clickedRow.work_mode);
		setSelectedDate(clickedRow.date);
		setSelectedStartTime(clickedRow.start_time);
		setSelectedEndTime(clickedRow.end_time);
		setSelectedHours(clickedRow.logged_hours);
		setSelectedStatus(statusData);
		setSelectedId(id);
		// console.log(params, "dhgdhsdjsjhdjsdh");
		setEditPopup(clickedRow);
	};

	useEffect(() => {
		setSelectedProject("");
		setSelectedManager("");
		// setSelectedDate('');
		setSelectedWorkModel("");
		setTextName("");
		setSelectedStartTime("");
		setSelectedEndTime("");
		setSelectedHours("");
		setSelectedStatus("");
		setNonProductiveHours("");
	}, [ButtonPopup]);

	const handleNonProductiveChange = (e) => {
		setNonProductiveHours(e.target.value);
		// console.log("non productive",e.target.value)
	};
	const handleOtherChange = (e) => {
		setSelectedProject("Others");
		setSelectedManager(e.target.value);
		setTextName(e.target.value);
	};

	const handleChangeWork = (e) => {
		setSelectedWorkModel(e.target.value);
	};

	const handleChange = (e) => {
		const selectedValue = e.target.value;

		// console.log("seleteced",selectedValue);

		setSelectedProject(selectedValue);
		setDropdownValue(selectedValue);

		if (selectedValue === "nonproductive" || selectedValue === "Others") {
			setTextName("");
			setSelectedManager("");
		} else {
			// const select = parseInt(selectedValue);
			const selectedProject = timesheetProjects.find(
				(item) => item.label === selectedValue
			);

			// console.log("seletedProject",selectedProject);

			if (selectedProject) {
				setTextName(selectedProject.project_manager_name);
				// console.log("project_manager", selectedProject);
				setSelectedManager(selectedProject.project_manager_name);
			} else {
				setTextName("");
				setSelectedManager("");
			}
		}
	};

	const handleBtnColor = (rowIndex) => {
		const colorRow = timesheetDetails[rowIndex];
		const access = colorRow.access;
		if (access === true) {
			return "s";
		} else {
			return "";
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<div className="flex flex-wrap flex-col lg:flex-nowrap justify-center p-2">
				{ButtonPopup && (
					<Popup
						popupstyle="md:w-2/5 w-full"
						trigger={ButtonPopup}
						title="Add Timesheet"
						setTrigger={setButtonPopup}
					>
						<form
							className="mt-2 rounded px-4 pt-2 pb-2 mb-2"
							onSubmit={handleSave}
						>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Project" />
								<Dropdown
									style="w-full"
									options={[
										{ value: "", label: "Select" },
										...(timesheetProjects
											? timesheetProjects.map((row) => ({
													value: row.label,
													label: row.label,
											  }))
											: []),
										{ value: "Others", label: "Others" },
										{ value: "nonproductive", label: "Non Productive" },
									]}
									onChange={handleChange}
									value={selectedProject}
									required={true}
								/>

								{selectedProject === "nonproductive" && (
									<div className="mb-4 mt-3 text-sm">
										<ProfileLabel label="Non Productive" />
										<NumberBox
											style="w-full"
											type="number"
											value={nonProductiveHours}
											onChange={handleNonProductiveChange}
											required={true}
										/>
									</div>
								)}

								{selectedProject === "Others" && (
									<div className="mb-4 mt-3 text-sm">
										<ProfileLabel label="Manager" />
										<InputBox
											style="w-full"
											value={textName}
											onChange={handleOtherChange}
											required={true}
										/>
									</div>
								)}

								{selectedProject !== "nonproductive" &&
									selectedProject !== "Others" && (
										<div className="mb-4 mt-3">
											<ProfileLabel label="Manager" />
											<InputBox style="w-full" value={textName} on readOnly />
										</div>
									)}
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Work Mode" />
								<Dropdown
									style="w-full"
									options={TimesheetWorkModel}
									onChange={handleChangeWork}
									value={selectedWorkModel}
									required={true}
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Date" />
								<DateBox
									style="w-full"
									placeholder="Date"
									value={currentDate}
									disabled={true}
									type="date"
								/>
							</div>
							<div className="mb-4 text-sm ">
								<ProfileLabel label="Start Time" />
								<input
									type="time"
									className="shadow bg-transparent appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline timefield"
									value={selectedStartTime}
									onChange={handleStartTimeChange}
									required
								/>
							</div>

							<div className="mb-4 text-sm">
								<ProfileLabel label="End Time" />
								<input
									type="time"
									className="shadow bg-transparent appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline timefield"
									value={selectedEndTime}
									onChange={handleEndTimeChange}
									required
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Logged Hours" />
								<NumberBox
									style="w-full"
									type="number"
									onChange={(e) => setSelectedHours(e.target.value)}
									value={selectedHours}
									required={true}
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Status" />
								<Textarea
									style="w-full h-24"
									onChange={(e) => setSelectedStatus(e.target.value)}
									value={selectedStatus}
									required={true}
								/>
							</div>
							<div className="flex justify-center">
								{btnLoader ? (
									<Loader />
								) : (
									<Button classname="save-button py-2 px-4 rounded">
										Save
									</Button>
								)}
							</div>
						</form>
					</Popup>
				)}

				{editPopup && (
					<Popup
						trigger={editPopup}
						title="Edit Timesheet"
						setTrigger={setEditPopup}
						popupstyle="md:w-2/5 w-full"
					>
						<form
							className="mt-2 rounded px-4 pt-2 pb-2 mb-2"
							onSubmit={handleSaveEdit}
						>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Project" />
								<Dropdown
									style="w-full"
									options={[
										{ value: "", label: "Select" },
										...(timesheetProjects
											? timesheetProjects.map((row) => ({
													value: row.label,
													label: row.label,
											  }))
											: []),
										{ value: "Others", label: "Others" },
										{ value: "nonproductive", label: "Non Productive" },
									]}
									onChange={handleChange}
									value={selectedProject}
									required={true}
								/>

								{selectedProject === "nonproductive" && (
									<div className="mb-4 mt-3 text-sm">
										<ProfileLabel label="Non Productive" />
										<NumberBox
											style="w-full"
											type="number"
											value={nonProductiveHours}
											onChange={handleNonProductiveChange}
											required={true}
										/>
									</div>
								)}

								{selectedProject === "Others" && (
									<div className="mb-4 mt-3 text-sm">
										<ProfileLabel label="Manager" />
										<InputBox
											style="w-full"
											value={textName}
											onChange={handleOtherChange}
											required={true}
										/>
									</div>
								)}

								{selectedProject !== "nonproductive" &&
									selectedProject !== "Others" && (
										<div className="mb-4 mt-3 text-sm">
											<ProfileLabel label="Manager" />
											<InputBox
												style="w-full"
												value={selectedManager}
												readOnly={true}
											/>
										</div>
									)}
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Work Mode" />
								<Dropdown
									style="w-full"
									options={TimesheetWorkModel}
									onChange={handleChangeWork}
									value={selectedWorkModel}
									required={true}
								/>
							</div>

							<div className="mb-4 text-sm">
								<ProfileLabel label="Date" />
								<DateBox
									style="w-full"
									placeholder="Date"
									value={selectedDate}
									disabled={true}
									type="date"
									required={true}
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Start Time" />
								<input
									type="time"
									className="shadow bg-transparent appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline timefield"
									value={selectedStartTime}
									onChange={handleStartTimeChange}
									required
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="End Time" />
								<input
									type="time"
									className="shadow bg-transparent appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline timefield"
									value={selectedEndTime}
									onChange={handleEndTimeChange}
									required
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Logged Hours" />
								<NumberBox
									style="w-full"
									type="number"
									onChange={(e) => setSelectedHours(e.target.value)}
									value={selectedHours}
									required={true}
								/>
							</div>
							<div className="mb-4 text-sm">
								<ProfileLabel label="Status" />
								<Textarea
									style="w-full h-24"
									onChange={(e) => setSelectedStatus(e.target.value)}
									value={selectedStatus}
									required={true}
								/>
							</div>

							<div className="flex justify-center">
								{btnLoaderEdit ? (
									<Loader />
								) : (
									<Button classname="save-button py-2 px-4 rounded bg-blue-700">
										Save
									</Button>
								)}
							</div>
						</form>
					</Popup>
				)}

				<div className="flex justify-end mr-5">
					<Button
						onClick={addTimeSheet}
						classname="bg-button text-white font-semibold px-3 py-2 rounded-md"
					>
						AddTimesheet
					</Button>
				</div>
				{/* {console.log("timesheetDetails", timesheetDetails)} */}
				{loading ? (
					<div className="w-full h-[70vh] flex items-center justify-center">
						<Loader />
					</div>
				) : user.user_role === "EMPLOYEE" ||
				  user.user_role === "INTERN" ||
				  user.user_role === "MANAGER" ||
				  user.user_role === "TRAINEE" ||
				  user.user_role === "CONSULTANT" ? (
					timesheetDetails && timesheetDetails.length > 0 ? (
						<>
							<Table
								tableStyle="border-spacing-y-[10px] border-separate -mt-4"
								bodyStyle="bg-accent box-shadow"
								header={timesheetTableHead}
								body={timesheetDetails.map((row) => [
									row.project,
									row.project_manager,
									row.date,
									row.start_time,
									row.end_time,
									row.logged_hours,
									row.project === "Non Productive" ? row.non_productive_hrs : 0,
									<span className="truncate max-w-[150px] inline-block">
										{row.status}
									</span>,
								])}
								bodyTdStyle="table-body-td intro-x"
								headTdStyle="table-body-td intro-x"
								btnTitle="Edit"
								buttonStyleOne={handleBtnColor}
								buttonStyleOneData={true}
								btnstyle="intro-x"
								onClick={(rowIndex) => timesheetEdit(rowIndex)}
							/>
						</>
					) : (
						<h4 className="text-center p-4 font-semibold">No Data</h4>
					)
				) : null}
			</div>
		</div>
	);
};

export default Timesheet;
