import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Dropdown,
	FormRow,
	InputBox,
	Loader,
	Popup,
	Table,
} from "../../components";
import {
	PayslipFormBodyData,
	PayslipFormHeadData,
	dropDownPagination,
} from "../../data";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { FORMSIXTEENUPDATE } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";

const FormSixteenUpdation = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [formUpdateData, setFormUpdateData] = useState([]);
	const [searchData, setSearchData] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [updatePopup, setUpdatePopup] = useState(null);
	const [selectedYear, setSelectedYear] = useState("");
	const [selectedPdf, setSelectedPdf] = useState(null);
	const [pagination, setPagination] = useState(10);
	const rowPerPage = 5;
	const year = new Date().getFullYear();
	const lastYear = year - 1;
	const twoYearsAgo = lastYear - 1;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem(
			"formsixteen-pagination-data"
		);
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${FORMSIXTEENUPDATE}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			//console.log("res", res.data);
			setFormUpdateData(res.data);
			setIsLoading(false);
		} catch (error) {
			console.log("Error While Fetching Data", error);
			setIsLoading(false);
		}
	};

	const yearFormSixteen = [
		{ value: "", label: "Select" },
		{ value: lastYear, label: lastYear },
		{ value: twoYearsAgo, label: twoYearsAgo },
	];

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"formsixteen-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handleYear = (e) => {
		e.preventDefault();
		setSelectedYear(e.target.value);
	};

	const handlePdf = (event) => {
		const file = event.target.files[0];
		setSelectedPdf(file);
	};

	const handleSearch = (e) => {
		setSearchData(e.target.value);
	};

	const searchedData = formUpdateData.filter((item) =>
		item.email.toLowerCase().includes(searchData.toLowerCase())
	);

	const handleFormUpdate = (rowIndex) => {
		if (searchedData.length > 7) {
			const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
			const id = searchedData[actualIndex].id;
			setUpdatePopup(id);
			//console.log("if UpdatePopup", updatePopup);
		} else {
			const correctIndex = formUpdateData.findIndex(
				(item) => item.id === searchedData[rowIndex].id
			);
			const id = formUpdateData[correctIndex].id;
			setUpdatePopup(id);
			//console.log("else UpdatePopup", updatePopup);
		}
		//setUpdatePopup(id);
	};

	const handleFormSixteenData = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("id", updatePopup);
		formData.append("year", selectedYear);
		formData.append("form16", selectedPdf);

		// console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }

		fetch("http://127.0.0.1:8000/api/payroll/form16_update/", {
			method: "POST",
			headers: {
				Authorization: "Bearer " + String(authTokens.access),
			},
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				//console.log("data", data);
				SweetAlert({ title: data, icon: "success" });

				setUpdatePopup(null);
				setSelectedPdf("");
				setSelectedYear("");
			})
			.catch((error) => {
				console.error("Error:", error);
				SweetAlert({ title: error, icon: "error" });
			});
	};

	// console.log("searchedData", searchedData.length);
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Form 16 Updation
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<div className="p-2 flex justify-between w-full mt-4 intro-x">
						<InputBox
							value={searchData}
							onChange={handleSearch}
							placeholder="Search"
							type="search"
						/>
						<Dropdown
							style="px-4"
							value={pagination}
							onChange={paginatedData}
							options={dropDownPagination}
						/>
					</div>
					<Table
						tableStyle="border-spacing-y-[10px] border-separate -mt-4"
						bodyStyle="bg-accent box-shadow"
						header={PayslipFormHeadData}
						body={searchedData.map((row) => [
							row.email,
							row.emp === "" ? "None" : row.emp,
							row.designation === "" ? "None" : row.designation,
							row.ctc,
						])}
						btnstyle="intro-x"
						bodyTdStyle="table-body-td intro-x"
						headTdStyle="table-body-td intro-x"
						btnTitle="Update"
						paginationNeed={searchedData.length > 7 ? true : false}
						currentPage={currentPage}
						rowPerPage={rowPerPage}
						paginationBody={formUpdateData}
						onPageChange={handlePageChange}
						onClick={handleFormUpdate}
					/>
					{updatePopup && (
						<Popup
							title="Upload Form 16"
							trigger={updatePopup}
							setTrigger={setUpdatePopup}
							popupstyle="md:w-2/5 w-full"
						>
							<form onSubmit={handleFormSixteenData}>
								<FormRow
									label="Year"
									item={
										<Dropdown
											value={selectedYear}
											onChange={handleYear}
											style="w-full"
											options={yearFormSixteen}
											required={true}
										/>
									}
								/>
								<FormRow
									label="Form 16"
									item={
										<InputBox
											onChange={handlePdf}
											style="w-full cursor-pointer"
											type="file"
											required={true}
										/>
									}
								/>
								<div className="text-center">
									<Button classname="w-auto my-4 text-white text-sm font-semibold py-2 rounded-md px-6 bg-button">
										Submit
									</Button>
								</div>
							</form>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default FormSixteenUpdation;
