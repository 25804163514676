import React, { useEffect, useState, useContext } from "react";
import ApiCall from "../utils/ApiCall";
import AuthContext from "../contexts/AuthContext";
import DateBox from "./DateBox";
import SubmitBtn from "./SubmitBtn";
import PieCharts from "./PieCharts";
import BarCharts from "./BarCharts";
import { TIMESHEETCHART } from "../Constants";
import Loader from "./Loader";

const Dashboard = () => {
	const { authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [pieData, setPieData] = useState([]);
	const [pieDataValue, setPieDataValue] = useState([]);
	const [barXaxis, setBarXaxis] = useState([]);
	const [barYaxis, setBarYaxis] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const handleFromDate = (e) => {
		setFromDate(e.target.value);
	};

	const handleToDate = (e) => {
		setToDate(e.target.value);
	};

	const handleSubmit = async () => {
		const params = {
			fromDate: fromDate,
			toDate: toDate,
		};
		//console.log("date", params);
		try {
			const response = await ApiCall(
				TIMESHEETCHART,
				"POST",
				authTokens,
				params
			);
			//console.log("Barchart", response.data);
			// Update the bar chart data
			setBarXaxis(response.data.bar_x_axis);
			setBarYaxis(response.data.bar_y_axis);
			//  console.log("barchart", response);
		} catch (error) {
			console.error("Error fetching data", error);
		}
	};

	const fetchData = async () => {
		try {
			const res = await ApiCall(TIMESHEETCHART, "GET", authTokens, null);
			//   console.log("res", res);
			const barXaxisData = res.data.bar_x_axis;
			const barYaxisData = res.data.bar_y_axis;
			//   console.log("barYaxisData", barYaxisData);

			setBarXaxis(barXaxisData);
			setBarYaxis(barYaxisData);

			const pieData = res.data.pie_data;
			//   console.log("svss",pieData);
			setPieData(pieData);

			const pieDataValue = res.data.pie_value;
			setPieDataValue(pieDataValue);
			//   console.log("timesheet pie", barXaxis);
			//   console.log("sdvgg",pieData);
		} catch (error) {
			console.log("Error fetching data", error);
		}
		setIsLoading(false);
	};
	return (
		<div>
			<div className=" w-auto  flex flex-col gap-3 justify-center items-center rounded-3xl my-6 mx-6 py-4 px-2">
				<div className="flex flex-col sm:flex-row justify-center items-center gap-4">
					<DateBox onChange={handleFromDate} value={fromDate} />
					<DateBox onChange={handleToDate} value={toDate} />
					<SubmitBtn onClick={handleSubmit} />
				</div>
				<h1 className="text-center m-2 font-semibold">
					Monthly Timesheet Chart
				</h1>
				<div className="flex mt-3 w-full md:mr-0 mr-8 md:h-auto justify-center items-center">
					{isLoading ? (
						<div className="w-full h-[40vh] flex items-center justify-center">
							<Loader />
						</div>
					) : BarCharts ? (
						<BarCharts xaxisvalue={barXaxis} yaxisvalue={barYaxis} />
					) : null}
				</div>
			</div>
			<div className=" w-auto flex justify-center items-center rounded-lg my-6 mx-6 px-2">
				<div className="flex justify-center items-center w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
					<div className="w-full">
						{isLoading ? (
							<div className="w-full h-[40vh] flex items-center justify-center">
								<Loader />
							</div>
						) : pieData ? (
							<PieCharts data={pieData} datas={pieDataValue} />
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
