import React, { useState, useContext, useEffect } from "react";
import { Dropdown, FormRow, Textarea, Button, Loader } from "../../components";
import FileInputBox from "../../components/FileInputBox";
import ApiCallTwo from "../../utils/ApiCallTwo";
import AuthContext from "../../contexts/AuthContext";
import { HELPDESK } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";

const HelpDesk = () => {
	const { authTokens } = useContext(AuthContext);
	const [btnLoader, setBtnLoader] = useState(false);
	const [loading, setLoading] = useState(true);
	const [selectedValue, setSelectedValue] = useState("");
	const [description, setDescription] = useState("");
	const [attachment, setAttachment] = useState(null);

	const handleChange = (e) => {
		setSelectedValue(e.target.value);
	};

	const handleAttachmentChange = (event) => {
		const file = event.target.files[0];
		setAttachment(file);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setBtnLoader(true);

		const formData = new FormData();
		formData.append("attachment", attachment);
		formData.append("category", selectedValue);
		formData.append("description", description);

		// console.log("params", formData);
		try {
			const response = await ApiCallTwo(HELPDESK, "POST", authTokens, formData);
			console.log("Add holiday datas", response);
			// setLoading(false);
			if (response.status === 200) {
				SweetAlert({ title: response.data, icon: "success" });
			} else {
				SweetAlert({ title: response.data, icon: "error" });
			}
			setSelectedValue("");
			setDescription("");
			setAttachment(null);
			setBtnLoader(false);
		} catch (error) {
			console.log("Error adding addholiday", error);
		}
	};

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2 flex items-center justify-center">
			<form
				className="w-full md:w-3/5 sm:w-full my-6 mx-6 py-4 px-2"
				onSubmit={handleSubmit}
			>
				<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4 ">
					<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4 ">
						<h3 className="mx-6 text-center table-header-text font-bold text-lg ">
							Help Desk
						</h3>
					</div>
					<FormRow
						label="Category"
						item={
							<Dropdown
								style="h-10 w-full text-md"
								options={[
									{ value: "", label: "Select" },
									{ value: "IT", label: "IT" },
									{ value: "admin", label: "Admin" },
									{ value: "recruitment", label: "Recruitment" },
									{ value: "accounts", label: "Accounts" },
									{ value: "hr", label: "HR" },
								]}
								onChange={handleChange}
								value={selectedValue}
								required={true}
							/>
						}
					/>
					<FormRow
						label="Description"
						item={
							<Textarea
								style="h-20 w-full text-md font-style: italic;"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								className="p-2 border border-gray-300 rounded"
								required={true}
							/>
						}
					/>
					<FormRow
						label="Attachment"
						item={
							<FileInputBox
								accept="image/*"
								style="w-full text-md"
								onChange={handleAttachmentChange}
							/>
						}
					/>

					<div className="intro-x">
						{btnLoader ? (
							<div className="mt-4">
								<Loader />
							</div>
						) : (
							<Button classname="bg-button px-5 py-2 rounded-lg text-white font-bold mt-4">
								Submit
							</Button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default HelpDesk;
