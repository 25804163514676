import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { MYPROFILE } from "../Constants";
import {
	MdPerson,
	MdEmail,
	MdShoppingBag,
	MdDateRange,
	MdPersonAddAlt1,
} from "react-icons/md";
import ApiCall from "../utils/ApiCall";
import Loader from "./Loader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useStateContext } from "../contexts/ContextProvider";

const MyProfile = () => {
	const { userPicData, imgLoader } = useStateContext();
	const { user } = useContext(AuthContext);
	const { authTokens } = useContext(AuthContext);
	const [userDetails, setUserDetails] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(MYPROFILE, "GET", authTokens, null);
			//console.log("res", res.data);
			setUserDetails(res.data);
			// console.log("user details", userDetails);
			setIsLoading(false);
		} catch (error) {
			console.log("Error fetching data", error);
			setIsLoading(false);
		}
	};
	return (
		<div className="w-auto flex gap-1 justify-center items-center rounded-3xl my-6 mx-6">
			<div className="container mx-auto my-4">
				<div className="columns-1 flex lg:flex-row flex-col items-center lg:columns-2 gap-6 lg:gap-28 justify-center">
					<div className="bg-profileBg intro-x box-shadow text-center flex rounded-full h-32 w-32 md:h-60 md:w-60 md:p-6 p-2">
						{isLoading ? (
							<Skeleton
								baseColor="#808080"
								className="w-full rounded-full transition-all ease-in-out"
							/>
						) : (
							userDetails &&
							userDetails.map((item, index) => (
								<img
									key={index}
									src={item.photo}
									className="w-full rounded-full transition-all ease-in-out"
								/>
							))
						)}
					</div>
					{isLoading ? (
						<Loader />
					) : (
						<div className="bg-accent intro-x box-shadow md:p-4 p-2 rounded-3xl">
							{userDetails &&
								userDetails.map((item, index) => (
									<div
										className="flex justify-center gap-6 md:gap-24 m-4"
										key={index}
									>
										<div className="flex flex-col">
											<p className="md:text-[16px] text-xs flex items-center gap-2 bold m-1">
												<MdPerson className="text-2xl p-1" />
												<span className="text-profileBg ">Name</span>
											</p>
											<p className="md:text-[16px] text-xs flex items-center gap-2 bold m-1">
												<MdEmail className="text-2xl p-1" />
												<span className="text-profileBg ">Email</span>
											</p>
											<p className="md:text-[16px] text-xs flex items-center gap-2 bold m-1">
												<MdShoppingBag className="text-2xl p-1" />
												<span className="text-profileBg ">Designation</span>
											</p>
											<p className="md:text-[16px] text-xs flex items-center gap-2 bold m-1">
												<MdDateRange className="text-2xl p-1" />
												<span className="text-profileBg ">Dob</span>
											</p>
											<p className="md:text-[16px] text-xs flex items-center gap-2 bold m-1">
												<MdPersonAddAlt1 className="text-2xl p-1" />
												<span className="text-profileBg ">Doj</span>
											</p>
										</div>
										<div className="flex flex-col">
											<p className="md:text-[16px] text-xs bold m-2">
												{item.user}
											</p>
											<p className="md:text-[16px] text-xs bold m-2">
												{item.email}
											</p>
											<p className="md:text-[16px] text-xs bold m-2">
												{item.designation}
											</p>
											<p className="md:text-[16px] text-xs bold m-2">
												{item.dob}
											</p>
											<p className="md:text-[16px] text-xs bold m-2">
												{item.doj}
											</p>
										</div>
									</div>
								))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyProfile;
