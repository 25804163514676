import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import ApiCall from "../../utils/ApiCall";
import ApiCallTwo from "../../utils/ApiCallTwo";
import { MODIFIEDUSER, EDITUSER, EDITUSERPROFILE } from "../../Constants";
import {
	Table,
	Loader,
	Button,
	SwitchTab,
	ModifyForm,
	ModifyUserDetails,
	Dropdown,
} from "../../components";
import { lmsModifyUserHead, dropDownPagination } from "../../data";
import { MdKeyboardBackspace } from "react-icons/md";
import SweetAlert from "../../utils/SweetAlert";

const ModifyUser = () => {
	const { user, authTokens } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(true);
	const [tabLoading, setTabLoading] = useState(false);
	const [modifiedUser, setModifiedUser] = useState("");
	const [popupItem, setPopupItem] = useState(false);
	const [userDetails, setUserDetails] = useState("");
	const [currentTab, setCurrentTab] = useState(
		sessionStorage.getItem("activeTab")
			? sessionStorage.getItem("activeTab")
			: "Modify User"
	);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	// const profilePic = userDetails.profile_pic;
	const reportToData = userDetails.report_to;
	const reportToDataThree = userDetails.report_to_three;
	const reportToDataTwo = userDetails.report_to_1;
	const [requestedUserId, setRequestedUserId] = useState("");
	const [profilePic, setProfilePic] = useState("");
	const [userModify, setUserModify] = useState(userDetails);
	const [userRole, setUserRole] = useState("");
	const [userGender, setUserGender] = useState("");
	const [userDob, setUserDob] = useState("");
	const [userDoj, setUserDoj] = useState("");
	const [mpagination, setMpagination] = useState(10);
	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userId, setUserId] = useState("");
	const [userDesignation, setUserDesignation] = useState("");
	const [userBname, setUserBname] = useState("");
	const [userBnumber, setUserBnumber] = useState("");
	const [userPfNumber, setUserPfNumber] = useState("");
	const [userSleave, setUserSleave] = useState("");
	const [userCleave, setUserCleave] = useState("");
	const [userOptilnalAvail, setUserOptilnalAvail] = useState("");
	const [userLop, setUserLop] = useState("");
	const [userRedeem, setUserRedeem] = useState("");
	const [userReportTo, setUserReportTo] = useState("");
	const [userReportToThree, setUserReportToThree] = useState("");
	const [userReportToTwo, setUserReportToTwo] = useState("");
	const [userPic, setUserPic] = useState("");

	// console.log("checking user details", userDetails);

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem("modify-pagination-data");
		if (savedPagination) {
			setMpagination(JSON.parse(savedPagination));
		}
	}, [mpagination]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${MODIFIEDUSER}?pg_r=${mpagination}`,
				"GET",
				authTokens,
				null
			);
			setModifiedUser(res.data.users);
			setIsLoading(false);
		} catch (error) {
			console.log("error during holiday calendar data fetching", error);
			setIsLoading(false);
		}
	};

	const handlePopupShow = (rowIndex) => {
		// setTabLoading(true);
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = modifiedUser[actualIndex];
		// sessionStorage.setItem("popup-data", currentData);
		setPopupItem(currentData);
		const id = modifiedUser[actualIndex].id;
		// console.log("id", id);
		fetchGetId(id);
		setUserName("");
		setUserDetails("");
		setUserModify("");
		setUserRole("");
		setUserGender("");
		setUserDob("");
		setUserDoj("");
		setUserEmail("");
		setUserId("");
		setUserDesignation("");
		setUserBname("");
		setUserBnumber("");
		setUserPfNumber("");
		setUserSleave("");
		setUserCleave("");
		setUserOptilnalAvail("");
		setUserLop("");
		setUserRedeem("");
		setProfilePic("");
		setRequestedUserId("");
		setUserReportTo("");
		setUserReportToThree("");
		setUserReportToTwo("");
		// setTabLoading(false);
	};

	const fetchGetId = async (id) => {
		setTabLoading(true);
		try {
			const res = await ApiCall(`${EDITUSER}${id}`, "GET", authTokens);
			//console.log("get User data", res.data);
			//console.log("rrrrrrrrrrr", res.data);
			if (res.data !== null) {
				setUserDetails(res.data);
				setUserModify(res.data);
				setUserRole(res.data.user.role);
				setUserGender(res.data.user.gender);
				setUserDob(res.data.user.dob);
				setUserDoj(res.data.user.doj);
				setUserName(res.data.user.name);
				setUserEmail(res.data.user.email);
				setUserId(res.data.user.employee_id);
				setUserDesignation(res.data.user.designation);
				setUserBname(res.data.user.bank_name);
				setUserBnumber(res.data.user.bank_account_number);
				setUserPfNumber(res.data.user.pf_no);
				setUserSleave(res.data.holdiay_serializer.sick_leave_allowed);
				setUserCleave(res.data.holdiay_serializer.casual_leave_allowed);
				setUserOptilnalAvail(res.data.holdiay_serializer.optional_leave);
				setUserLop(res.data.holdiay_serializer.lop);
				setUserRedeem(res.data.holdiay_serializer.redeem_leave);
				setProfilePic(res.data.profile_pic);
				setRequestedUserId(res.data.user.id);
				setUserReportTo(res.data.user.report_to);
				setUserReportToThree(res.data.user.report_to_three);
				setUserReportToTwo(res.data.user.report_to_1);
			}
			//const userDataInLocal = JSON.stringify(res.data);
			// sessionStorage.setItem("user-details", userDataInLocal);
			setIsLoading(false);
		} catch (error) {
			console.log("Error during Get User data fetching", error);
			setIsLoading(false);
		}
		try {
			const res = await ApiCallTwo(
				`${EDITUSERPROFILE}${id}`,
				"GET",
				authTokens
			);
			// console.log("profile pic", res.data);
			if (res.data !== null) {
				const imageBlob = res.data;
				const url = URL.createObjectURL(imageBlob);
				setProfilePic(url);
			}
			setIsLoading(false);
		} catch (error) {
			console.log("Error during Get User data fetching", error);
		}
		setTabLoading(false);
	};

	const handleTabChange = (tab) => {
		setCurrentTab(tab);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleGoBack = () => {
		setPopupItem("");
		// sessionStorage.removeItem("popup-data");
	};

	const handleChange = (e) => {
		setPopupItem({ ...popupItem, [e.target.name]: e.target.value });
	};

	const handleRole = (e) => {
		setUserRole(e.target.value);
	};

	const handleGender = (e) => {
		setUserGender(e.target.value);
	};

	const handleDob = (e) => {
		setUserDob(e.target.value);
	};

	const handleDoj = (e) => {
		setUserDoj(e.target.value);
	};

	const handleChangeEmp = (e) => {
		setUserName(e.target.value);
	};

	const handleEmail = (e) => {
		setUserEmail(e.target.value);
	};

	const handleId = (e) => {
		setUserId(e.target.value);
	};

	const handleDesignation = (e) => {
		setUserDesignation(e.target.value);
	};

	const handleBname = (e) => {
		setUserBname(e.target.value);
	};

	const handleBnumber = (e) => {
		setUserBnumber(e.target.value);
	};

	const handlePfnum = (e) => {
		setUserPfNumber(e.target.value);
	};

	const handleSick = (e) => {
		setUserSleave(e.target.value);
	};

	const handleOptional = (e) => {
		setUserOptilnalAvail(e.target.value);
	};

	const handleCasual = (e) => {
		setUserCleave(e.target.value);
	};

	const handleLop = (e) => {
		setUserLop(e.target.value);
	};

	const handleRedeem = (e) => {
		setUserRedeem(e.target.value);
	};

	const handleReportTo = (e) => {
		setUserReportTo(e.target.value);
		//setUserReportTo({ ...userReportTo, [e.target.name]: e.target.value });
		//console.log("user report", e.target.value);
	};

	const handleReportToThree = (e) => {
		setUserReportTo(e.target.value);
	};

	const handleReportTwo = (e) => {
		setUserReportToTwo(e.target.value);
		// console.log("userReportToTwo", userReportToTwo);
	};

	const handleRemovePng = (e) => {
		setProfilePic("");
	};

	const handleImage = (e) => {
		const file = e.target.files[0];
		setUserPic(file);
		const reader = new FileReader();
		reader.onload = () => {
			const url = reader.result;
			setProfilePic(url);
		};
		reader.readAsDataURL(file);
	};

	const handleModifyUser = async (event) => {
		event.preventDefault();
		const formData = new FormData();
		formData.append("id", requestedUserId);
		formData.append("emp_id", userId && userId.length > 0 ? userId : "None");
		formData.append("username", userName);
		formData.append("email", userEmail);
		formData.append("role", userRole);
		formData.append("designation", userDesignation);
		formData.append("gender", userGender);
		formData.append("dob", userDob);
		formData.append("doj", userDoj);
		formData.append("bank", userBname);
		formData.append("acc_no", userBnumber);
		formData.append("pf_no", userPfNumber);
		formData.append("profile_pic", userPic);
		formData.append("sick", userSleave);
		formData.append("casual", userCleave);
		formData.append("lop", userLop);
		formData.append("oh_availed", userOptilnalAvail);
		formData.append("leave_redeemed", userRedeem);

		if (userRole && userRole === "EMPLOYEE") {
			formData.append(
				"report_to",
				userReportTo && userReportTo !== null ? userReportTo : "None"
			);
		}

		if (userRole && userRole === "TRAINEE") {
			formData.append(
				"report_to_three",
				userReportToThree && userReportToThree !== null ? userReportToThree : "None"
			);
		}

		if (userRole && userRole === "INTERN") {
			formData.append(
				"report_to_1",
				userReportToTwo && userReportToTwo !== null ? userReportToTwo : "None"
			);
		}
		//console.log("userReportTo", userReportTo);
		//console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }
		try {
			const response = await ApiCallTwo(
				`${EDITUSER}${requestedUserId}`,
				"POST",
				authTokens,
				formData
			);
			if (response.status === 200) {
				SweetAlert({ title: "Successfully Applied", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("While Sending Modified Data", error);
		}
	};

	const mpaginatedData = (e) => {
		e.preventDefault();
		setMpagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem("modify-pagination-data", JSON.stringify(pageValue));
	};

	// console.log("user details", userDetails);
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
				Modify User
			</h2>
			{isLoading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					{popupItem ? (
						<div className="intro-x px-2">
							<Button
								classname="mt-4 ml-1 hover:translate-y-[-3px] hover:font-semibold hover:nav-item-active transition-all ease-in-out"
								onClick={handleGoBack}
							>
								<span className="flex justify-center items-center gap-1 text-sm">
									<MdKeyboardBackspace size={18} />
									Go Back
								</span>
							</Button>
							<SwitchTab
								tabs={["Modify User", "User Details"]}
								defaultTab={currentTab}
								onTabChange={handleTabChange}
							/>
							{currentTab === "Modify User" && userDetails && (
								<div>
									{tabLoading ? (
										<div className="w-full h-[70vh] flex items-center justify-center">
											<Loader />
										</div>
									) : (
										<ModifyForm
											onSubmit={handleModifyUser}
											handleImage={handleImage}
											profilePic={profilePic}
											data={userDetails}
											handleRemovePng={handleRemovePng}
											userModify={userModify}
											userRole={userRole}
											userGender={userGender}
											userDob={userDob}
											userDoj={userDoj}
											userName={userName}
											userEmail={userEmail}
											userId={userId}
											userDesignation={userDesignation}
											userBname={userBname}
											userBnumber={userBnumber}
											userPfNumber={userPfNumber}
											userSleave={userSleave}
											userCleave={userCleave}
											userOptilnalAvail={userOptilnalAvail}
											userLop={userLop}
											userRedeem={userRedeem}
											handleRole={handleRole}
											handleGender={handleGender}
											handleDob={handleDob}
											handleDoj={handleDoj}
											userReportTo={userReportTo}
											userReportToThree={userReportToThree}
											userReportToTwo={userReportToTwo}
											reportToData={reportToData}
											reportToDataThree={reportToDataThree}
											reportToDataTwo={reportToDataTwo}
											handleReportTo={handleReportTo}
											handleReportTwo={handleReportTwo}
											handleReportToThree={handleReportToThree}
											handleChangeEmp={handleChangeEmp}
											handleEmail={handleEmail}
											handleId={handleId}
											handleDesignation={handleDesignation}
											handleBname={handleBname}
											handleBnumber={handleBnumber}
											handlePfnum={handlePfnum}
											handleSick={handleSick}
											handleCasual={handleCasual}
											handleOptional={handleOptional}
											handleLop={handleLop}
											handleRedeem={handleRedeem}
										/>
									)}
								</div>
							)}
							{currentTab === "User Details" && userDetails && (
								<div>
									{tabLoading ? (
										<div className="w-full h-[70vh] flex items-center justify-center">
											<Loader />
										</div>
									) : (
										<ModifyUserDetails
											profilePic={profilePic}
											data={userDetails}
										/>
									)}
								</div>
							)}
						</div>
					) : (
						<div>
							<div className="w-full flex justify-end items-end px-4">
								<Dropdown
									style="px-4"
									value={mpagination}
									onChange={mpaginatedData}
									options={dropDownPagination}
								/>
							</div>
							<Table
								header={lmsModifyUserHead}
								body={modifiedUser.map((row) => [
									row.id,
									row.name,
									row.email,
									row.role,
								])}
								btnTitle="View"
								paginationNeed={true}
								currentPage={currentPage}
								rowPerPage={rowPerPage}
								paginationBody={modifiedUser}
								onPageChange={handlePageChange}
								onClick={handlePopupShow}
								tableStyle="intro-x"
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ModifyUser;
