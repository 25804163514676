import React, { useEffect, useState, useContext } from "react";
import { FormRow, Textarea, InputBox, Button, Popup } from "../../components";
import { OPENTICKETHR, TECHSUPPORTSHARE } from "../../Constants";
import { useLocation } from "react-router-dom";
import ApiCall from "../../utils/ApiCall";
import { useHistory } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import SweetAlert from "../../utils/SweetAlert";
import { MdKeyboardBackspace, MdOutlineShare } from "react-icons/md";

const TicketsView = () => {
	const { user } = useContext(AuthContext);
	const location = useLocation();
	const history = useHistory();
	const ticketData = location.state?.ticketData;
	const ticketDataID = location.state?.ticketDataID;
	const attachmentPic = location.state?.attachmentPic;
	const [ticketViewId, setTicketViewId] = useState(ticketDataID);
	const [accessPopup, setAccessPopup] = useState(false);
	const { authTokens } = useContext(AuthContext);
	const handleOpenPopup = () => {
		setAccessPopup(true);
	};

	const handleShareToTech = async () => {
		const params = {
			true: "true",
			id: ticketViewId,
		};
		//console.log("id", ticketViewId);
		try {
			const res = await ApiCall(TECHSUPPORTSHARE, "POST", authTokens, params);
			if (res.status === 200) {
				SweetAlert({ title: "Successfully Shared", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry! Something went wrong", icon: "error" });
			}
			//console.log("access", res);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	const handleNo = () => {
		setAccessPopup(false);
	};
	// console.log("id .....", ticketViewId);

	const handleYes = async () => {
		const params = {
			yes: "yes",
			id: ticketViewId,
		};
		// console.log("ticketViewparams", params);
		try {
			const res = await ApiCall(OPENTICKETHR, "POST", authTokens, params);
			setAccessPopup(false);
			if (user.user_role === "EMPLOYEE" || user.user_role === "TRAINEE") {
				history.push({
					pathname: "/helpdesk/open-ticket",
				});
			} else {
				history.push({
					pathname: "/helpdesk/openticket",
				});
			}
			if (res.status === 200) {
				SweetAlert({ title: "Ticket Closed", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry! Something went wrong", icon: "error" });
			}
			// console.log("access", res);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};
	//console.log("user", user);

	const handleGoBack = () => {
		if (user.user_role === "EMPLOYEE" || user.user_role === "TRAINEE") {
			history.push({
				pathname: "/helpdesk/open-ticket",
			});
		} else {
			history.push({
				pathname: "/helpdesk/openticket",
			});
		}
	};

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2 flex flex-col ">
			<div className="flex justify-center items-center w-full">
				<div className="w-full md:w-4/5 flex justify-center items-center">
					<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full pb-4 ">
						{user && user.user_role === "EMPLOYEE" || user && user.user_role === "TRAINEE" ? (
							<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-between items-center py-3 mb-4 ">
								<div className="flex justify-start mb-5 w-full md:w-2/5 ml-2">
									<Button
										classname="mt-4 ml-1 hover:translate-y-[-3px] hover:font-semibold hover:nav-item-active transition-all ease-in-out"
										onClick={handleGoBack}
									>
										<span className="flex justify-center items-center gap-1 text-sm text-[#ffffffc7]">
											<MdKeyboardBackspace size={18} />
											Go Back
										</span>
									</Button>
								</div>
								<h3 className="text-center mr-16 md:mr-36 pr-6 table-header-text font-bold text-md md:text-lg w-full">
									Ticket View
								</h3>
							</div>
						) : (
							<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-evenly items-center py-3 mb-4 ">
								<div className="w-full flex items-center justify-start ml-2">
									<Button
										classname="ml-1 hover:translate-y-[-3px] hover:font-semibold hover:nav-item-active transition-all ease-in-out"
										onClick={handleGoBack}
									>
										<span className="flex justify-center items-center gap-1 text-sm text-[#ffffffc7]">
											<MdKeyboardBackspace size={18} />
											Go Back
										</span>
									</Button>
								</div>

								<h3 className="text-center table-header-text font-bold text-sm md:text-lg w-full">
									Ticket View
								</h3>

								<div className="flex justify-end w-full mr-2">
									<Button
										classname="bg-[#795997] text-white flex items-center justify-center gap-2 font-semibold text-xs md:text-sm md:px-4 p-1 md:py-2 rounded-md my-4"
										onClick={handleShareToTech}
									>
										<MdOutlineShare size={18} /> Share To Technical Team
									</Button>
								</div>
							</div>
						)}

						<FormRow
							label="Employee Name"
							item={
								<InputBox
									style="h-10 w-full text-md"
									className="p-2 border border-gray-300 rounded"
									value={ticketData && ticketData.name}
									disabled={true}
								/>
							}
						/>

						<FormRow
							label="Category"
							item={
								<InputBox
									style="h-10 w-full text-md"
									className="p-2 border border-gray-300 rounded"
									value={ticketData && ticketData.category}
									disabled={true}
								/>
							}
						/>

						<FormRow
							label="Description"
							item={
								<Textarea
									style="h-20 w-full text-md"
									className="p-2 border border-gray-300 rounded"
									value={ticketData && ticketData.description}
									disabled={true}
								/>
							}
						/>

						<FormRow
							label="Ticket Solved"
							item={
								<Button
									classname="bg-red-600 text-white font-semibold text-sm px-4 py-2 rounded-md"
									onClick={handleOpenPopup}
								>
									Close
								</Button>
							}
						/>

						<div className="p-2 w-full text-center flex flex-col justify-center items-center">
							<h1 className="p-2 font-semibold text-lg">Attachment</h1>
							<img src={attachmentPic} className="p-2 w-64" />
						</div>
					</div>
				</div>
			</div>

			{accessPopup && (
				<Popup
					title="Ticket Solved"
					trigger={accessPopup}
					setTrigger={setAccessPopup}
					popupstyle="md:w-2/5 w-full"
				>
					<div className="w-full flex my-3 flex-col justify-center items-center">
						<p className="text-lg font-semibold">
							Are sure want to close this ticket?
						</p>
						<div className="flex mt-4 gap-3">
							<Button
								onClick={handleNo}
								classname="bg-button-two text-white px-3 py-1 rounded-sm"
							>
								No
							</Button>
							<Button
								onClick={handleYes}
								classname="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-sm"
							>
								Yes
							</Button>
						</div>
					</div>
				</Popup>
			)}
		</div>
	);
};

export default TicketsView;
