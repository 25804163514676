import React, { useEffect, useState } from "react";
import ModfifyUserForm from "./ModfifyUserForm";
import InputBox from "./InputBox";
import InputFile from "./InputFile";
import DateBox from "./DateBox";
import Dropdown from "./Dropdown";
import { lmsUserRole, lmsUserGender } from "../data";

const ModifyForm = ({
	onSubmit,
	data,
	handleChange,
	userModify,
	userRole,
	userGender,
	userDob,
	userDoj,
	handleRole,
	handleGender,
	handleDob,
	handleDoj,
	userReportTo,
	userReportToTwo,
	reportToData,
	reportToDataTwo,
	reportToDataThree,
	userReportToThree,
	userName,
	userEmail,
	userId,
	userDesignation,
	userBname,
	userBnumber,
	userPfNumber,
	userSleave,
	userCleave,
	userOptilnalAvail,
	userLop,
	userRedeem,
	handleChangeEmp,
	handleReportTwo,
	handleReportTo,
	handleEmail,
	handleId,
	handleDesignation,
	handleBname,
	handleBnumber,
	handlePfnum,
	handleSick,
	handleCasual,
	handleOptional,
	handleLop,
	handleRedeem,
	profilePic,
	handleRemovePng,
	handleImage,
	handleReportToThree
}) => {
	// console.log("main page", userModify);
	// console.log("userPfNumber", userPfNumber);
	return (
		<ModfifyUserForm
			styleOne="p-4"
			styleTwo="p-4"
			styleThree="p-4"
			onSubmit={onSubmit}
			submit="Modify"
			labelOne="Employee Name"
			itemOne={
				<InputBox
					type="text"
					name="name"
					value={userName && userName}
					onChange={handleChangeEmp}
					disabled={false}
					style="w-full"
				/>
			}
			labelTwo="E-Mail Address"
			itemTwo={
				<InputBox
					type="email"
					name="email"
					value={userEmail && userEmail}
					onChange={handleEmail}
					disabled={false}
					style="w-full"
				/>
			}
			labelThree="Employee ID"
			itemThree={
				<InputBox
					type="text"
					name="emp"
					value={userId && userId}
					onChange={handleId}
					disabled={false}
					style="w-full"
				/>
			}
			labelFour="Role"
			itemFour={
				<Dropdown
					name="role"
					onChange={handleRole}
					value={userRole}
					options={lmsUserRole}
					style="w-full"
				/>
			}
			labelFive="Designation"
			itemFive={
				<InputBox
					type="text"
					name="designation"
					value={userDesignation && userDesignation}
					onChange={handleDesignation}
					disabled={false}
					style="w-full"
				/>
			}
			labelSix="Gender"
			itemSix={
				<Dropdown
					name="gender"
					value={userGender}
					onChange={handleGender}
					options={lmsUserGender}
					style="w-full"
				/>
			}
			labelSeven="Dob"
			itemSeven={
				<DateBox
					name="dob"
					value={userDob}
					onChange={handleDob}
					disabled={false}
					style="w-full"
				/>
			}
			labelEight="Doj"
			itemEight={
				<DateBox
					name="doj"
					value={userDoj}
					onChange={handleDoj}
					disabled={false}
					style="w-full"
				/>
			}
			labelNine="Bank Name"
			itemNine={
				<InputBox
					type="text"
					name="bank"
					value={userBname && userBname}
					onChange={handleBname}
					disabled={false}
					style="w-full"
				/>
			}
			labelTen="Bank Account Number"
			itemTen={
				<InputBox
					type="text"
					name="acc_no"
					value={userBnumber && userBnumber}
					onChange={handleBnumber}
					disabled={false}
					style="w-full"
				/>
			}
			labelEleven="PF Number"
			itemEleven={
				<InputBox
					type="number"
					name="pf_no"
					value={userPfNumber && userPfNumber.length > 0 ? userPfNumber : ""}
					onChange={handlePfnum}
					disabled={false}
					style="w-full"
				/>
			}
			labelTwelve="Sick leaves allowed"
			itemTwelve={
				<InputBox
					type="number"
					name="sick"
					value={userSleave && userSleave}
					onChange={handleSick}
					disabled={false}
					style="w-full"
				/>
			}
			labelThirteen="Casual leaves allowed"
			itemThirteen={
				<InputBox
					type="number"
					name="casual"
					value={userCleave && userCleave}
					onChange={handleCasual}
					disabled={false}
					style="w-full"
				/>
			}
			labelFourteen="Optional Holidays Availed"
			itemFourteen={
				<InputBox
					type="number"
					name="optional"
					value={userOptilnalAvail && userOptilnalAvail}
					onChange={handleOptional}
					disabled={false}
					style="w-full"
				/>
			}
			labelFifteen="Lop"
			itemFifteen={
				<InputBox
					type="number"
					name="lop"
					value={userLop && userLop}
					onChange={handleLop}
					disabled={false}
					style="w-full"
				/>
			}
			labelSixteen="Leave Redeemed"
			itemSixteen={
				<InputBox
					type="number"
					name="leave_redeem"
					value={userRedeem && userRedeem}
					onChange={handleRedeem}
					disabled={false}
					style="w-full"
				/>
			}
			labelSeventeen={
				userRole && userRole === "EMPLOYEE" ? "Reporting To" : null
			}
			itemSeventeen={
				userRole && userRole === "EMPLOYEE" ? (
					<Dropdown
						options={
							reportToDataTwo && [
								{ value: "", label: "Select" },
								...reportToDataTwo.map((item) => ({
									label: item.name,
									value: item.id,
								})),
							]
						}
						value={userReportTo}
						onChange={handleReportTo}
						style="w-full"
					/>
				) : null
			}
			lthreeExtraThree={
				userRole && userRole === "TRAINEE" ? "Reporting To" : null
			}
			ithreeExtraThree={
				userRole && userRole === "TRAINEE" ? (
					<Dropdown
						options={
							reportToDataTwo && [
								{ value: "", label: "Select" },
								...reportToDataTwo.map((item) => ({
									label: item.name,
									value: item.id,
								})),
							]
						}
						value={userReportToThree}
						onChange={handleReportToThree}
						style="w-full"
					/>
				) : null
			}
			ltthreeExtraOne={
				userRole && userRole === "INTERN" ? "Reporting To" : null
			}
			ithreeExtraOne={
				userRole && userRole === "INTERN" ? (
					<Dropdown
						options={
							reportToDataTwo && [
								{ value: "", label: "Select" },
								...reportToDataTwo.map((item) => ({
									label: item.name,
									value: item.id,
								})),
							]
						}
						value={userReportToTwo}
						onChange={handleReportTwo}
						style="w-full"
					/>
				) : null
			}
			labelEighteen="USER PROFILE"
			itemEighteen={
				<InputFile
					onChange={handleImage}
					cancelBtn={handleRemovePng}
					userImg={profilePic}
					accept="image/*"
				/>
			}
		/>
	);
};

export default ModifyForm;
