import React, { useState, useContext } from "react";
import FileInputBox from "../../components/FileInputBox";
import { Button, InputBox } from "../../components";
import AuthContext from "../../contexts/AuthContext";
import ApiCallTwo from "../../utils/ApiCallTwo";
import { LEAVEPOLICYFILE, HRPOLICYFILE } from "../../Constants";

const PolicyUpdate = () => {
	const { authTokens } = useContext(AuthContext);
	const [leavePolicyFile, setLeavePolicyFile] = useState(null);
	const [hrPolicyFile, setHrPolicyFile] = useState(null);

	const handleLeavePolicyFile = (event) => {
		const file = event.target.files[0];
		setLeavePolicyFile(file);
	};

	const handleHrPolicyFile = (event) => {
		const file = event.target.files[0];
		setHrPolicyFile(file);
	};

	const SubmitLeavePolicy = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("leave_policy", leavePolicyFile);
		//console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }

		try {
			const response = await ApiCallTwo(
				LEAVEPOLICYFILE,
				"POST",
				authTokens,
				formData
			);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	const SubmitHrPolicy = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("hr_policy", hrPolicyFile);

		//console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }

		try {
			const response = await ApiCallTwo(
				HRPOLICYFILE,
				"POST",
				authTokens,
				formData
			);
		} catch (error) {
			console.log("Error uploading file", error);
		}
	};

	return (
		<div className="w-auto my-6 flex md:flex-row flex-col gap-4 mx-6 py-4 px-2 justify-center">
			<div className="w-full flex flex-col px-2 justify-center">
				<form onSubmit={SubmitLeavePolicy}>
					<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
						<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
							<h3 className="mx-6 text-center table-header-text font-bold text-lg">
								Leave Policy Update
							</h3>
						</div>

						<div className="flex flex-col items-center justify-center gap-3 p-4">
							<InputBox
								onChange={handleLeavePolicyFile}
								style="w-full cursor-pointer"
								type="file"
								required={true}
							/>
							<Button classname="bg-blue-600 text-white font-semibold text-sm px-4 py-2 rounded-md">
								Submit
							</Button>
						</div>
					</div>
				</form>
			</div>

			<div className="w-full flex flex-col px-2 justify-center">
				<form onSubmit={SubmitHrPolicy}>
					<div className="box-shadow intro-y bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto pb-4">
						<div className="w-full intro-y bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
							<h3 className="mx-6 text-center table-header-text font-bold text-lg">
								HR Policy Update
							</h3>
						</div>

						<div className="flex flex-col items-center justify-center gap-3 p-4">
							<InputBox
								onChange={handleHrPolicyFile}
								style="w-full cursor-pointer"
								type="file"
								required={true}
							/>
							<Button classname="bg-blue-600 text-white font-semibold text-sm px-4 py-2 rounded-md">
								Submit
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PolicyUpdate;
