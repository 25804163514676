import React, { useState, useContext, useEffect, useRef } from "react";
import {
	DateBox,
	FormRow,
	SubmitBtn,
	Table,
	LmsLeaveDetails,
	Popup,
	Button,
	Dropdown,
} from "../../components";
import {
	timesheetTableHeadHr,
	timesheetTableHeadEmpAndManager,
} from "../../data";
import { useHistory } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import { HRUSERVIEW, TIMESHEETEXCEL, BASE_URL } from "../../Constants";
import { useLocation } from "react-router-dom";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import SweetAlert from "../../utils/SweetAlert";
import ApiCallTwo from "../../utils/ApiCallTwo";

const EmployeeTimesheet = () => {
	const { user } = useContext(AuthContext);
	const location = useLocation();
	const [pagination, setPagination] = useState(
		sessionStorage.getItem("emphr-timesheet-pagination-data")
			? JSON.parse(sessionStorage.getItem("emphr-timesheet-pagination-data"))
			: 10
	);
	const userTimesheetEdit = location.state?.userTimesheetEdit;
	const userTimesheetEditId = location.state?.userTimesheetEditId;
	const userTimesheetName = location.state?.userTimesheetName;
	//console.log("userTimesheetEditId", userTimesheetEdit);
	const [fromDate, setFromDate] = useState(
		sessionStorage.getItem("emptimesheet-fromdate")
			? JSON.parse(sessionStorage.getItem("emptimesheet-fromdate"))
			: ""
	);
	const [toDate, setToDate] = useState(
		sessionStorage.getItem("emptimesheet-todate")
			? JSON.parse(sessionStorage.getItem("emptimesheet-todate"))
			: ""
	);
	const [userData, setUserData] = useState([]);
	const [userTimeSheetData, setUserTimesheetData] = useState([]);
	const [viewPopup, setViewPopup] = useState(null);
	const [accessPopup, setAccessPopup] = useState(null);
	const { authTokens } = useContext(AuthContext);
	const [employeeTableView, setEmployeeTableView] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	const history = useHistory();

	//console.log("user Data", userData);

	useEffect(() => {
		if (userData.length > 0) {
			setUserTimesheetData(userData);
			//console.log("going one");
		} else {
			// setTimeout(() => {
			setUserTimesheetData(userTimesheetEdit && userTimesheetEdit);
			//console.log("going two");
			// }, 1000);
		}
		const savedFromDate = sessionStorage.getItem("emptimesheet-fromdate");
		if (savedFromDate) {
			setFromDate(JSON.parse(savedFromDate));
		}

		const savedToDate = sessionStorage.getItem("emptimesheet-todate");
		if (savedToDate) {
			setToDate(JSON.parse(savedToDate));
		}

		const savedPagination = sessionStorage.getItem(
			"emphr-timesheet-pagination-data"
		);
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [fromDate, toDate, userData, pagination, userTimesheetEdit]);

	const handleFromDate = (e) => {
		setFromDate(e.target.value);
		sessionStorage.setItem(
			"emptimesheet-fromdate",
			JSON.stringify(e.target.value)
		);
	};

	const handleToDate = (e) => {
		setToDate(e.target.value);
		sessionStorage.setItem(
			"emptimesheet-todate",
			JSON.stringify(e.target.value)
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// console.log("userTimesheetEditId", userTimesheetEditId);
		const params = {
			fromDate: fromDate,
			toDate: toDate,
			id: parseInt(userTimesheetEditId),
		};
		//console.log("date", params);
		try {
			const res = await ApiCall(HRUSERVIEW, "POST", authTokens, params);
			//console.log("res", res);
			setUserData(res.data.user);
			// console.log("user data", userData);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	const handleGoBack = () => {
		sessionStorage.removeItem("emptimesheet-fromdate");
		sessionStorage.removeItem("emptimesheet-todate");
		setUserData([]);
		if (
			user.user_role === "MANAGER" ||
			user.user_role === "EMPLOYEE" ||
			user.user_role === "TRAINEE" ||
			user.user_role === "CONSULTANT"
		) {
			history.push({
				pathname: "/timesheet/employee-dashboard",
			});
		} else {
			history.push({
				pathname: "/timesheet/dashboard",
			});
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleEditView = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = userTimeSheetData[actualIndex];
		// console.log(currentData);
		setViewPopup(currentData);
	};

	const handleEditAccess = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const id = userTimeSheetData[actualIndex].id;
		const access = userTimeSheetData[actualIndex].access;
		const cWeek = userTimeSheetData[actualIndex].c_week;
		if (access === false && cWeek === false) {
			setAccessPopup(id);
		} else {
			setAccessPopup(false);
		}

		// console.log("id", id);
	};

	const handleAccessNo = () => {
		setAccessPopup(null);
	};

	const handleAccessYes = async (id) => {
		//console.log("id:", accessPopup);
		const params = {
			yes: "yes",
			time_id: parseInt(accessPopup),
		};
		try {
			const res = await ApiCall(HRUSERVIEW, "POST", authTokens, params);
			setAccessPopup(null);
			refreshPostData();
			if (res.status === 200) {
				SweetAlert({ title: "Access given", icon: "success" });
			} else {
				SweetAlert({ title: res.data, icon: "error" });
			}
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"emphr-timesheet-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handleDownloadExcel = async () => {
		const params = {
			id: parseInt(userTimesheetEditId),
			from: fromDate,
			to: toDate,
		};

		const queryParams = new URLSearchParams(params);

		fetch(BASE_URL + TIMESHEETEXCEL + "?" + queryParams.toString(), {
			method: "GET",
			headers: {
				Authorization: "Bearer " + String(authTokens.access),
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = userTimesheetName + "-timesheet-report.xlsx";

				document.body.appendChild(a);
				a.click();
				URL.revokeObjectURL(url);
				document.body.removeChild(a);
			})
			.catch((error) => {
				console.error("Error downloading file:", error);
			});
	};

	const refreshPostData = async () => {
		const params = {
			fromDate: fromDate,
			toDate: toDate,
			id: parseInt(userTimesheetEditId),
		};
		//console.log("date", params);
		try {
			const res = await ApiCall(HRUSERVIEW, "POST", authTokens, params);
			//console.log("res two", res);
			setUserData(res.data.user);
			// console.log("user data", userData);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	const handleBtnStyleTwo = (rowIndex) => {
		//console.log("going");
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const nameRow = userTimeSheetData[actualIndex];
		const access = nameRow.access;
		const cweek = nameRow.c_week;
		//console.log("access", access);
		if (access === true || cweek === true) {
			return "Remove Access";
		} else {
			return null;
		}
	};

	const handleChangeBtnTwoName = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const nameRow = userTimeSheetData[actualIndex];
		const access = nameRow.access;
		//console.log("access", access);
		if (access === true) {
			return "Remove Access";
		} else {
			return "Edit Access";
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<h3 className="text-lg font-semibold text-center">
				{userTimesheetName} Time Sheet
			</h3>
			<div className="flex justify-start ml-10">
				<Button
					classname="mt-4 ml-1 hover:translate-y-[-3px] hover:font-semibold hover:nav-item-active transition-all ease-in-out"
					onClick={handleGoBack}
				>
					<span className="flex justify-center items-center gap-1 text-sm">
						<MdKeyboardBackspace size={18} />
						Go Back
					</span>
				</Button>
			</div>

			<form
				className="flex flex-col sm:flex-row justify-center items-center gap-4 mt-5"
				onSubmit={handleSubmit}
			>
				<DateBox required={true} onChange={handleFromDate} value={fromDate} />
				<DateBox required={true} onChange={handleToDate} value={toDate} />
				<SubmitBtn />
			</form>
			<div className="flex justify-end mt-10 mr-5 gap-2">
				{/* <Dropdown
					style="px-4"
					value={pagination}
					onChange={paginatedData}
					options={dropDownPagination}
				/> */}
				<Button
					onClick={handleDownloadExcel}
					classname="bg-button text-white font-semibold text-sm px-4 py-2 rounded-md"
				>
					Export
				</Button>
			</div>

			<div className="mt-5">
				{userTimeSheetData && (
					<Table
						tableStyle="border-spacing-y-[10px] border-separate -mt-4"
						bodyStyle="bg-accent box-shadow"
						header={
							user.user_role === "HR"
								? timesheetTableHeadHr
								: timesheetTableHeadEmpAndManager
						}
						body={userTimeSheetData.map((row) => [
							row.project,
							row.project_manager,
							row.date,
							// row.start_time,
							// row.end_time,
							row.logged_hours,
							// row.non_productive_hrs,
							row.work_mode,
							<span className="truncate max-w-[150px] inline-block">
								{row.status}
							</span>,
						])}
						bodyTdStyle="table-body-td intro-x"
						headTdStyle="table-body-td intro-x"
						btnTitle="view"
						btnTitleTwo={
							user.user_role === "HR" ? handleChangeBtnTwoName : null
						}
						btnstyle="intro-x"
						btnstyleTwo="intro-x"
						// onClickTwo={handleEditView}
						onClick={handleEditView}
						onClickTwo={user.user_role === "HR" ? handleEditAccess : null}
						paginationNeed={true}
						currentPage={currentPage}
						paginationBody={userTimeSheetData}
						rowPerPage={rowPerPage}
						onPageChange={handlePageChange}
						buttonStyleTwo={handleBtnStyleTwo}
						btnTitleTwoName={true}
					/>
				)}
				{viewPopup && (
					<Popup
						title="Employee Timesheet"
						trigger={viewPopup}
						setTrigger={setViewPopup}
						popupstyle="md:w-2/5 w-full"
					>
						<div className="w-full flex flex-col justify-center items-center">
							<LmsLeaveDetails label="Project" item={viewPopup.project} />
							<LmsLeaveDetails
								label="Manager"
								item={viewPopup.project_manager}
							/>
							<LmsLeaveDetails label="Work Mode" item={viewPopup.work_mode} />
							<LmsLeaveDetails label="Start Time" item={viewPopup.start_time} />
							<LmsLeaveDetails label="End Time" item={viewPopup.end_time} />
							<LmsLeaveDetails label="Date" item={viewPopup.date} />
							<LmsLeaveDetails
								label="Logged Hours"
								item={viewPopup.logged_hours}
							/>
							<LmsLeaveDetails label="Status" item={viewPopup.status} />
						</div>
					</Popup>
				)}
				{accessPopup && (
					<Popup
						title="Enable Access"
						trigger={accessPopup}
						setTrigger={setAccessPopup}
						popupstyle="md:w-2/5 w-full"
					>
						<div className="w-full flex my-3 flex-col justify-center items-center">
							<p className="text-lg font-semibold">
								Do you need to give access
							</p>
							<div className="flex mt-4 gap-3">
								<Button
									onClick={handleAccessNo}
									classname="bg-button-two text-white px-3 py-1 rounded-sm"
								>
									No
								</Button>
								<Button
									onClick={handleAccessYes}
									classname="bg-button text-white px-3 py-1 rounded-sm"
								>
									Yes
								</Button>
							</div>
						</div>
					</Popup>
				)}
			</div>
		</div>
	);
};
export default EmployeeTimesheet;
