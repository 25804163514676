import React from "react";
import ReactApexChart from "react-apexcharts";

const colors = [
	"#267EC3",
	"#23E4A3",
	"#FEBC3B",
	"#FF6178",
	"#46B3A9",
	"#D830EB",
	"#008FFB",
	"#6D848E",
];

const BarCharts = ({ xaxisvalue, yaxisvalue, xAxisLabel, yAxisLabel }) => {
	const chartData = {
		series: [
			{
				name: "Y-axis",
				data: yaxisvalue,
			},
		],
		options: {
			chart: {
				height: 350,
				type: "bar",
				events: {
					click: function (chart, w, e) {},
				},
			},
			colors: colors,
			plotOptions: {
				bar: {
					columnWidth: "50%",
					distributed: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: false,
			},
			xaxis: {
				categories: xaxisvalue,
				labels: {
					style: {
						colors: "#7352C7",
						fontSize: "12px",
						fontWeight: 600,
					},
				},
				title: {
					text: xAxisLabel,
					style: {
						color: "#7352C7",
						fontSize: "14px",
						fontWeight: 800,
					},
				},
			},
			yaxis: {
				min: 0,
				max: 12,
				tickAmount: 4,
				labels: {
					style: {
						colors: "#7352C7",
						fontSize: "12px",
						fontWeight: 600,
					},
				},
				title: {
					text: yAxisLabel,
					style: {
						color: "#7352C7",
						fontSize: "14px",
						fontWeight: 800,
					},
				},
			},
			chart: {
				toolbar: {
					show: false,
				},
			},
		},
	};

	return (
		<div id="chart" style={{ width: "90%" }}>
			<ReactApexChart
				options={chartData.options}
				series={chartData.series}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default BarCharts;
