import React from "react";
import Button from "./Button";

const InputFile = ({ onChange, userImg, cancelBtn, disabled, accept }) => {
	return (
		<div>
			<div className="flex items-center justify-center w-full intro-x">
				<label
					htmlFor="dropzone-file"
					className="flex flex-col file-input items-center cursor-pointer justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-2xl cursor-point bg-primary dark:border-gray-600 dark:hover:border-gray-500"
				>
					{userImg ? (
						<>
							<h4 className="text-sm font-bold uppercase">
								User Profile Added
							</h4>
							<img
								className="w-40 h-auto my-2"
								src={userImg && userImg}
								alt="User Profile"
							/>
							{disabled ? null : (
								<Button
									onClick={cancelBtn}
									classname="bg-[rgb(30,64,175)] box-shadow p-2 mt-2 rounded-md text-white px-3 text-xs"
								>
									Update
								</Button>
							)}
						</>
					) : (
						<>
							<div className="flex flex-col items-center justify-center pt-5 pb-6">
								<svg
									className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 16"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
									/>
								</svg>
								<p className="mb-2 text-sm text-center text-gray-500 dark:text-gray-400">
									<span className="font-semibold">Click to upload</span> or drag
									and drop
								</p>
								<p className="text-xs text-gray-500 dark:text-gray-400">
									SVG, PNG, JPG or GIF
								</p>
							</div>
							<input
								onChange={onChange}
								id="dropzone-file"
								type="file"
								className="hidden"
								disabled={disabled}
								accept={accept}
							/>
						</>
					)}
				</label>
			</div>
		</div>
	);
};

export default InputFile;
