import React from "react";

const InputBox = ({
	name,
	type,
	value,
	style,
	placeholder,
	disabled,
	onChange,
	checked,
	readOnly,
	required,
}) => {
	return (
		<input
			type={type ? type : "text"}
			name={name}
			checked={checked}
			value={value}
			className={`border-gray-300 bg-transparent ${style} border text-sm rounded-md p-3`}
			placeholder={placeholder}
			onChange={onChange}
			disabled={disabled}
			required={required}
			readOnly={readOnly}
			{...(type === "file" && { accept: "application/pdf" })}
		/>
	);
};

export default InputBox;
