import React from 'react';

const OrgChart = ({ title, children, name, avatar }) => (
    <li>
        <div className="bg-slate-200 team-chart card border  ">
                <img src={avatar} alt="images" className="" />
            <span className="label teamchart-title h-6 flex flex-col  p-2 mb-[35px] ">
                {name}
                <span className=" text-xs pt-1 teamchart-name uppercase font-semibold">
                {title}
            </span>
            </span> 
        </div>
        {children && <ul>{children}</ul>}
    </li>
)

export default OrgChart;
