import React, { useContext, useEffect, useState } from "react";
import { Loader, UserDetailsForm } from "../../components";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { MYDETAILSDATA, EDITUSERPROFILE, MYPROFILE } from "../../Constants";
import ApiCallTwo from "../../utils/ApiCallTwo";
import SweetAlert from "../../utils/SweetAlert";
import { useStateContext } from "../../contexts/ContextProvider";

const UserDetails = () => {
	const { authTokens } = useContext(AuthContext);
	const { fetchUserProfileData } = useStateContext();
	const [loading, setLoading] = useState(true);
	const [modifyDetailsData, setModifyDetailsData] = useState("");
	const [profilePic, setProfilePic] = useState([]);
	const [userPic, setUserPic] = useState("");
	const [isChecked, setIsChecked] = useState(false);

	//console.log("ssssssssssssssssssss", userPicData);
	useEffect(() => {
		fetchUserProfileDat();
	}, []);

	const fetchUserProfileDat = async () => {
		try {
			const res = await ApiCallTwo(MYPROFILE, "GET", authTokens, null);
			if (res.data !== null) {
				const url = res.data[0].photo;
				setProfilePic(url);
				setLoading(false);
			}
		} catch (error) {
			console.log("Error while fetching data from api", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(MYDETAILSDATA, "GET", authTokens, null);
			setModifyDetailsData(res.data);
			//setProfilePic(res.data.profile_pic);
			setIsChecked(res.data.same_as_per_addr);
			setLoading(false);
		} catch (error) {
			console.log("Error while fetching data from api", error);
			setLoading(false);
		}
		try {
			const res = await ApiCallTwo(MYPROFILE, "GET", authTokens, null);
			if (res.data !== null) {
				const url = res.data[0].photo;
				setLoading(false);
				//setProfilePic(url);
			}
		} catch (error) {
			console.log("Error while fetching data from api", error);
			setLoading(false);
		}
	};

	const handleDataChange = (event, field) => {
		const { value } = event.target;
		setModifyDetailsData((prevData) => ({ ...prevData, [field]: value }));
	};

	const handleRemovePng = (e) => {
		setProfilePic("");
	};

	const handleImage = (e) => {
		const file = e.target.files[0];
		setUserPic(file);
		const reader = new FileReader();
		reader.onload = () => {
			const url = reader.result;
			setProfilePic(url);
		};
		reader.readAsDataURL(file);
	};

	const handleCheckBox = (e) => {
		setIsChecked(e.target.checked);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("personal_email", modifyDetailsData.personal_email);
		formData.append("same_as_per_addr", isChecked);
		formData.append("profile_pic", userPic);
		formData.append("add1", modifyDetailsData.per_address1);
		formData.append("add2", modifyDetailsData.per_address2);
		formData.append("city", modifyDetailsData.per_city);
		formData.append("state", modifyDetailsData.per_state);
		formData.append("country", modifyDetailsData.per_country);
		formData.append("pincode", modifyDetailsData.per_pincode);
		formData.append("phone", modifyDetailsData.per_phone_number);
		formData.append("add3", modifyDetailsData.tem_address1);
		formData.append("add4", modifyDetailsData.tem_address2);
		formData.append("city2", modifyDetailsData.tem_city);
		formData.append("state2", modifyDetailsData.tem_state);
		formData.append("country2", modifyDetailsData.tem_country);
		formData.append("pincode2", modifyDetailsData.tem_pincode);
		formData.append("phone2", modifyDetailsData.tem_phone_number);
		formData.append("brand", modifyDetailsData.computer_brand);
		formData.append("ram", modifyDetailsData.computer_ram);
		formData.append("processor", modifyDetailsData.computer_processor);
		formData.append("asset", modifyDetailsData.asset);

		// console.log("formData values:");
		// for (let pair of formData.entries()) {
		// 	console.log(pair[0], pair[1]);
		// }

		try {
			const res = await ApiCallTwo(MYDETAILSDATA, "POST", authTokens, formData);
			fetchUserProfileData();
			if (res.status === 200) {
				SweetAlert({ title: "Changes Updated", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry! Something went wrong", icon: "error" });
			}
		} catch (error) {
			console.log("Error While Sending Data", error);
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			<div className="w-full intro-x bg-blue-800 justify-center flex mt-0 rounded-t-3xl items-center py-3">
				<h4 className="table-header-text text-center font-bold text-sm md:text-lg">
					My Details
				</h4>
			</div>
			{loading ? (
				<div className="w-full h-[70vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<UserDetailsForm
						handleFormSubmit={handleFormSubmit}
						handleDataChange={handleDataChange}
						modifyDetailsData={modifyDetailsData}
						handleImage={handleImage}
						profilePic={profilePic}
						handleRemovePng={handleRemovePng}
						handleCheckBox={handleCheckBox}
						isChecked={isChecked}
					/>
				</>
			)}
		</div>
	);
};

export default UserDetails;
