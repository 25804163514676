import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { OrgChart } from "../../components";
import { TEAMCHART } from "../../Constants";
import "../../css/orgchart.css";
import ApiCall from "../../utils/ApiCall";
import Loader from "../../components";

const OurOrganizationChart = () => {
  const { authTokens } = useContext(AuthContext);
  const [orgChartData, setOrgChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
  	//console.log("api calling");
  	fetchData();
  }, []);

  const fetchData = async () => {
  	try {
  		const response = await ApiCall(TEAMCHART, "GET", authTokens, null);
  		setOrgChartData(response.data.user_org);
  		setIsLoading(false);
  		//console.log("response", response.data.user_org);
  		// console.log("orgchart",response)
  	} catch (error) {
  		console.log("error while fetching orgchart data", error);
  		setIsLoading(false);
  	}
  };
  
  const Organizationchart = (orgChartData) => {
    //console.log("orgchart", orgChartData);

    if (orgChartData.length > 0) {
      return orgChartData.map((item, index) => (
        <OrgChart
          key={index}
          title={item.title}
          name={item.name}
          avatar={item.avatar}
        >
          {item.children && Organizationchart(item.children)}
        </OrgChart>
      ));
    } else {
      return <h2>No Data</h2>;
    }
  };
  
  return (
    <div className="bg-accent w-auto box-shadow flex gap-1 justify-center items-center rounded-lg my-6 mx-6  teamchart-card">
    {/* {isLoading ? (
      <div className="w-full h-[70vh] flex items-center justify-center">
        <Loader />
      </div>
    ) : ( */}
      <div className="tree-container">
        <div className="tree">
          <ul>{Organizationchart([orgChartData])}</ul>
        </div>
      </div>
    {/* )} */}
  </div>
  );
};

export default OurOrganizationChart
