import React from "react";
import { MdTableRows } from "react-icons/md";

const NavButton = ({ click }) => {
	return (
		<button
			onClick={click}
			className="rounded-3xl text-lg text-navBtn toggle-btn p-3 cursor-pointer"
		>
			<MdTableRows />
		</button>
	);
};

export default NavButton;
