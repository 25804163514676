import React from "react";

const NumberBox = ({ onChange, value, style, disabled, required }) => {
	return (
		<input
			type="number"
			onChange={onChange}
			value={value}
			className={`${style} bg-transparent border p-2 text-sm rounded-md border-gray-300 cursor-pointer`}
			disabled={disabled}
			required={required}
		/>
	);
};

export default NumberBox;
