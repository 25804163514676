import React from "react";
import ProfileLabel from "./ProfileLabel";

const FormRow = ({
	item,
	label,
	type,
	name,
	onChange,
	placeholder,
	disabled,
	formRowStyle,
}) => {
	return (
		<div
			className={`flex w-full p-2 gap-1 items-center px-4 intro-x ${formRowStyle}`}
		>
			<ProfileLabel lableStyle="text-sm w-full" label={label} />
			{item}
		</div>
	);
};

export default FormRow;
