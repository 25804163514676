import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { BASE_URL, LOGIN, REFRESH } from "../Constants";
import SweetAlert from "../utils/SweetAlert";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
	const [authTokens, setAuthTokens] = useState(() =>
		localStorage.getItem("authTokens")
			? JSON.parse(localStorage.getItem("authTokens"))
			: null
	);
	const [user, setUser] = useState(() =>
		localStorage.getItem("authTokens")
			? jwt_decode(localStorage.getItem("authTokens"))
			: null
	);
	const [loading, setLoading] = useState(true);
	const [isClicked, setIsClicked] = useState(false);

	const history = useHistory();

	const loginUser = async (e) => {
		e.preventDefault();
		const response = await fetch(BASE_URL + LOGIN, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: e.target.email.value,
				password: e.target.password.value,
			}),
		});
		const data = await response.json();

		if (response.status === 200) {
			setAuthTokens(data);
			setUser(jwt_decode(data.access));
			localStorage.setItem("authTokens", JSON.stringify(data));
			history.push("/");
		} else {
			// alert("Something went wrong!");
			SweetAlert({ title: "Something went wrong", icon: "error" });
		}
	};

	const handleClick = () => {
		setIsClicked(!isClicked);
	};

	const logoutUser = () => {
		setAuthTokens(null);
		setUser(null);
		localStorage.removeItem("authTokens");
		setIsClicked(!isClicked);
		history.push("/login");
	};

	const updateToken = async () => {
		const response = await fetch(BASE_URL + REFRESH, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ refresh: authTokens?.refresh }),
		});

		const data = await response.json();

		if (response.status === 200) {
			setAuthTokens(data);
			setUser(jwt_decode(data.access));
			localStorage.setItem("authTokens", JSON.stringify(data));
		} else {
			logoutUser();
		}

		if (loading) {
			setLoading(false);
		}
	};

	const contextData = {
		user: user,
		authTokens: authTokens,
		loginUser: loginUser,
		logoutUser: logoutUser,
		isClicked: isClicked,
		setIsClicked: setIsClicked,
		handleClick: handleClick,
	};

	useEffect(() => {
		if (loading) {
			updateToken();
		}

		const fourMinutes = 1000 * 60 * 4;

		const interval = setInterval(() => {
			if (authTokens) {
				updateToken();
			}
		}, fourMinutes);
		return () => clearInterval(interval);
	}, [authTokens, loading]);

	return (
		<AuthContext.Provider value={contextData}>
			{loading ? null : children}
		</AuthContext.Provider>
	);
};
