import React from "react";
import Button from "./Button";
import ProfileLabel from "./ProfileLabel";

const LmsForm = ({
	onSubmit,
	labelOne,
	itemOne,
	labelTwo,
	itemTwo,
	labelThree,
	itemThree,
	labelFour,
	itemFour,
	labelFive,
	itemFive,
	labelSix,
	itemSix,
	Submit,
	style,
}) => {
	return (
		<form
			className="flex flex-col text-center md:text-left md:justify-between w-5/6 justify-center"
			onSubmit={onSubmit}
		>
			<div className={`flex flex-col md:flex-row md:gap-32 ${style}`}>
				<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
					<ProfileLabel lableStyle="text-xs" label={labelOne} />
					{itemOne}
				</div>
				<div className="w-full md:w-1/2 px-3">
					<ProfileLabel lableStyle="text-xs" label={labelTwo} />
					{itemTwo}
				</div>
			</div>
			<div className={`flex flex-col md:flex-row md:gap-32 ${style}`}>
				<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
					<ProfileLabel lableStyle="text-xs" label={labelThree} />
					{itemThree}
				</div>
				<div className="w-full md:w-1/2 px-3">
					<ProfileLabel lableStyle="text-xs" label={labelFour} />
					{itemFour}
				</div>
			</div>

			<div className={`flex flex-col md:flex-row md:gap-32 ${style}`}>
				<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
					<ProfileLabel lableStyle="text-xs" label={labelFive} />
					{itemFive}
				</div>
				<div className="w-full md:w-1/2 px-3">
					<ProfileLabel lableStyle="text-xs" label={labelSix} />
					{itemSix}
				</div>
			</div>

			<div className="flex flex-wrap -mx-3 mb-2 justify-center">
				<div className="w-full flex items-center justify-center">
					<Button classname="w-auto text-white text-sm font-semibold py-2 rounded-md px-6 bg-button">
						{Submit}
					</Button>
				</div>
			</div>
		</form>
	);
};

export default LmsForm;
