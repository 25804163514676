import React, { useState, useContext } from "react";
import LoginImg from "../images/loginbg.jpg";
import AuthContext from "../contexts/AuthContext";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";

const UserLogin = () => {
	const { loginUser } = useContext(AuthContext);
	const [passwordVisible, setPasswordVisible] = useState(false);

	const handlePasswordKeyDown = (e) => {
		if (e.key === "Space") {
			e.preventDefault();
		}
	};

	return (
		<div className="flex  items-center justify-center bg-loginBg py-4 sm:py-8 h-screen">
			<div className="max-w-xl mx-auto overflow-hidden rounded-md bg-white shadow ">
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<div className="relative ">
						<div className="absolute inset-0 bg-gray-800/70"></div>
						<img
							className="h-48 sm:h-96 w-full object-cover sm:max-h-full sm:max-w-full responsive-image"
							src={LoginImg}
							alt="Login Background"
						/>
						<div className="absolute inset-0  flex items-center justify-center flex-col text-center text-white">
							<h1 className="text-3xl font-semibold mb-4">Sign In</h1>
							<p className="text-base pl-1">
								By signing in, you can avail full features of the ESS.
							</p>
							<Link
								to="/forgot-password"
								className="text-md pt-4 underline underline-offset-2"
							>
								Forgot your password?
							</Link>
							Recover Now
						</div>
					</div>
					<div className="sm:px-6">
						<div className="flex flex-col justify-center h-full py-6">
							<form className="w-full space-y-4" onSubmit={loginUser}>
								<div className="mb-4">
									<label
										className="block text-black text-md font-bold mb-2"
										htmlFor="Email"
									>
										Email
									</label>
									<input
										className="bg-white shadow appearance-none border rounded-lg  py-2 px-3 sm:py-3 sm:px-4 h-12 sm:h-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
										id="email"
										name="email"
										placeholder="Email"
										type="email"
									/>
								</div>
								<div className="mb-6 pt-4 relative">
									<label
										className="block text-black text-md font-bold mb-2"
										htmlFor="password"
									>
										Password
									</label>
									<div className="relative">
										<input
											className="bg-white shadow appearance-none border rounded-lg  h-12 sm:h-14 py-2 px-3 sm:py-3 sm:px-4 tet-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline w-full pr-12"
											id="password"
											type={passwordVisible ? "text" : "password"}
											name="password"
											placeholder="Password"
											onKeyDown={handlePasswordKeyDown}
										/>
										<span
											className="absolute right-2 top-4 cursor-pointer"
											onClick={() => setPasswordVisible(!passwordVisible)}
										>
											{passwordVisible ? (
												<AiOutlineEyeInvisible size={20} />
											) : (
												<AiOutlineEye size={20} />
											)}
										</span>
									</div>
								</div>
								<div className="pt-3 flex justify-center">
									<button
										className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline w-full sm:py-3 sm:px-4"
										type="submit"
									>
										Sign In
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserLogin;
