import React from "react";
import { MdLogout } from "react-icons/md";
import { motion } from "framer-motion";

const UserProfile = ({ onClick }) => {
	return (
		<motion.div
			whileTap={{ scale: 0.9 }}
			onClick={onClick}
			className="mt-0 z-50 user-logout bg-white rounded-sm flex justify-start items-center h-10 w-56 p-2 cursor-pointer select-none"
		>
			<MdLogout />
			<span className="ml-4">Logout</span>
		</motion.div>
	);
};

export default UserProfile;
