import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";

const TablePagination = ({ body, rowPerPage, currentPage, onPageChange }) => {
	const [currentPageState, setCurrentPageState] = useState(currentPage);

	useEffect(() => {
		setCurrentPageState(currentPage);
	}, [currentPage]);

	const handlePageChange = (pageNumber) => {
		if (pageNumber !== currentPageState) {
			setCurrentPageState(pageNumber);
			onPageChange(pageNumber);
		}
	};

	const isCurrentPageEmpty = () => {
		const startIndex = (currentPageState - 1) * rowPerPage;
		const endIndex = startIndex + rowPerPage;
		return body.slice(startIndex, endIndex).length === 0;
	};
	//console.log("isCurrentPageEmpty", isCurrentPageEmpty);

	if (isCurrentPageEmpty() && currentPageState !== 1) {
		setCurrentPageState(1);
		onPageChange(1);
	}

	return (
		<>
			{body.length > rowPerPage && (
				<div className="mt-4 flex transition-all ease-in-out intro-x justify-center">
					<Pagination
						activePage={currentPageState}
						itemsCountPerPage={rowPerPage}
						totalItemsCount={body.length}
						pageRangeDisplayed={5}
						onChange={handlePageChange}
						itemClass="px-2 md:px-3 py-0 md:py-1 rounded-sm md:rounded-lg mr-[2px] md:mr-2 text-[10px] text-secondary"
						activeClass="bg-[#1E3A8A] active border-none px-3 py-1 rounded-lg mr-2 text-[#fff!important]"
						prevPageText="Previous"
						nextPageText="Next"
						innerClass="flex cursor-pointer text-center"
					/>
				</div>
			)}
		</>
	);
};

export default TablePagination;
