
import React, {useContext} from "react";
import { Layout, Sidebar } from "../components";
import { Route ,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import AddHolidays from "../sub-pages/AdminPortalPages/AddHolidays" ;
import Announcement from "../sub-pages/AdminPortalPages/Announcement";
import HolidayCalendar from "../sub-pages/AdminPortalPages/HolidayCalendar";
import ModifiedHoliday from "../sub-pages/AdminPortalPages/ModifiedHoliday";
import PolicyUpdate from "../sub-pages/AdminPortalPages/PolicyUpdate";
import TrainingMaterial from "../sub-pages/AdminPortalPages/TrainingMaterial";
import AuthContext from "../contexts/AuthContext";
import { AdminItems } from "../data";

const AdminPage = () => {
    const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}

    return(
        <>
               {user.user_role === "ADMIN" && (
				<Layout sidebar={<Sidebar sidebarLinks={AdminItems} />}>
					<Route path="/admin/policy-update" component={PolicyUpdate} />
					<Route path="/admin/training-materials-upload" component={ TrainingMaterial} />
					<Route path="/admin/holidaycalendar" component={HolidayCalendar}/>
					<Route path="/admin/modifiedholiday" component={ModifiedHoliday }/>
					<Route path="/admin/add-holiday" component={AddHolidays} />
                    <Route path="/admin/announcement" component={Announcement} />
				</Layout>
			   )}
        </>
    )
}

export default AdminPage;