import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import { Layout, Sidebar } from "../components";
import { ConferenceRoomItems } from "../data";
import ConDashboard from "../sub-pages/ConferenceRoomPages/ConDashboard";
import ConHistory from "../sub-pages/ConferenceRoomPages/ConHistory";

const ConferenceRoomPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<>
			{user.user_role === "EMPLOYEE" ||
			user.user_role === "INTERN" ||
			user.user_role === "CONSULTANT" ||
			user.user_role === "MANAGER" ||
			user.user_role === "HR" ||
			user.user_role === "CEO" ||
			user.user_role === "ADMIN" ? (
				<Layout sidebar={<Sidebar sidebarLinks={ConferenceRoomItems} />}>
					<Route path="/conference-room/dashboard" component={ConDashboard} />
					<Route path="/conference-room/history" component={ConHistory} />
				</Layout>
			) : null}
		</>
	);
};

export default ConferenceRoomPage;
