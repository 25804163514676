import React, { useEffect, useState } from "react";

const PdfViewer = ({ pdfUrl }) => {
	return (
		<div className="container intro-y box-shadow w-full md:w-[90%] rounded-xl bg-accent flex justify-center items-center mx-auto p-2">
			{pdfUrl ? (
				<object
					data={pdfUrl}
					type="application/pdf"
					width="100%"
					height="650px"
				>
					<p>PDF cannot be displayed</p>
				</object>
			) : (
				<p>Loading PDF...</p>
			)}
		</div>
	);
};

export default PdfViewer;
