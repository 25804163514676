import React, { useState } from "react";
import Button from "./Button";
import TablePagination from "./TablePagination";
import InputBox from "./InputBox";

const Table = ({
	header,
	body,
	btnTitle,
	btnstyle,
	onClick,
	onClickTwo,
	btnTitleTwo,
	btnstyleTwo,
	// pageRows,
	// paginationNeed,
	bodyStyle,
	tableStyle,
	bodyTdStyle,
	headTdStyle,
	currentPage,
	rowPerPage,
	paginationNeed,
	paginationBody,
	onPageChange,
	btnRadio,
	radioName,
	radioChange,
	radioChecked,
	tdonClick,
	btndisTwo,
	headStyle,
	buttonStyleOne,
	buttonStyleTwo,
	btnTitleTwoName,
	buttonStyleOneData,
}) => {
	const indexOfLastRow = currentPage * rowPerPage;
	const indexOfFirstRow = indexOfLastRow - rowPerPage;
	const currentRows = body.slice(indexOfFirstRow, indexOfLastRow);
	return (
		<div className="w-full md:overflow-x-hidden overflow-x-auto py-4">
			<table className={`w-full text-sm text-left ${tableStyle}`}>
				<thead className="text-xs uppercase">
					<tr className={`${headStyle}`}>
						{header &&
							header.map((item, index) => (
								<th
									className={`px-6 py-3 text-md md:text-[0.9rem] ${headTdStyle}`}
									key={index}
								>
									{item}
								</th>
							))}
					</tr>
				</thead>
				<tbody className="p-2">
					{paginationNeed === true
						? currentRows &&
						  currentRows.map((row, rowIndex) => (
								<tr
									className={`transition ease-out cursor-pointer ${bodyStyle}`}
									key={rowIndex}
								>
									{Object.values(row).map((cell, cellIndex) => (
										<td
											key={cellIndex}
											className={`px-6 py-3 ${bodyTdStyle}`}
											onClick={() =>
												tdonClick && tdonClick(rowIndex, cellIndex)
											}
										>
											{cell}
										</td>
									))}
									{btnTitle && (
										<td className={`px-6 py-3 cursor-pointer ${btnstyle}`}>
											<Button
												onClick={() => onClick && onClick(rowIndex)}
												classname="bg-button text-white px-3 py-1 rounded-sm"
											>
												{btnTitle}
											</Button>
										</td>
									)}
									{btnTitleTwo && (
										<td className={`px-6 py-3 cursor-pointer ${btnstyleTwo}`}>
											<Button
												onClick={() => onClickTwo && onClickTwo(rowIndex)}
												classname={` text-white px-3 py-1 rounded-sm ${
													buttonStyleTwo(rowIndex)
														? "bg-button-three"
														: "bg-button-two"
												}`}
											>
												{btnTitleTwoName ? btnTitleTwo(rowIndex) : btnTitleTwo}
											</Button>
										</td>
									)}
									{btnRadio && (
										<td className="px-6 py-3">
											<InputBox
												type="radio"
												name={radioName}
												onChange={() => radioChange(rowIndex)}
												style="cursor-pointer"
											/>
										</td>
									)}
								</tr>
						  ))
						: body &&
						  body.map((row, rowIndex) => (
								<tr
									className={`transition capitalize ease-out cursor-pointer ${bodyStyle}`}
									key={rowIndex}
								>
									{Object.values(row).map((cell, cellIndex) => (
										<td key={cellIndex} className={`px-6 py-3 ${bodyTdStyle}`}>
											{cell}
										</td>
									))}
									{btnTitle && (
										<td className={`px-6 py-3 cursor-pointer ${btnstyle}`}>
											<Button
												onClick={() => onClick && onClick(rowIndex)}
												classname={` text-white px-3 py-1 rounded-sm ${
													buttonStyleOneData
														? buttonStyleOne(rowIndex)
															? "bg-button-two"
															: "bg-button"
														: buttonStyleOne
														? "bg-button"
														: "bg-button"
												}`}
											>
												{btnTitle}
											</Button>
										</td>
									)}
									{btnTitleTwo && (
										<td className={`px-6 py-3 cursor-pointer ${btnstyleTwo}`}>
											<Button
												onClick={() => onClickTwo && onClickTwo(rowIndex)}
												classname={` text-white px-3 py-1 rounded-sm ${
													buttonStyleTwo(rowIndex)
														? "bg-button-three"
														: "bg-button-two"
												}`}
											>
												{btnTitleTwoName ? btnTitleTwo(rowIndex) : btnTitleTwo}
											</Button>
										</td>
									)}
									{btnRadio && (
										<td className="px-6 py-3">
											<InputBox
												type="radio"
												name={radioName}
												onChange={() => radioChange(rowIndex)}
												// checked={radioChecked === row.id}
												style="cursor-pointer"
											/>
										</td>
									)}
								</tr>
						  ))}
				</tbody>
			</table>
			{paginationNeed === true ? (
				<TablePagination
					body={paginationBody}
					rowPerPage={rowPerPage}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			) : null}
		</div>
	);
};

export default Table;
