import React from "react";
import ModfifyUserForm from "./ModfifyUserForm";
import InputFile from "./InputFile";
import InputBox from "./InputBox";
import Dropdown from "./Dropdown";
import { MyDetailsAsset } from "../data";

const UserDetailsForm = ({
	handleImage,
	handleRemovePng,
	profilePic,
	handleDataChange,
	modifyDetailsData,
	handleFormSubmit,
	handleCheckBox,
	isChecked,
}) => {
	return (
		<ModfifyUserForm
			onSubmit={handleFormSubmit}
			submit="Submit"
			labelEighteen="USER PROFILE"
			headingTwo="Present Address"
			headingThree="Permanent Address"
			itemEighteen={
				<InputFile
					onChange={handleImage}
					cancelBtn={handleRemovePng}
					userImg={profilePic}
					accept=".jpg,.png,.jpeg,.svg,.gif"
				/>
			}
			styleOne="p-4"
			labelOne="Employee Name"
			itemOne={
				<InputBox
					value={modifyDetailsData.name}
					onChange={(e) => handleDataChange(e, "name")}
					disabled={true}
					type="text"
					style="w-full"
				/>
			}
			labelTwo="Employee Id"
			itemTwo={
				<InputBox
					value={modifyDetailsData.employee_id}
					disabled={true}
					type="text"
					style="w-full"
				/>
			}
			labelThree="E-Mail Address"
			itemThree={
				<InputBox
					value={modifyDetailsData.email}
					disabled={true}
					type="email"
					style="w-full"
				/>
			}
			labelFour="Designation"
			itemFour={
				<InputBox
					value={modifyDetailsData.designation}
					disabled={true}
					type="text"
					style="w-full"
				/>
			}
			labelFive="Dob"
			itemFive={
				<InputBox
					value={modifyDetailsData.dob}
					disabled={true}
					type="text"
					style="w-full"
				/>
			}
			labelSix="Doj"
			itemSix={
				<InputBox
					value={modifyDetailsData.doj}
					disabled={true}
					type="text"
					style="w-full"
				/>
			}
			labelNineteen="Asset"
			itemNineteen={
				<Dropdown
					value={modifyDetailsData.asset}
					onChange={(event) => handleDataChange(event, "asset")}
					options={MyDetailsAsset}
					style="w-full"
				/>
			}
			labelTwenty="Personal Email"
			itemTwenty={
				<InputBox
					type="email"
					value={modifyDetailsData.personal_email}
					onChange={(event) => handleDataChange(event, "personal_email")}
					style="w-full"
				/>
			}
			styleTwo="pb-4"
			labelSeven="Address Line1"
			itemSeven={
				<InputBox
					type="text"
					value={modifyDetailsData.tem_address1}
					onChange={(event) => handleDataChange(event, "tem_address1")}
					style="w-full"
				/>
			}
			labelEight="Address Line2"
			itemEight={
				<InputBox
					type="text"
					value={modifyDetailsData.tem_address2}
					onChange={(event) => handleDataChange(event, "tem_address2")}
					style="w-full"
				/>
			}
			labelNine="City"
			itemNine={
				<InputBox
					type="text"
					value={modifyDetailsData.tem_city}
					onChange={(event) => handleDataChange(event, "tem_city")}
					style="w-full"
				/>
			}
			labelTen="State"
			itemTen={
				<InputBox
					type="text"
					value={modifyDetailsData.tem_state}
					onChange={(event) => handleDataChange(event, "tem_state")}
					style="w-full"
				/>
			}
			labelEleven="Country"
			itemEleven={
				<InputBox
					type="text"
					value={modifyDetailsData.tem_country}
					onChange={(event) => handleDataChange(event, "tem_country")}
					style="w-full"
				/>
			}
			labelTwelve="Postal Code"
			itemTwelve={
				<InputBox
					type="number"
					value={modifyDetailsData.tem_pincode}
					onChange={(event) => handleDataChange(event, "tem_pincode")}
					style="w-full"
				/>
			}
			ltwoExtraOne="Phone No"
			itwoExtraOne={
				<InputBox
					type="number"
					value={modifyDetailsData.tem_phone_number}
					onChange={(event) => handleDataChange(event, "tem_phone_number")}
					style="w-full"
				/>
			}
			styleThree="pb-4"
			labelThirteen="Address Line1"
			itemThirteen={
				<InputBox
					type="text"
					value={
						isChecked === true
							? modifyDetailsData.tem_address1
							: modifyDetailsData.per_address1
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_address1" : "per_address1"
						)
					}
					style="w-full"
				/>
			}
			labelFourteen="Address Line2"
			itemFourteen={
				<InputBox
					type="text"
					value={
						isChecked === true
							? modifyDetailsData.tem_address2
							: modifyDetailsData.per_address2
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_address2" : "per_address2"
						)
					}
					style="w-full"
				/>
			}
			labelFifteen="City"
			itemFifteen={
				<InputBox
					type="text"
					value={
						isChecked === true
							? modifyDetailsData.tem_city
							: modifyDetailsData.per_city
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_city" : "per_city"
						)
					}
					style="w-full"
				/>
			}
			labelSixteen="State"
			itemSixteen={
				<InputBox
					type="text"
					value={
						isChecked === true
							? modifyDetailsData.tem_state
							: modifyDetailsData.per_state
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_state" : "per_state"
						)
					}
					style="w-full"
				/>
			}
			labelSeventeen="Country"
			itemSeventeen={
				<InputBox
					type="text"
					value={
						isChecked === true
							? modifyDetailsData.tem_country
							: modifyDetailsData.per_country
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_country" : "per_country"
						)
					}
					style="w-full"
				/>
			}
			ltthreeExtraOne="Postal Code"
			ithreeExtraOne={
				<InputBox
					type="number"
					value={
						isChecked === true
							? modifyDetailsData.tem_pincode
							: modifyDetailsData.per_pincode
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_pincode" : "per_pincode"
						)
					}
					style="w-full"
				/>
			}
			ltthreeExtraTwo="Phone No"
			ithreeExtraTwo={
				<InputBox
					type="number"
					value={
						isChecked === true
							? modifyDetailsData.tem_phone_number
							: modifyDetailsData.per_phone_number
					}
					onChange={(event) =>
						handleDataChange(
							event,
							isChecked === true ? "tem_phone_number" : "per_phone_number"
						)
					}
					style="w-full"
				/>
			}
			checkBox={true}
			checkBoxValue={isChecked}
			disabled={false}
			checkBoxChange={handleCheckBox}
		/>
	);
};

export default UserDetailsForm;
