import React from "react";
import ReactApexChart from 'react-apexcharts';

const PieCharts = ({data,datas}) => {
 
  const chartData = {
    series: datas,
    options: {
      chart: {
        type: 'donut',
        height : 250,
      },
      responsive: [
        {
          breakpoint: 0,
          options: {
            // chart: {
            //   width: '100%',
            // },
            legend: {
              position: 'bottom',
            },
            dataLabels:{
              style:{
                fontSize: '15px',
                // colors: ['#FF6384', '#36A2EB', '#FFCE56', '#66BB6A'],
              },
            },
          },
        },

        {
          breakpoint: 480,
          options:{
            dataLabels :{
              // chart: {
              //   width:200
              // },
              style:{
                fontSize: '9px',

              },
            },
          },
        },
      ],
      labels: data,
      legend: {
        labels: {
          colors: ['#008FFB','#00E396','#00E396','#00E396'], 
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart 
        options={chartData.options}
        series={chartData.series}
        type="donut"
      />
    </div>
  );
};



export default PieCharts;
