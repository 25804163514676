import React from "react";
import FormRow from "./FormRow";
import Button from "./Button";
import InputBox from "./InputBox";

const ModfifyUserForm = ({
	onSubmit,
	onChange,
	itemOne,
	submit,
	labelOne,
	labelTwo,
	itemTwo,
	labelThree,
	itemThree,
	labelFour,
	itemFour,
	labelFive,
	itemFive,
	labelSix,
	itemSix,
	labelSeven,
	itemSeven,
	labelEight,
	itemEight,
	labelNine,
	itemNine,
	labelTen,
	itemTen,
	labelEleven,
	itemEleven,
	labelTwelve,
	itemTwelve,
	labelThirteen,
	itemThirteen,
	labelFourteen,
	itemFourteen,
	labelFifteen,
	itemFifteen,
	labelSixteen,
	itemSixteen,
	labelSeventeen,
	itemSeventeen,
	labelEighteen,
	itemEighteen,
	ltwoExtraOne,
	lthreeExtraThree,
	itwoExtraOne,
	ltwoExtraTwo,
	ithreeExtraThree,
	itwoExtraTwo,
	ltthreeExtraOne,
	ithreeExtraOne,
	ltthreeExtraTwo,
	ithreeExtraTwo,
	headingOne,
	headingTwo,
	headingThree,
	styleOne,
	styleTwo,
	styleThree,
	checkBox,
	checkBoxValue,
	checkBoxChange,
	itemNineteen,
	labelNineteen,
	labelTwenty,
	itemTwenty,
	disabled,
}) => {
	return (
		<form className="mt-4" onSubmit={onSubmit}>
			<div className="grid grid-cols-1 md:grid-cols-2 py-2 gap-4">
				<div className="flex flex-col px-6 pb-6 bg-accent justify-center ox-shadow rounded-2xl">
					<p className="text-md font-semibold px-2 mb-4 mt-2 text-center intro-x">
						{labelEighteen}
					</p>
					{itemEighteen}
				</div>
				<div
					className={`flex w-full flex-col bg-accent items-center justify-center box-shadow rounded-2xl ${styleOne}`}
				>
					{headingOne ? (
						<div className="w-full p-4 border-b-[nav-item-active]">
							<h3 className="text-md font-semibold">{headingOne}</h3>
						</div>
					) : null}
					<FormRow label={labelOne} item={itemOne} />
					<FormRow label={labelTwo} item={itemTwo} />
					<FormRow label={labelThree} item={itemThree} />
					<FormRow label={labelFour} item={itemFour} />
					{itemFive && <FormRow label={labelFive} item={itemFive} />}
					{itemSix && <FormRow label={labelSix} item={itemSix} />}
					{itemNineteen && (
						<FormRow label={labelNineteen} item={itemNineteen} />
					)}
					{itemTwenty && <FormRow label={labelTwenty} item={itemTwenty} />}
				</div>
				<div
					className={`flex w-full flex-col bg-accent items-center box-shadow rounded-2xl ${styleTwo}`}
				>
					{headingTwo ? (
						<div className="w-full px-4 py-3 bg-blue-900 mb-2 rounded-t-2xl">
							<h3 className="text-md font-semibold text-white">{headingTwo}</h3>
						</div>
					) : null}
					<FormRow label={labelSeven} item={itemSeven} />
					<FormRow label={labelEight} item={itemEight} />
					<FormRow label={labelNine} item={itemNine} />
					<FormRow label={labelTen} item={itemTen} />
					<FormRow label={labelEleven} item={itemEleven} />
					<FormRow label={labelTwelve} item={itemTwelve} />
					{itwoExtraOne && <FormRow label={ltwoExtraOne} item={itwoExtraOne} />}
					{itwoExtraTwo && <FormRow label={ltwoExtraTwo} item={itwoExtraTwo} />}
				</div>
				<div
					className={`flex w-full flex-col bg-accent items-center box-shadow rounded-2xl ${styleThree}`}
				>
					{headingThree ? (
						<div className="w-full flex justify-between px-4 py-3 bg-blue-900 mb-2 rounded-t-2xl">
							<h3 className="text-md font-semibold text-white w-full">
								{headingThree}
							</h3>
							{checkBox ? (
								<div className="w-full flex items-center justify-center gap-2">
									<input
										onChange={checkBoxChange}
										value={checkBoxValue}
										disabled={disabled}
										type="checkbox"
										className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
									/>
									<h3 className="text-white">Same as present address</h3>
								</div>
							) : null}
						</div>
					) : null}
					<FormRow label={labelThirteen} item={itemThirteen} />
					<FormRow label={labelFourteen} item={itemFourteen} />
					<FormRow label={labelFifteen} item={itemFifteen} />
					<FormRow label={labelSixteen} item={itemSixteen} />
					{itemSeventeen && (
						<FormRow label={labelSeventeen} item={itemSeventeen} />
					)}
					{ithreeExtraOne && (
						<FormRow label={ltthreeExtraOne} item={ithreeExtraOne} />
					)}
					
					{ithreeExtraThree && (
						<FormRow label={lthreeExtraThree} item={ithreeExtraThree} />
					)}
					{ithreeExtraTwo && (
						<FormRow label={ltthreeExtraTwo} item={ithreeExtraTwo} />
					)}
				</div>
			</div>
			<div className="w-full flex-col gap-4 flex items-center justify-center text-center my-4">
				{submit ? (
					<Button classname="bg-button text-white text-md px-4 py-2 rounded-lg">
						{submit}
					</Button>
				) : null}
			</div>
		</form>
	);
};

export default ModfifyUserForm;
