import React from "react";

const ProfileLabel = ({ label, lableStyle }) => {
	return (
		<label
			className={`block uppercase tracking-wide font-bold mb-2 mx-1 ${lableStyle}`}
		>
			{label}
		</label>
	);
};

export default ProfileLabel;
