import React from "react";
import "../css/popupmenu.css";
import { MdClose } from "react-icons/md";

const PopupHeader = ({ handleClose, title, popupstyle }) => {
	return (
		<div className="header flex items-center justify-center">
			<h4 className="text-center text-white capitalize">{title}</h4>
			<button
				className="close-btn rounded-3xl bg-accent flex justify-center items-center text-xl text-navBtn toggle-btn p-3 cursor-pointer"
				onClick={handleClose}
			>
				<MdClose className="text-lg font-bold" />
			</button>
		</div>
	);
};

const Popup = ({ trigger, setTrigger, children, title, popupstyle }) => {
	const handleClose = () => {
		setTrigger(null);
	};

	return trigger ? (
		<div className="popup">
			<div className={`popup-inner bg-accent ${popupstyle}`}>
				<PopupHeader title={title} handleClose={handleClose} />
				<div className="content">{children}</div>
			</div>
		</div>
	) : null;
};

export default Popup;
