import React, { useContext, useEffect, useState } from "react";
import {
	InputBox,
	Button,
	Popup,
	LmsLeaveDetails,
	Loader,
} from "../../components";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import SweetAlert from "../../utils/SweetAlert";
import { USERSKILLS } from "../../Constants";
import NoDataImg from "../../images/no_data.svg";

const UserSkillSet = () => {
	const { authTokens } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [popupData, setPopupData] = useState(null);
	const [primary, setPrimary] = useState("");
	const [secondary, setSecondary] = useState("");

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(USERSKILLS, "GET", authTokens, null);
			//console.log("res", res.data);
			setPrimary(res.data.primary_skill);
			setSecondary(res.data.secondary_skil);
			setData(res.data);
			setLoading(false);
		} catch (error) {
			console.log("Error While Fetching Api Data", error);
			setLoading(false);
		}
	};

	const hadlePopup = () => {
		setPopupData(data);
	};

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSkillData = async (e) => {
		e.preventDefault();
		const params = {
			primary: data.primary_skill,
			secondary: data.secondary_skil,
		};
		//console.log("params", params);
		try {
			const res = await ApiCall(USERSKILLS, "POST", authTokens, params);
			if (res.status === 200) {
				SweetAlert({ title: "Skills Updated", icon: "success" });
			} else {
				SweetAlert({ title: "Sorry! Something went wrong", icon: "error" });
			}
			setPopupData(null);
			fetchData();
		} catch (error) {
			console.log("Error While Sending Api Data", error);
		}
	};

	let primaryList = [];
	let secondaryList = [];

	if (primary.length > 0) {
		primaryList =
			primary && primary.split(",").map((primary) => primary.trim());
	}

	if (secondary.length > 0) {
		secondaryList =
			secondary && secondary.split(",").map((secondary) => secondary.trim());
	}

	//console.log("data", data);

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			{loading ? (
				<div className="w-full h-[60vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<h2 className="text-lg intro-x table-body-td px-2 font-semibold">
						My Skill Set
					</h2>
					<div className="flex md:flex-row flex-col gap-4 w-full mt-3">
						{primary.length > 0 || secondary.length > 0 ? (
							<>
								<div className="flex flex-col gap-3 flex-wrap md:w-2/5 w-full text-center">
									<h2 className="text-md uppercase font-bold intro-y">
										PRIMARY SKILLS
									</h2>
									<div className="flex flex-wrap gap-3 justify-center">
										{primaryList.map((items, index) => (
											<span
												key={index}
												className="bg-[rgb(30,64,175)] box-shadow p-2 intro-y text-center rounded-md text-white px-3 text-sm h-10"
											>
												{items}
											</span>
										))}
									</div>
								</div>
								<div className="flex flex-col gap-3 w-full md:w-2/5">
									<h2 className="text-md font-bold uppercase text-center intro-y">
										SECONDARY SKILLS
									</h2>
									<div className="flex flex-wrap gap-3 justify-center">
										{secondaryList.map((items, index) => (
											<span
												key={index}
												className="bg-[rgb(30,64,175)] box-shadow p-2 intro-y text-center rounded-md text-white px-3 text-sm h-10"
											>
												{items}
											</span>
										))}
									</div>
								</div>
							</>
						) : (
							<h3 className="w-full text-center flex flex-col justify-center items-center intro-x my-4">
								<img className="w-72 w- text-center" src={NoDataImg} />
								<p className="font-bold">
									Please add some skills by clicking Edit Skill button
								</p>
							</h3>
						)}
						<div className="md:w-1/5 w-full flex justify-center intro-y">
							<Button
								onClick={hadlePopup}
								classname="bg-[rgb(228,74,119)] box-shadow p-2 rounded-md text-white px-3 text-sm h-10"
							>
								Edit Skill
							</Button>
						</div>
					</div>

					{popupData && (
						<Popup
							title="Edit Your Skill Set"
							trigger={popupData}
							setTrigger={setPopupData}
							popupstyle="md:w-2/5 w-full"
						>
							<form
								className="flex flex-col justify-center my-4"
								onSubmit={handleSkillData}
							>
								<LmsLeaveDetails
									className="bg-[transparent!important] px-3"
									style="flex items-center intro-x"
									label="Primary Skills"
									item={
										<InputBox
											placeholder="example: Python, Java, Html..."
											style="w-full intro-x"
											value={data.primary_skill}
											name="primary_skill"
											onChange={handleChange}
											required={true}
										/>
									}
								/>
								<LmsLeaveDetails
									className="bg-[transparent!important] px-3"
									style="flex items-center intro-x"
									label="Secondary Skills"
									item={
										<InputBox
											placeholder="example: Python, Java, Html..."
											style="w-full intro-x"
											value={data.secondary_skil}
											name="secondary_skil"
											onChange={handleChange}
											//required={true}
										/>
									}
								/>
								<div className="flex justify-center mt-2 intro-y">
									<Button classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm h-10">
										Submit
									</Button>
								</div>
							</form>
						</Popup>
					)}
				</>
			)}
		</div>
	);
};

export default UserSkillSet;
