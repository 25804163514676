import React, { useState, useContext, useEffect } from "react";
import {
	EmployeeRecord,
	employeeTableHead,
	dropDownPagination,
} from "../../data";
import { Dropdown, Loader, Table } from "../../components";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ApiCall from "../../utils/ApiCall";
import AuthContext from "../../contexts/AuthContext";
import { HRTIMESHEETVIEW, HRUSERVIEW } from "../../Constants";
import SweetAlert from "../../utils/SweetAlert";
import NoDataImg from "../../images/no_data.svg";

const EmployeeDashboard = () => {
	const [pagination, setPagination] = useState(
		sessionStorage.getItem("emphr-dashboard-pagination-data")
			? JSON.parse(sessionStorage.getItem("emphr-dashboard-pagination-data"))
			: 10
	);
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const { authTokens } = useContext(AuthContext);
	const [employeeTableBody, setEmployeeTableBody] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const rowPerPage = 7;

	useEffect(() => {
		fetchData();
		const savedPagination = sessionStorage.getItem(
			"emphr-dashboard-pagination-data"
		);
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}
	}, [pagination]);

	const fetchData = async () => {
		try {
			const res = await ApiCall(
				`${HRTIMESHEETVIEW}?pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			// console.log("hhfkds", res.data);
			setEmployeeTableBody(res.data);
			setIsLoading(false);
		} catch (error) {
			console.log("Error fetching data", error);
			setIsLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		// console.log("current", currentPage);
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem(
			"emphr-dashboard-pagination-data",
			JSON.stringify(pageValue)
		);
	};

	const handleEditRow = async (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const id = employeeTableBody[actualIndex].id;
		const name = employeeTableBody[actualIndex].name;
		history.push({
			state: {
				userTimesheetEditId: id,
			},
		});
		// console.log("res id", id);
		try {
			const res = await ApiCall(
				`${HRUSERVIEW}?id=${id}`,
				"GET",
				authTokens,
				null
			);
			// console.log("edit res", res.data);
			history.push({
				pathname: "/timesheet/employee-timesheet",
				state: {
					userTimesheetEdit: res.data.user,
					userTimesheetEditId: id,
					userTimesheetName: name,
				},
			});

			if (res.status == 400) {
				SweetAlert({ title: res.data, icon: "error" });
			}
		} catch (error) {
			console.log("While Requesting data", error);
		}
	};
	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			{isLoading ? (
				<div className="flex justify-center items-center w-full h-[60vh]">
					<Loader />
				</div>
			) : (
				<div className="mt-10">
					{employeeTableBody.length > 0 ? (
						<>
							<div className="w-full flex justify-end items-end pr-10">
								<Dropdown
									style="px-4"
									value={pagination}
									onChange={paginatedData}
									options={dropDownPagination}
								/>
							</div>
							<Table
								tableStyle="border-spacing-y-[10px] border-separate -mt-4"
								bodyStyle="bg-accent box-shadow"
								header={employeeTableHead}
								body={employeeTableBody.map((row) => [
									row.id,
									row.name,
									row.email,
									row.role,
								])}
								bodyTdStyle="table-body-td intro-x"
								headTdStyle="table-body-td intro-x"
								btnTitle={<Link to="/timesheet/employee-timesheet">Edit</Link>}
								paginationNeed={true}
								btnstyle="intro-x"
								currentPage={currentPage}
								paginationBody={employeeTableBody}
								rowPerPage={rowPerPage}
								onPageChange={handlePageChange}
								onClick={handleEditRow}
							/>
						</>
					) : (
						<h3 className="text-center w-full flex flex-col justify-center items-center intro-x my-4">
							<img className="w-72 w- text-center" src={NoDataImg} />
							<p className="font-bold">No data</p>
						</h3>
					)}
				</div>
			)}
		</div>
	);
};

export default EmployeeDashboard;
