import React, { useEffect, useState, useContext } from "react";
import Button from "./Button";
import { FaDownload } from "react-icons/fa";
import { TRAINING, TRAININGPDF } from "../Constants";
import ApiCall from "../utils/ApiCall";
import ApiCallTwo from "../utils/ApiCallTwo";
import AuthContext from "../contexts/AuthContext";

const TrainingMaterial = () => {
	const { authTokens } = useContext(AuthContext);
	const [training, setTraining] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(TRAINING, "GET", authTokens, null);
			setTraining(res.data);
		} catch (error) {
			console.log("Error fetching data", error);
		}
	};

	const handleDownload = async (fileId, fileName) => {
		// console.log("res", fileId, fileName);
		try {
			const res = await ApiCallTwo(
				`${TRAININGPDF}?id=${fileId}`,
				"GET",
				authTokens,
				null
			);
			// console.log("res", res);

			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName + ".pdf");

			document.body.appendChild(link);
			link.click();
		} catch (error) {
			console.log("Error downloading file:", error);
		}
	};

	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<div className="box-shadow intro-x bg-accent transition flex flex-col items-center justify-center rounded-3xl w-auto sm:w-full pb-4">
				<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3 mb-4">
					<h3 className="mx-6 text-center table-header-text font-bold text-lg">
						Training Materials
					</h3>
				</div>
				<div className="w-full md:w-2/3 lg:w-1/2">
					{training.map((item, index) => (
						<h2
							key={index}
							className="flex gap-4 flex-col md:flex-row justify-between items-center mb-4"
						>
							{index + 1}. {item.name}
							<Button
								classname="ml-4 bg-blue-600 text-white font-semibold text-sm px-4 py-2 rounded-md w-36 flex justify-center items-center"
								onClick={() => handleDownload(item.id, item.name)}
							>
								Download <FaDownload className="ml-5" />
							</Button>
						</h2>
					))}
				</div>
			</div>
		</div>
	);
};

export default TrainingMaterial;
