import React, { useContext, useEffect, useState } from "react";
import {
	Loader,
	PdfViewer,
	ProfileLabel,
	Dropdown,
	Button,
} from "../../components";
import { FORMSIXTEENPAYROLLPDF } from "../../Constants";
import AuthContext from "../../contexts/AuthContext";
import ApiCallTwo from "../../utils/ApiCallTwo";
import NoDataImg from "../../images/no_data.svg";

const PayrollForm = () => {
	const { authTokens } = useContext(AuthContext);
	const date = new Date();
	const currentYear = date.getFullYear();
	const lastYear = currentYear - 1;
	const twoYearsBack = lastYear - 1;
	const [pdfUrl, setPdfUrl] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [year, setYear] = useState(
		sessionStorage.getItem("year-form")
			? JSON.parse(sessionStorage.getItem("year-form"))
			: ""
	);

	const filterDataYear = [
		{ value: "none", label: "Select" },
		{
			value: lastYear,
			label: lastYear,
		},
		{
			value: twoYearsBack,
			label: twoYearsBack,
		},
	];

	useEffect(() => {
		fetchData();
	}, []);

	//console.log("year", year);
	const fetchData = async () => {
		try {
			const response = await ApiCallTwo(
				`${FORMSIXTEENPAYROLLPDF}?year=${year}`,
				"GET",
				authTokens,
				null
			);

			if (response.isPdf) {
				const pdfBlob = response.data;
				const url = URL.createObjectURL(pdfBlob);
				setPdfUrl(url);
				setIsLoading(false);
			}
		} catch (error) {
			console.log("Error While fetching PDF data", error);
			setIsLoading(false);
		}
	};

	const handleYear = (e) => {
		e.preventDefault();
		setYear(e.target.value);
		sessionStorage.setItem("year-form", JSON.stringify(e.target.value));
	};

	const handleFormSixPDf = async (e) => {
		e.preventDefault();
		try {
			const response = await ApiCallTwo(
				`${FORMSIXTEENPAYROLLPDF}?year=${year}`,
				"GET",
				authTokens,
				null
			);

			if (response.isPdf) {
				const pdfBlob = response.data;
				const url = URL.createObjectURL(pdfBlob);
				setPdfUrl(url);
				setIsLoading(false);
			}
		} catch (error) {
			console.log("Error While fetching PDF data", error);
			setIsLoading(false);
		}
	};
	return (
		<div className="w-auto my-6 flex flex-col mx-6 py-4 px-2 justify-center">
			<h2 className="text-lg mx-2 intro-x table-body-td px-2 font-semibold mb-4">
				Form 16
			</h2>
			<div className="flex md:flex-row flex-col mx-4 gap-4 justify-between items-center px-4">
				<form
					onSubmit={handleFormSixPDf}
					className="flex md:flex-row flex-col md:gap-2 gap-1 intro-x items-center"
				>
					<ProfileLabel lableStyle="text-sm" label="Year" />
					<Dropdown
						onChange={handleYear}
						value={year}
						options={filterDataYear}
					/>
					<Button classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm mx-4">
						Filter
					</Button>
				</form>
			</div>

			<div className="w-auto intro-y flex justify-center items-center my-6 py-4 px-2">
				{pdfUrl.length > 0 ? (
					isLoading ? (
						<div className="w-full h-[80vh] flex items-center justify-center">
							<Loader />
						</div>
					) : (
						PdfViewer && <PdfViewer pdfUrl={pdfUrl} />
					)
				) : (
					<h3 className="text-center flex flex-col justify-center items-center intro-x my-4">
						<img className="w-72 text-center" src={NoDataImg} />
						<p className="font-bold">No data</p>
					</h3>
				)}
			</div>
		</div>
	);
};

export default PayrollForm;
