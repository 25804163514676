import React, { useContext, useEffect, useState } from "react";
import PdfViewer from "./PdfViewer";
import Loader from "./Loader";
import { LEAVEPOLICY } from "../Constants";
import ApiCallTwo from "../utils/ApiCallTwo";
import AuthContext from "../contexts/AuthContext";

const LeavePolicy = () => {
	const { authTokens } = useContext(AuthContext);
	const [pdfUrl, setPdfUrl] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiCallTwo(LEAVEPOLICY, "GET", authTokens);
			if (response.isPdf) {
				const pdfBlob = response.data;
				const url = URL.createObjectURL(pdfBlob);
				setPdfUrl(url);
				setIsLoading(false);
				// console.log("rrrrrrrrrrr", response);
			}
		} catch (error) {
			console.log("Error fetching PDF data", error);
			setIsLoading(false);
		}
	};
	return (
		<div className="w-auto intro-y flex flex-col my-6 mx-6 py-4 px-2 -mt-7">
			<h4 className="text-lg intro-x table-body-td font-semibold  py-4 px-2">
				Leave Policy
			</h4>
			<div className="w-full intro-y flex justify-center items-center py-4 px-2">
				{isLoading ? (
					<div className="w-full h-[70vh] flex items-center justify-center">
						<Loader />
					</div>
				) : (
					PdfViewer && <PdfViewer pdfUrl={pdfUrl} />
				)}
			</div>
		</div>
	);
};

export default LeavePolicy;
