import React from "react";

const PaySlipRow = ({ dataone, dataTwo }) => {
	return (
		<div className="px-2 flex gap-4 justify-between text-center md:text-start">
			<p>{dataone}</p>
			<p>: {dataTwo}</p>
		</div>
	);
};

export default PaySlipRow;
